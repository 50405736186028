import { useContext, createContext, useState, useEffect }  from 'react'
import { io } from 'socket.io-client'
import { useAuth } from './useAuth'

const SocketContext = createContext()

function SocketProvider({ children }) {

    const [ socketConnection, setSocketConnection ] = useState(null)
    const { userData } = useAuth()

    useEffect(() => {

        if(!socketConnection){

            const socket = io({
                auth: {
                    users_id: userData.id,
                    token: userData.token,
                    typeAccess_id: userData.typeAccess_id
                }
            });
            
            socket.on('connect', () => {
                setSocketConnection(socket)
                console.log('Conexão com socket estabelecida: ', socket.id)
            })
    
            socket.on('disconnect', () => console.log('Conexão com socket foi encerrada.'))

        }

    }, [socketConnection])

    return (
        <SocketContext.Provider value={{ socketConnection, setSocketConnection}}>
            {children}
        </SocketContext.Provider>
    )

}

function useSocket() {
    return useContext(SocketContext)
}

export {
    SocketProvider,
    useSocket
}