// hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// context
import { useThemeContext } from "../../contexts/themeContext";
import { useAuth } from "../../contexts/useAuth";
// components
import { CalendarMain } from "../../components/calendar/calendar.main";
import { Body } from "../../components/container/Body";
import { Container } from "../../components/container/container";
import { Input } from "../../components/input/input";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { LabelInput } from "../../components/label/label.input";
import { DefaultLoader } from "../../components/loaders/defaultLoader";
import { Title } from "../../components/titlePages/title";
import { TitlePage } from "../../components/titlePages/title.page";
// utils
import { responseError } from "../../utils/responsesFunctions/error.response";
import { getPartners, getClients } from '../../services/api/callAPIsFunctions/defaultCalls.api'
import { api } from "../../services/api/api";
import { listHours } from "../../utils/listHours";
import { Button } from "../../components/buttons/button.default";

export function ToSchedule() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [dayToMiniCalendar, setDayToMiniCalendar] = useState(0)
    const [selectedDay, setSelectedDay] = useState(0)
    const [observation, setObservation] = useState('')
    const [selectPartner, setSelectPartner] = useState()
    const [selectClient, setSelectClient] = useState()
    const [partnersList, setPartnersList] = useState([])
    const [clientsList, setClientsList] = useState([])
    const [partnerHours, setPartnerHours] = useState([])
    const [weekday, setWeekday] = useState()
    const [startHour, setStartHour] = useState()
    const [finishHour, setFinishHour] = useState()

    useEffect(() => {
        setLoading(true)

        async function getPartnersList() {
            try {

                const getPartnersApi = await getPartners(userData.token)
                setPartnersList(getPartnersApi?.data.map((partner) => {
                    return {
                        ...partner,
                        option: partner?.id + ' - ' + partner?.name
                    }
                }))
                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        async function getClientsList() {
            try {

                const getClientsApi = await getClients(userData.token)
                setClientsList(getClientsApi?.data.map((client) => {
                    return {
                        ...client,
                        option: client.firstname + client.lastname
                    }
                }))
                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getPartnersList()
        getClientsList()

    }, [])

    useEffect(() => {
        if (selectPartner) {
            setLoading(true)

            async function getPartnerHours() {
                try {

                    const getHoursPartner = await api.get(`/api/v1/partnersHours/${selectPartner?.id}`, {
                        headers: {
                            authorization: `Bearer ${userData.token}`
                        }
                    })
                    setPartnerHours(getHoursPartner?.data?.data)

                    setLoading(false)

                } catch (error) {
                    if (responseError(error).length > 0) {
                        setLoading(false)
                        setShowNotificationModalSuccess(false)
                        setShowModificationModal(true)
                        return setShowNotificationModalText(responseError(error))
                    } else {
                        setLoading(false)
                        setShowNotificationModalSuccess(false)
                        setShowModificationModal(true)
                        return setShowNotificationModalText('Erro inesperado')
                    }
                }

            }

            getPartnerHours()
        }

    }, [selectPartner])

    const weekdayList = [
        { 'id': 0, 'name': 'Domingo' },
        { 'id': 1, 'name': 'Segunda-Feira' },
        { 'id': 2, 'name': 'Terça-Feira' },
        { 'id': 3, 'name': 'Quarta-Feira' },
        { 'id': 4, 'name': 'Quinta-Feira' },
        { 'id': 5, 'name': 'Sexta-Feira' },
        { 'id': 6, 'name': 'Sábado' }
    ]

    return (
        <>
            <Container>
                <TitlePage>
                    <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Agendar'} />
                        </div>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <div className='flex col justify-center items-center'>
                                <DefaultLoader />
                            </div>
                        :
                        <div className="flex flex-col lg:flex-row gap-2 w-full">
                            <div className='flex flex-col lg:items-center items-center justify-start lg:w-[40vw] gap-4'>
                                <LabelInput text={'Cliente *'}>
                                    <InputAutoComplete
                                        data={clientsList}
                                        selectedLabel={'option'}
                                        value={selectClient ? selectClient?.option : ''}
                                        id='client'
                                        optionList={['id', 'option']}
                                        placeHolder={' - '}
                                        onChange={e => {
                                            console.log(e)
                                            if (typeof (e) === 'object') {
                                                setSelectClient(e)
                                            }
                                        }}
                                    />
                                </LabelInput>
                                <div className='flex flex-col items-center justify-center w-62 shadow-md shadow-gray-300'>
                                    <div className='flex flex-col items-start justify-start w-full'>
                                        <div className='flex flex-row w-full items-start justify-start gap-4'>
                                            <div className='sm:flex flex-col w-56'>
                                                <CalendarMain externalSelectedDay={dayToMiniCalendar} onClick={(e) => { setSelectedDay(e) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !selectClient ?
                                    <LabelInput text={'Parceiro *'}>
                                        <Input disabled={true} id='partner'/>
                                    </LabelInput>
                                    :
                                    <LabelInput text={'Parceiro *'}>
                                        <InputAutoComplete
                                            data={partnersList}
                                            selectedLabel={'option'}
                                            optionList={['option']}
                                            placeHolder={' - '}
                                            id='partner'
                                            value={selectPartner ? selectPartner?.option : ''}
                                            onChange={e => {
                                                if (typeof (e) === 'object') {
                                                    setSelectPartner(e)
                                                }
                                            }}
                                        />
                                    </LabelInput>                                
                                }
                                <LabelInput text={'Observação'}>
                                    <textarea className='outline-none border border-gray-400 border-opacity-75 rounded-lg 
                                    w-80 h-36 lg:h-16 
                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark 
                                    focus:border focus:border-primaryDefaultLight 
                                    dark:focus:border dark:focus:border-primaryDefaultLight p-2'
                                        maxLength={250} id='observation'
                                        onChange={e => setObservation(e.target.value)}
                                    />
                                </LabelInput>
                            </div>
                            <div className="lg:w-[40vw] max-h-72">
                                {
                                    !selectPartner ?
                                        <></>
                                        :
                                        <>
                                            <h3 className="text-center font-semibold text-primaryDefaultLight">Horários de atendimento</h3>
                                            <div className="h-full flex flex-row flex-wrap justify-center gap-6 mt-5 p-5 overflow-auto scrollbar-thin scrollbar-track-gray-400 scrollbar-thumb-gray-300">
                                                {
                                                    partnerHours.map((hour) => {
                                                        return (
                                                            <div>
                                                                <div className="w-32 flex h-24 flex-col items-center justify-center rounded-md shadow-md shadow-gray-400">
                                                                    {weekdayList.map((weekday) => {
                                                                        return (
                                                                            <p><b>{hour?.weekday === weekday?.id ? weekday?.name : ''}</b></p>
                                                                        )
                                                                    })}
                                                                    {listHours.map((list) => {
                                                                        return (
                                                                            <div>
                                                                                <p>{hour?.startHour === list?.id ? list?.name + ' às' : ''}</p>
                                                                                <p>{hour?.finishHour === list?.id ? list?.name : ''}</p>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="flex flex-row justify-center gap-3 mt-2">
                                                <LabelInput text={'Horário Inicio *'}>
                                                    <InputAutoComplete                                                    
                                                        selectedLabel={'name'}
                                                        id='startHour'
                                                        data={listHours} optionList={['name']}
                                                        onChange={(e) => {
                                                            setStartHour(e?.id)
                                                        }} charLimit={100} width={56}
                                                    />
                                                </LabelInput>
                                                <LabelInput text={'Horário Fim *'}>
                                                    <InputAutoComplete                                                    
                                                        selectedLabel={'name'}
                                                        id='finishHour'
                                                        data={listHours} optionList={['name']}
                                                        onChange={(e) => {
                                                            setFinishHour(e?.id)
                                                        }} charLimit={100} width={56}
                                                    />
                                                </LabelInput>
                                            </div>
                                            <div className="flex justify-center mt-10 gap-3">
                                                <Button>Agendar</Button>
                                                <Button approval={false} onClick={() => navigate('/operatorSchedule')}>Cancelar</Button>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    }
                </Body>
            </Container>
        </>
    )
}