// hooks
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Body } from '../../../components/container/Body'
// components
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'

export function ReservationCategoryCreate() {

    return (

        <Container>
            <TitlePage>
                <Title text={'Criar nova categoria'} />
            </TitlePage>
            <Body>
                <div className='flex flex-col items-start justify-start w-full'>
                    <form className='flex flex-col items-start justify-start'>
                        <LabelInput text={'Descrição *'}>
                            <Input type={'text'}></Input>
                            </LabelInput>                    
                    </form>
                </div>
            </Body>
        </Container>
    )
}