import { Routes, Route, Link, Navigate } from 'react-router-dom';

export function ProtectedRoute({ user, permission, children }) {

    if (
        (user.typeAccess_id !== 1)
        &&
        (!permission?.includes(user.typeAccess_id))
    ) {
        return <Navigate to="/" replace />;
    }

    return children;
}
