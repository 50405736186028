// hooks 
import React from 'react'
// contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { ScreenSizeProvider, useScreenSizeContext } from '../../../contexts/screenSizeContext'
// components 
import { TitlePage } from '../../../components/titlePages/title.page'
import { Tooltip } from '../../../components/toolTip/toolTip'
import { Title } from '../../../components/titlePages/title'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
// utils
import { changeThemeColor } from '../../../theme/changeColors'
import { menuItems } from '../../../components/menu/menuItems'
import { Button } from '../../../components/buttons/button.default'

export function ThemesDefaultCenter() {

    const { darkMode,
        setDarkMode,
        changeTheme,
        setPrimaryClientDefaultLight,
        primaryClientDefault,
        secondaryClientDefault
    } = useThemeContext()
    const { screenY } = useScreenSizeContext()

    async function handleChangeDefaultColor() {

        if (window.confirm('Deseja aplicar esta cor como padrão para seu sistema?')) {
            console.log('chamada api')
        }
    }

    return (

        <Container>
            <TitlePage>
                <Title text={'Temas'}></Title>
            </TitlePage>
            <Body>
                <h2 className='text-center mt-4  hidden md:flex text-base font-bold text-secondaryDefaultLight dark:text-titleGrayTextDark'>Selecione o esquema de cores <br />Padrão do sistema que será apresentado para os clientes </h2>
                <div className='mb-20 rounded-2xl flex flex-col w-[900px] 2xl:w-[1100px] boxShadow items-center justify-between gap-10 dark:border dark:border-primaryBorderDark'>
                    <div className='flex flex-col xl:flex-row gap-12 w-full items-center justify-center rounded-2xl mt-16'>
                        <div onClick={() => changeTheme(false)} className={`cursor-pointer border-2 ${darkMode ? 'border-transparent' : 'border-primaryDefaultLight'}  rounded-2xl flex flex-col boxShadow w-80 2xl:w-96 h-64 2xl:h-80 bg-bgPrimaryColor dark:bg-thirdDefaultDark dark:border dark:border-secondaryBorderDark items-center justify-center gap-4`}>
                            <dvi className='flex flex-row shadow-lg shadow-titleBlackTextLight/60 dark:shadow-titleGrayTextDark/50'>
                                <div className='flex flex-col w-4 2xl:w-8 bg-primaryDefaultLight h-36 2xl:h-48'>
                                    <div className='flex flex-col items-center gap-2 mt-2'>
                                        {menuItems.map((item) => {
                                            return <div className='flex flex-col items-center text-xs text-titleGrayTextLight'>
                                                {item.icon}
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className='flex flex-col bg-bgSecondaryLight w-72 gap-2 pl-1 pr-1'>
                                    <div className='w-full bg-bgPrimaryLight h-8 pl-1 pr-1 flex flex-row justify-between items-center'>
                                        <h2 className='text-xs text-titleBlackTextLight'>Exemplo</h2>
                                        <button className='flex flex-col items-center justify-center bg-primaryDefaultLight w-10 h-4 text-xs text-titleGrayTextLight p-px rounded-md hover:bg-secondaryDefaultLight' >Botão</button>
                                    </div>
                                    <div className='w-full bg-bgPrimaryLight h-full pl-1'>
                                        <h2 className='text-xs text-titleBlackTextDark'>Exemplo</h2>
                                    </div>
                                </div>
                            </dvi>
                            <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>Claro</a>
                        </div>
                        <div onClick={() => { changeTheme(true); console.log(darkMode) }} className={`cursor-pointer border-2 ${darkMode ? 'border-primaryDefaultLight' : 'border-transparent'}  rounded-2xl flex flex-col boxShadow w-80 2xl:w-96 h-64 2xl:h-80 bg-bgPrimaryColor dark:bg-thirdDefaultDark items-center justify-center gap-4`}>
                            <div className='flex flex-row shadow-lg shadow-titleBlackTextLight/60 dark:shadow-titleGrayTextDark/50'>
                                <div className='flex flex-col w-6 2xl:w-8 bg-secondaryDefaultDark h-36 2xl:h-48 border border-primaryBorderDark'>
                                    <div className='flex flex-col items-center gap-2 mt-2'>
                                        {menuItems.map((item) => {
                                            return <div className='flex flex-col items-center text-xs text-titleGrayTextLight'>
                                                {item.icon}
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className='flex flex-col bg-primaryDefaultDark w-72 gap-2 pl-1 pr-1'>
                                    <div className='w-full bg-secondaryDefaultDark border border-primaryBorderDark h-8 pl-1 pr-1 flex flex-row justify-between items-center'>
                                        <h2 className='text-xs text-titleGrayTextDark'>Exemplo</h2>
                                        <button className='flex flex-col items-center justify-center bg-primaryDefaultLight w-10 h-4 text-xs text-titleGrayTextLight p-px rounded-md hover:bg-secondaryDefaultLight' >Botão</button>
                                    </div>
                                    <div className='w-full bg-secondaryDefaultDark border border-secondaryBorderDark h-full pl-1'>
                                        <h2 className='text-xs text-titleGrayTextDark'>Exemplo</h2>
                                    </div>
                                </div>
                            </div>
                            <a className='text-titleBlackTextLight dark:text-titleGrayTextDark text-base'>Escuro</a>
                        </div>

                    </div>
                    <div className='w-full flex flex-col'>
                        <div className='relative flex flex-row border-t border-titleGrayTextDark dark:border-thirdDefaultDark h-20 w-full gap-4 items-center justify-center'>
                            <Tooltip
                                component={<button onClick={() => { changeThemeColor('orange', '#f97316'); setPrimaryClientDefaultLight('#f97316') }} className='hover:brightness-150 transition-all duration-200 hover:scale-105  flex flex-col items-center cursor-pointer justify-center w-10 h-10 bg-orange-500 rounded-full'></button>}
                                title='Orange'
                            />
                            <Tooltip
                                component={<button onClick={() => { changeThemeColor('red'); setPrimaryClientDefaultLight('#7c2d12') }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-10 h-10 bg-red-800 rounded-full'></button>}
                                title='Red'
                            />
                            <Tooltip
                                component={<button onClick={() => { changeThemeColor('green'); setPrimaryClientDefaultLight('#16a34a') }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-10 h-10 bg-green-600 rounded-full'></button>}
                                title='Green'
                            />
                            <Tooltip
                                component={<button onClick={() => { changeThemeColor('emerald'); setPrimaryClientDefaultLight('#10b981') }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-10 h-10 bg-emerald-500 rounded-full'></button>}
                                title='Emerald'
                            />
                            <Tooltip
                                component={<button onClick={() => { changeThemeColor('teal'); setPrimaryClientDefaultLight('#14b8a6') }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-10 h-10 bg-teal-500 rounded-full'></button>}
                                title='Teal'
                            />
                            <Tooltip
                                component={<button onClick={() => { changeThemeColor('default'); setPrimaryClientDefaultLight('#00B0E9') }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-10 h-10 bg-[#00B0E9] rounded-full'></button>}
                                title='Default'
                            />
                            <Tooltip
                                component={
                                    <button onClick={() => { changeThemeColor('blue'); setPrimaryClientDefaultLight('#3b82f6') }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-10 h-10 bg-blue-500 rounded-full'></button>
                                }
                                title='Blue'
                            />
                            <Tooltip
                                component={<button onClick={() => { changeThemeColor('indigo'); setPrimaryClientDefaultLight('#6366f1') }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-10 h-10 bg-indigo-500 rounded-full'></button>}
                                title='Indigo'
                            />
                            <Tooltip
                                component={<button onClick={() => { changeThemeColor('purple'); setPrimaryClientDefaultLight('#c084fc') }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-10 h-10 bg-purple-400 rounded-full'></button>}
                                title='Purple'
                            />
                            <Tooltip
                                component={<button onClick={() => { changeThemeColor('rose'); setPrimaryClientDefaultLight('#f43f5e') }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-10 h-10 bg-rose-500 rounded-full'></button>}
                                title='Rose'
                            />
                            <h2 className='text-secondaryDefaultLight mb-6 mt-6 font-bold'>Cor personalizada</h2>
                            <Tooltip
                                component={
                                    <input value={primaryClientDefault} onChange={(e) => { changeThemeColor('primaryClientDefault', e.target.value); setPrimaryClientDefaultLight(e.target.value) }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-10 h-10 outline-none dark:bg-secondaryDefaultDark' type='color'></input>
                                }
                                title='Cor primária'
                            />
                            <Tooltip
                                component={
                                    <input value={secondaryClientDefault} onChange={(e) => { changeThemeColor('secondaryClientDefault', e.target.value); secondaryClientDefault(e.target.value) }} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-10 h-10 outline-none dark:bg-secondaryDefaultDark' type='color'></input>
                                }
                                title="Cor secondária"
                            />



                        </div>
                        <div className='w-full border-t border-t-gray-300 dark:border-t-primaryBorderDark flex flex-col items-center justify-center p-5'>
                            <div className='flex flex-row gap-2 w-full items-center justify-center mb-4' >
                                <p className='text-titleBlackTextLight dark:text-titleGrayTextDark'>Travar troca de cores</p>
                                <Tooltip
                                    component={
                                        <input value={secondaryClientDefault} onChange={(e) => setLockColors(e.target.checked)} className='hover:brightness-150 transition-all duration-200 hover:scale-105 flex flex-col items-center cursor-pointer justify-center w-6 h-6 outline-none' type='checkbox'></input>
                                    }
                                    title="Marque para que não seja possível seus colaboradores alterarem a cor para eles"
                                />
                            </div>
                            <Button shadow={true} onClick={() => handleChangeDefaultColor()}>Aplicar</Button>
                        </div>
                    </div>
                </div>
            </Body>
        </Container>
    )
}