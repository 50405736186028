import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RedirectRoutes } from './routes/redirect.routes';
import { AuthContextProvider, useAuth } from './contexts/useAuth'
import { ScreenSizeProvider } from './contexts/screenSizeContext'
import { ThemeContextProvider } from './contexts/themeContext';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(

  <AuthContextProvider>
    <DndProvider backend={HTML5Backend}>
      <ThemeContextProvider>
        <ScreenSizeProvider>
          <RedirectRoutes />
        </ScreenSizeProvider>
      </ThemeContextProvider>
    </DndProvider>
  </AuthContextProvider>
);
