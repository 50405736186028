// hooks
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { FaTrashAlt } from 'react-icons/fa'
// components
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { LabelInput } from '../../../components/label/label.input'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Tooltip } from '../../../components/toolTip/toolTip'
import { Toggle } from '../../../components/toggle/toggleButton'
// services
import { api } from '../../../services/api/api'
import { getPermissionGroups, getTypeAccess, getUserEspecific } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
// utils
import { validateEmail } from '../../../utils/validators/email.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { mCPF } from '../../../utils/validators/cpfMask'
import { formatSizeCpf } from '../../../utils/validators/formatSizeCpf'
import { justNumber } from '../../../utils/validators/justNumber'
import { maskPhone } from '../../../utils/validators/phoneMask'
import { removePontuaction } from '../../../utils/validators/removePontuaction'
import { LogHistory } from '../../../components/logHistory/logHistory'
import { Footer } from '../../../components/footer/Footer'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';

export function UserEdit() {
    const [nametitle, setNametitle] = useState('')
    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [updatedUser, setUpdatedUser] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [showNotification, setShowNotication] = useState('')
    const [showModal, setShowModal] = useState(true)
    const [successData, setSuccessData] = useState(false)
    const [typeAccessList, setTypeAccessList] = useState([])
    const [permissionGroupsData, setPermissionsGroupsData] = useState([])
    const [thisUserData, setThisUserData] = useState([])
    const [activeUsers, setActiveUsers] = useState(false)
    const [modalAlterPass, setModalAlterPass] = useState(false)
    const [showLogs, setShowLogs] = useState(false)

    //selectedVariables
    const [typeAccess_id, setTypeAccess_id] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [birthday, setBirthday,] = useState('')
    const [dddphone, setDddphone] = useState('')
    const [gender, setGender] = useState('')
    const [dddmobile, setDddmobile] = useState('')
    const [mobile, setMobile,] = useState('')
    const [phone, setPhone,] = useState('')
    const [cpf, setCpf] = useState('')

    //temporary API
    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        }
    ]

    useEffect(() => {

        async function getUser() {
            setLoading(true)

            try {

                const getTypeAccess = await api.get('/api/v1/typeAccess', {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('@auth:token')}`
                    }
                })
                const filterTypeaccess = getTypeAccess.data.data.filter(type => {
                    return type.id < 5
                })

                setTypeAccessList(filterTypeaccess)

                const getUserData = await getUserEspecific(localStorage.getItem('@auth:token'), id)
                setNametitle(getUserData.data[0].firstname)
                if (getUserData.error) {
                    throw getUserData.data
                }

                setFirstname(getUserData.data[0].firstname)
                setLastname(getUserData.data[0].lastname)
                setEmail(getUserData.data[0].email)
                setBirthday(getUserData.data[0].birthday ? getUserData.data[0].birthday.split('T')[0] : '')
                setGender(getUserData.data[0].gender)
                setDddmobile(getUserData.data[0].dddmobile)
                setMobile(maskPhone(getUserData.data[0].mobile))
                setDddphone(getUserData.data[0].dddphone)
                setPhone(maskPhone(getUserData.data[0].phone))
                setCpf(getUserData.data[0]?.cpf ? mCPF(formatSizeCpf(getUserData.data[0].cpf)) : '')
                setTypeAccess_id(getUserData.data[0].typeAccess_id)
                setActiveUsers(getUserData.data[0]?.active)

                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        getUser()

    }, [updatedUser])

    async function handleSubmit() {

        const requiredFields =
            [
                { name: 'firstname', value: firstname, required: true, type: 'string' },
                { name: 'lastname', value: lastname, required: true, type: 'string' },
                { name: 'email', value: email, required: true, type: 'string' },
                { name: 'typeAccess_id', value: typeAccess_id, required: true, type: 'string' }
            ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Campos obrigatórios não preenchidos!')
            return setShowNotificationModalSuccess(false)
        }

        if (!validateEmail(email)) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('E-mail inválido')
        }

        setLoading(true)

        try {

            await api.put(`/api/v1/users/${id}`, {
                firstname: firstname ? firstname : undefined,
                lastname: lastname ? lastname : undefined,
                birthday: birthday ? birthday : null,
                gender: gender ? gender : null,
                dddphone: dddphone ? dddphone : null,
                dddmobile: dddmobile ? dddmobile : null,
                phone: phone ? removePontuaction(phone) : null,
                mobile: mobile ? removePontuaction(mobile) : null,
                cpf: cpf ? parseInt(removePontuaction(cpf)) : null,
                email: email ? email : undefined,
                typeAccess_id: typeAccess_id ? typeAccess_id : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('@auth:token')}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/users')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDelete() {

        setLoading(true)

        try {

            await api.delete(`/api/v1/users/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/users')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    async function usersStatus() {

        const status = !activeUsers
        setActiveUsers(status)
        // setLoading(true)

        try {

            await api.put(`/api/v1/users/${id}/active/${status ? 1 : 0}`, {}, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })

            setShowNotificationModalSuccess(true)
            setShowModificationModal(true)
            // setLoading(false)
            setShowNotificationModalText(status === false ? `Usuário ${firstname} desativado com sucesso.` : `Usuário ${firstname} ativado com sucesso.`)

        } catch (error) {
            setLoading(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            }
        }
    }

    async function handleAlterPass() {
        setLoading(true)

        try {
            const alterPass = await api.put(`api/v1/users/alterPass/${id}`, {}, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })

            setShowModificationModal(true)
            setShowUniversalModal(false)
            setLoading(false)
            setModalAlterPass(false)
            setShowNotificationModalText(alterPass?.data?.message)
            setShowNotificationModalSuccess(true)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowUniversalModal(false)
                setModalAlterPass(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowUniversalModal(false)
                setModalAlterPass(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <ScreenModal title={'Exlusão de usuário'} width={400} height={200} >
                {
                    modalAlterPass ?
                        <div className='w-full p-2 h-96 flex flex-col items-center justify-center'>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente reenviar a senha?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button shadow={true} onClick={() => { handleAlterPass() }}>Sim</Button>
                                <Button shadow={true} onClick={() => { setShowUniversalModal(false); setModalAlterPass(false) }} approval={false}>Não</Button>
                            </div>
                        </div>
                        :

                        <div className='w-full p-2 h-96 flex flex-col items-center justify-center'>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente excluir o usuário {`${firstname} ${lastname}`} ?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                                <Button shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                            </div>
                        </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage >
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Editar Usuário - ${nametitle}`} />
                    </div>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                                <form className='flex flex-col flex-wrap gap-4 ' onSubmit={e => e.preventDefault()}>
                                    <div className='flex  flex-col lg:flex-row gap-4 items-center justify-center'>
                                        <label className='flex flex-col gap-1 items-start justify-center'>
                                            <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                            <Input charLimit={255} id='firstname' type='text' value={firstname} onChange={(e) => setFirstname(e.target.value)}></Input>
                                        </label>
                                        <label className='flex flex-col gap-1 items-start justify-center'>
                                            <a className='text-sm text-inputPlaceholderLight'>Sobrenome *</a>
                                            <Input charLimit={255} id='lastname' type='text' value={lastname} onChange={(e) => setLastname(e.target.value)}></Input>
                                        </label>
                                    </div>
                                    <div className='flex flex-col lg:flex-row gap-4 items-center justify-center'>
                                        <label className='flex flex-col gap-1 items-start justify-center'>
                                            <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                            <Input charLimit={255} id='email' type='e-mail' value={email} onChange={(e) => { validateEmail(e.target.value); setEmail(e.target.value) }}></Input>
                                        </label>
                                        <LabelInput text={'Data de nascimento '}>
                                            <Input id='birthday' value={birthday} type={'date'} onChange={(e) => setBirthday(e.target.value)}></Input>
                                        </LabelInput>
                                    </div>
                                    <div className='flex flex-col lg:flex-row gap-4 items-center justify-center'>
                                        <label className='relative flex flex-col gap-1 items-start justify-center'>
                                            <a className='text-sm text-inputPlaceholderLight'>Tipo de acesso *</a>
                                            <InputAutoComplete
                                                data={typeAccessList}
                                                selectedLabel={'description'}
                                                optionList={['description']}
                                                onChange={typeAccess => setTypeAccess_id(typeAccess.id)}
                                                preSelectedValue={typeAccessList ? typeAccessList.filter(type => type.id == typeAccess_id)[0]?.description : ''}
                                                id='typeAccess_id'
                                                width={'318px'}
                                            />
                                        </label>
                                        <LabelInput text={'Gênero'}>
                                            <InputAutoComplete
                                                data={sexTypes}
                                                selectedLabel={'name'}
                                                optionList={['id', 'name']}
                                                placeHolder=' - '
                                                onChange={e => setGender(e.id)}
                                                preSelectedValue={gender ? sexTypes.filter(sex => sex.id == gender)[0]?.name : ''}
                                                id='user_gender_select'
                                            />
                                        </LabelInput>
                                    </div>
                                    <div className='flex flex-col lg:flex-row gap-4 items-center justify-center'>
                                        <label className='flex flex-col gap-1 items-start justify-center'>
                                            <a className='text-sm text-inputPlaceholderLight'>CPF</a>
                                            <Input charLimit={14} id='cpf' type='text' value={cpf} onChange={(e) => setCpf(mCPF(e.target.value))}></Input>
                                        </label>
                                        <div className='flex flex-row gap-3'>
                                            <LabelInput text={'DDD'}>
                                                <Input charLimit={2} width={'50px'} type={'text'} value={dddmobile} onChange={(e) => setDddmobile(justNumber(e.target.value))}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Celular'}>
                                                <Input charLimit={9} width={'255px'} type={'text'} value={mobile} onChange={(e) => setMobile(justNumber(e.target.value))}></Input>
                                            </LabelInput>
                                        </div>
                                    </div>
                                    <div className='flex flex-row gap-3'>
                                        <LabelInput text={'DDD'}>
                                            <Input charLimit={2} width={'50px'} type={'text'} onChange={(e) => setDddphone(justNumber(e.target.value))} value={dddphone}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Telefone'}>
                                            <Input charLimit={8} width={'255px'} type={'text'} onChange={(e) => setPhone(justNumber(e.target.value))} value={phone}></Input>
                                        </LabelInput>
                                    </div>
                                    {
                                        userData?.typeAccess_id == 1 ?
                                            <div className='flex justify-center lg:justify-start flex-row gap-3 mt-[1.5rem]'>
                                                <Button shadow={true} onClick={()=> {setModalAlterPass(true); setShowUniversalModal(true)}}>Reenviar senha</Button>
                                            </div>
                                            :
                                            <></>
                                    }
                                    <div className='flex flex-col w-full items-center lg:items-start justify-center mt-7'>
                                        <Tippy content={<span>{activeUsers ? 'Desativar' : 'Ativar'}</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='top'
                                            delay={100}>
                                            <div>
                                                <Toggle  status={activeUsers ? true : false} onClick={(e) => usersStatus()} />                                                   
                                            </div>
                                        </Tippy>
                                    </div>
                                </form>
                                <hr className='border-t border-gray-400 w-full py-2 mt-5'/>
                                {
                                    !showLogs ?
                                    <div className="flex w-full items-center justify-center">
                                        <Button onClick={() => setShowLogs(true)}>Mostrar Histórico</Button>
                                    </div>
                                    :
                                    <LogHistory 
                                        apiData={{
                                            params: `?table_name=users&table_id=${id}`
                                        }}
                                    />
                                }
                            </div>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>

                        <Button
                            id='cancelUserEdit'
                            approval={false}
                            shadow={true}
                            onClick={() => { navigate('/users'); setShowModificationModal(false) }}
                        >Voltar
                        </Button>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <Button
                                id='deleteUserEdit'
                                approval={false}
                                shadow={true}
                                onClick={() => setShowUniversalModal(true)}
                            >Excluir
                            </Button>

                            <Button
                                id='approvalUserEdit'
                                approval={true}
                                shadow={true}
                                onClick={() => handleSubmit()}
                            >Editar usuário
                            </Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )


}
