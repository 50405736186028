// hooks 
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// utils
import { configItems } from "./configuration.items";
//contexts
import { useAuth } from "../../../contexts/useAuth";
import { configItemsPartner } from "./configuration.items.partner";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { useScreenSizeContext } from "../../../contexts/screenSizeContext";

export function Configuration() {

    const { userData } = useAuth()
    const navegate = useNavigate()
    const { screenX, screenY } = useScreenSizeContext()
    const [menuItems, setMenuItems] = useState(userData.typeAccess_id < 5 ? configItems : configItemsPartner)

    return (

        <div id='configuration-menu' className="overflow-y-auto flex flex-col bg-bgSecondaryLight dark:bg-primaryDefaultDark w-full p-4" style={{ height: screenX < 640 ? screenY - 95 : 'auto' }}>
            <div id='configuration-menu-items' className="mt-10 flex flex-row flex-wrap gap-4 items-start justify-start">
                {
                    menuItems.map((configItem, index) => {
                        if (
                            configItem?.permission.includes(userData?.typeAccess_id)
                            ||
                            userData[0]?.permissions?.filter((userPermission) => {
                                if (configItem.permission.indexOf(userPermission) > -1) {
                                    return userPermission
                                }
                            }).length > 0
                        ) {
                            return <div id={`${configItem.name.replace(' ', '-').toLocaleLowerCase()}-config`} key={index} className="flex flex-col w-64 justify-between items-start">
                                <div key={`${index}-${configItem.name}`} className="flex flex-row gap-3 items-start justify-start">
                                    <div className="flex flex-col text-primaryDefaultLight dark:text-primaryDefaultDarkColor text-xl w-5"> {configItem.icon}</div>
                                    <Tippy content={configItem.legend}
                                        animation="shift-away"
                                        arrow={true}
                                        placement="right"
                                        delay={700}
                                    >
                                        <h2 className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor font-normal">{configItem.name}</h2>
                                    </Tippy>
                                </div>
                                {
                                    configItem.subItems.map((subItem, subIndex) => {
                                        if ((subItem.permission.indexOf(userData.typeAccess_id) > -1)
                                            ||
                                            userData[0]?.permissions?.filter((userPermission) => {
                                                if (subItem.permission.indexOf(userPermission) > -1) {
                                                    return userPermission
                                                }
                                            }).length > 0
                                        ) {
                                            return <div className="flex flex-row items-start justify-start gap-1">
                                                <div className="flex flex-col w-7"></div>
                                                <h3 key={`${index}-${subIndex}`} onClick={() => navegate(subItem.path)} className="cursor-pointer text-titleBlackTextLight dark:text-titleGrayTextDark font-light hover:brightness-200 dark:hover:brightness-50 transition-all duration-75 text-sm">{subItem.name}</h3>
                                            </div>
                                        }
                                    })
                                }
                            </div>
                        }
                    })
                }
            </div>
        </div>

        // <Container>
        //     <TitlePage>
        //         <div className='flex justify-center lg:justify-start'>
        //             <Title text='Gerenciamento do Sistema' />
        //         </div>
        //     </TitlePage>
        //     <Body>
        //         <div className="flex flex-row mt-10 flex-wrap items-start justify-start lg:gap-28 lg:mt-0 w-full gap-8">
        //             {
        //                 menuItems.map((configItem, index) => {
        //                     if (configItem?.permission.includes(userData?.typeAccess_id)) {

        //                         return <div key={index} className="flex flex-col justify-between items-start">
        //                             <div className="flex flex-row gap-2 items-center justify-center">
        //                                 <span className="text-lg text-primaryDefaultLight dark:text-primaryDefaultDarkColor font-bold w-4">{configItem?.icon}</span>
        //                                 <h2 className="text-lg text-primaryDefaultLight dark:text-primaryDefaultDarkColor font-bold">{configItem?.name}</h2>
        //                             </div>
        //                             {
        //                                 configItem.subItems.map((subItem, subIndex) => {

        //                                     if (subItem?.permission.includes(userData?.typeAccess_id)) {
        //                                         return (
        //                                             <h3 key={`${index}-${subIndex}`}
        //                                                 onClick={() => navegate(subItem.path)}
        //                                                 className="cursor-pointer text-base text-titleBlackTextLight dark:text-titleGrayTextDark font-light hover:brightness-50 transition-all duration-75 ml-6">{subItem.name}
        //                                             </h3>)
        //                                     }
        //                                 })
        //                             }
        //                         </div>
        //                     }

        //                 })
        //             }
        //         </div>

        //     </Body>
        // </Container>
    )
}

