import {
    FaChartPie, FaCog, FaCalendarAlt, FaUserTie, FaUsers, FaUserTag, FaBell, FaRegListAlt
} from 'react-icons/fa'
import { HiOutlineDocumentReport } from 'react-icons/hi'

export const menuItemsPartner = [

    {
        "icon": <FaChartPie />,
        "name": "Dashboard",
        "type": "normal",
        "path": "/",
    },
    {
        "icon": <FaBell />,
        "name": "Notificações",
        "type": "normal",
        "path": "/notify",
    },
    {
        "icon": <FaRegListAlt />,
        "name": "Minha agenda",
        "type": "normal",
        "path": "/operatorSchedulePartner",
    },
    {
        "icon": <FaCalendarAlt />,
        "name": "Calendário",
        "type": "normal",
        "path": "/operatorSchedulePartnerCalendar",
    },
    {
        "icon": <FaUserTag />,
        "name": "Meus clientes",
        "type": "list",
        "path": "/clients",
    },
    {
        "icon": <HiOutlineDocumentReport />,
        "name": "Relatório",
        "type": "normal",
        "path": "/reportsPartners",

    },
    {
        "icon": <FaCog />,
        "name": "Gerenciar",
        "type": "list",
        "path": "/configuration",
        "list": [
            {
                "icon": <FaCog />,
                "name": "Consultar",
                "type": "normal",
                "path": "/configuration/consultar",
            },
            {
                "icon": <FaCog />,
                "name": "Cadastrar",
                "type": "normal",
                "path": "/configuration/consultar",
            }

        ]
    },

]