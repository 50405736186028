import React, { useState } from 'react'
import { DayCalendar } from '../../components/calendar/day.calendar'
import { Container } from '../../components/container/container'
import { Title } from '../../components/titlePages/title'
import { TitlePage } from '../../components/titlePages/title.page'
import { Body } from '../../components/container/Body'
import { CalendarMain } from '../../components/calendar/calendar.main'

export function Reservation() {

    const [activeDate, setActiveDate] = useState([])

    return (
        <Container>
            <TitlePage>
                <Title text={'Reservas'} />
            </TitlePage>
            <Body>
                <div className='flex flex-row gap-2 w-full' >
                    <div className='flex flex-col w-72'>
                        <CalendarMain onClick={(e)=> setActiveDate(e)} />
                    </div>
                    <DayCalendar selectedDate={activeDate}></DayCalendar>
                </div>
            </Body>
        </Container>
    )
}