export class ValidPeriodTime {

    constructor(startHour, endHour, arrayTime) {

        this.startHour = startHour,
            this.endHour = endHour,
            this.arrayTime = arrayTime
    }


    validTime() {

        let errorType = ''
        const errors = this.arrayTime.filter((hours) => {
            if (this.startHour === hours.startHour) {
                errorType = 'start'
                return hours
            }

            if ((this.startHour < hours.startHour) && (this.endHour > hours.startHour)) {
                return hours
            }
            if ((this.startHour > hours.startHour) && (this.startHour < hours.endHour)) {

                errorType = 'start'
                return hours
            }
        })


        if (errors?.length > 0) {
            return { error: true, message: 'Periodo de horas incorreto verifique as horas deste dia', errorHour: errors[0].startHour - 1,errorType:errorType}
        }
        return { error: false, message: 'ok' }
    }
}