//hooks
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaUserAlt, FaDoorOpen } from 'react-icons/fa'
//contexts
import { useThemeContext } from '../../contexts/themeContext'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { useAuth } from '../../contexts/useAuth'
import { menuItems } from './menuItems'
import { menuItemsPartner } from './menuItemsPartner'
import styles from './styles/menu.module.css'
//comoponents
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { Button } from '../buttons/button.default'
import { api } from '../../services/api/api'
import { useSocket } from '../../contexts/socketContext'
import { contextSchedule } from '../../contexts/schedulesContext'

export function Menu() {

    const { userData, setUserData, setSigned } = useAuth()
    const { selectedMode, setSelectedMode } = contextSchedule()
    const { screenY, screenX } = useScreenSizeContext()
    const { socketConnection } = useSocket()
    const hasNotify = userData?.hasNotify ? userData.hasNotify : true
    const thisMenuItems = [6,7].includes(userData.typeAccess_id) 
        ? menuItemsPartner?.filter(menu => menu.name != 'Relatório') 
        : userData.typeAccess_id == 5 ? menuItemsPartner 
        : menuItems
    const { setShowModificationModal } = useThemeContext()
    const [showExitModal, setShowExitModal] = useState(false)
    const [showNotify, setShowNotify] = useState(false)
    const [animateThisModal, setAnimateThisModal] = useState(false)
    const modalRef = useRef()
    const notificationModalRef = useRef()
    const openModalRef = useRef()
    const openNotifyRef = useRef()
    const [userPic, setUserPic] = useState('')
    const [showMyProfile, setShowMyProfile] = useState(false)
    const [menuHeight, setMenuHeight] = useState('0')
    const [filteredIconsItens, setFilteredIconsItens] = useState(thisMenuItems)
    const [removedMenuItens, setRemovedMenuItens] = useState([])
    const [displayMenuHide, setDisplayMenuHide] = useState('none')
    const [displayCom, setDisplayCom] = useState('flex')
    const [showSubMenu, setShowSubMenu] = useState(false)
    const [menuSubListDisplay, setMenuSubListDisplay] = useState('none')
    const [isToHideMenu, setIsToHideMenu] = useState(true)
    const [notifications, setNotifications] = useState([])
    const [notificationsNumber, setNotificationsNumber] = useState(0)
    let newFilteredItens = filteredIconsItens
    let removedItens = []
    const navegate = useNavigate()

    useEffect(() => {

        const menu = document.getElementById('menuBar')
        checkWindowSize()
        window.addEventListener('resize', () => checkWindowSize())

        function checkWindowSize() {
            if (menu.getClientRects()[0]?.height < window.innerHeight && window.innerWidth > 640) {
                setMenuHeight('100vh')
                filteredIcons()
            } else if (window.innerWidth > 640) {
                setMenuHeight(`${window.innerHeight}px`)
                filteredIcons()
            } else {
                setMenuHeight(`68px`)
                setRemovedMenuItens([])
                setFilteredIconsItens(thisMenuItems)
            }
        }

        function filteredIcons() {

            const menuHide = document.getElementById('menuHideDiv')

            if (newFilteredItens.length < thisMenuItems.length) {
                menuHide.children[1].style.bottom = '10px'
            } else if (menuHide) {
                menuHide.children[1].style.bottom = ''
            }

            if ((thisMenuItems.length + 1) * 70 <= window.innerHeight) {
                removedItens = []
                newFilteredItens = thisMenuItems
                setRemovedMenuItens(removedItens)
                setFilteredIconsItens(newFilteredItens)
            }
            else if (newFilteredItens.length * 70 >= window.innerHeight) {
                while ((newFilteredItens.length + 1) * 70 >= window.innerHeight) {
                    removedItens.push(newFilteredItens.slice(newFilteredItens.length - 1, newFilteredItens.length)[0])
                    newFilteredItens = newFilteredItens.slice(0, newFilteredItens.length - 1)
                    setRemovedMenuItens(removedItens)
                    setFilteredIconsItens(newFilteredItens)
                }
            }
            else if ((newFilteredItens.length + 1) * 70 < window.innerHeight) {
                if (removedItens[removedItens.length - 1]) {
                    newFilteredItens.push(removedItens[removedItens.length - 1])
                    removedItens = removedItens.slice(0, removedItens.length - 1)
                    setRemovedMenuItens(removedItens)
                    setFilteredIconsItens(newFilteredItens)
                }
            }
        }

        return () => window.removeEventListener('resize', (e) => checkWindowSize(e))

    }, [])

    useEffect(() => {

        async function animateModal(status) {

            await new Promise(resolve => setTimeout(resolve, 60))
            showExitModal ? setAnimateThisModal(true) : setAnimateThisModal(false)
        }

        if (showExitModal) {
            animateModal(true)
            setShowMyProfile(false)
        } else {
            animateModal(false)
        }
    }, [showExitModal])

    useEffect(() => {
        if (isToHideMenu) {
            handleStatusMenu(true)
        }
    }, [isToHideMenu])

    useEffect(() => {

        setUserPic(userData.photoFile)
        function handler(event) {
            if (!modalRef.current?.contains(event.target) && !openModalRef.current?.contains(event.target)) {
                if (modalRef.current.className.indexOf('hidden') === -1) {
                    setShowMyProfile(false)
                }
            }
            if (!notificationModalRef.current?.contains(event.target) && !openNotifyRef.current?.contains(event.target)) {
                if (notificationModalRef.current.className.indexOf('hidden') === -1) {
                    setShowNotify(false)
                }
            }
        }

        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    }, [])

    useEffect(() => {
        if (!showNotify) {
            socketConnection?.emit('clearNotifications', [userData.id])
        }
    }, [showNotify])

    async function handleStatusMenu() {

        const ul = document.getElementById('ulHide')?.getAttribute('disabled')

        if (ul === 'true') {
            await new Promise(resolve => setTimeout(resolve, 500))

            setDisplayCom('flex')
            setDisplayMenuHide('none')
        }
    }

    const handleExit = async () => {

        try {

            await api.put('/auth/logout', {
            }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })

            localStorage.removeItem('@auth:token')
            setUserData('')
            setSigned(false)
        } catch (error) {
            console.log(error)
        }

    }

    function changeUlHide(hide) {

        const ul = document.querySelectorAll('#ulHide')

        if (hide) {
            ul[0].setAttribute("disabled", "true");
            return
        }
        ul[0].setAttribute("disabled", "false");
    }

    async function handleMenuSubListDisplay() {
        await new Promise(resolve => setTimeout(resolve, 1000))
        setMenuSubListDisplay('')
    }

    function formatName(firstname) {
        let sizeFirstname = firstname?.indexOf(' ')
        if (sizeFirstname > -1) {
            return firstname.slice(0, sizeFirstname)
        }
        return firstname
    }

    useEffect(() => {

        socketConnection?.on('newNotifications', async (users) => {
            const exists = users.indexOf(userData.id)
            if (exists != -1) getNotifications()
        })

        async function getNotifications() {

            const notifications = await api.get('/api/v1/notifications', {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })

            setNotifications(notifications?.data?.data)
            setNotificationsNumber(notifications?.data?.data?.filter(n => n?.status == 0)?.length)
        }

        getNotifications()

    }, [socketConnection])

    return (
        <>
            {
                showExitModal &&
                <div className='flex flex-col'>
                    <div className={`${showExitModal ? 'flex' : 'hidden'} flex-col items-center justify-center absolute w-[100vw] top-0 h-[100vh] sm:h-full z-[999999999] `}>
                        <div className={`${animateThisModal ? 'translate-y-0' : '-translate-y-96'} transition-all duration-300 bg-bgPrimaryLight dark:bg-thirdDefaultDark rounded-lg boxShadow`}>
                            <div className='flex flex-row justify-between items-center p-2'>
                                <h1 className='text-sm text-titleBlackTextLight dark:text-titleGrayTextLight'>Sair do sistema</h1>
                                <button onClick={() => setShowExitModal(false)} className='bg-gray-300 w-6 h-6 rounded-full'>x</button>
                            </div>
                            <div className='flex flex-col items-center justify-center overflow-x-hidden'>
                                <div className='flex flex-col items-center justify-center w-96 p-4'>
                                    <p className='text-titleBlackTextLight dark:text-titleGrayTextDark text-sm mb-6'>Deseja realmente sair do sistema?</p>
                                    <div className='flex flex-row w-full justify-center gap-4 items-center'>
                                        <Button approval={true} onClick={handleExit} shadow={true}>Sim</Button>
                                        <Button approval={false} onClick={() => setShowExitModal(false)} shadow={true}>Não</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${showExitModal ? 'flex' : 'hidden'} bg-gray-500 opacity-75 absolute top-0 w-full h-[100vh] sm:h-full z-[9999999]`}></div>
                </div>
            }

            <div id='menuBar' style={{ height: menuHeight }} className={`${styles.menuContainer} boxShadowT z-[202] sm:boxShadowL overflow-x-auto sm:overflow-hidden flex flex-row sm:flex-col gap-6 items-center w-full sm:w-14 bg-gradient-to-t from-secondaryDefaultLight  to-primaryDefaultLight dark:bg-gradient-to-t dark:from-secondaryDefaultLight dark:via-primaryDefaultDark dark:to-secondaryDefaultDark dark:text-titleGrayTextLight dark:border-x dark:border-primaryBorderDark pr-10 sm:pr-0`}>
                <div style={{ maxHeight: screenY }} ref={notificationModalRef} className={`${showNotify ? 'flex' : 'hidden'} overflow-auto animate-[wiggle_.2s_ease-in-out] sm:items-baseline items-center justify-center sm:justify-start bottom-24 sm:w-auto z-[300] absolute boxShadow top-0 left-0 sm:left-14 flex-col bg-bgSecondaryLight dark:bg-primaryDefaultDark gap-8 sm:gap-2 border border-secondaryDefaultLight dark:border dark:border-thirdDefaultDark sm:h-full`}>
                    <div className='flex flex-col justify-start items-center w-72 gap-1'>
                        <h1 className='text-primaryDefaultLight font-bold mt-2 dark:text-primaryDefaultDarkColor'>Notificações</h1>
                        {
                            notifications?.length > 0 ?
                                notifications.map((notification) => {
                                    let action = ''
                                    switch (notification.action) {
                                        case 'created':
                                            action = 'Novo'
                                            break;
                                        case 'updated':
                                            action = 'Alterado'
                                            break;
                                        case 'canceled':
                                            action = 'Cancelado'
                                            break;
                                        default:
                                            action = 'Alerta'
                                            break;
                                    }
                                    return <div className='w-full bg-bgPrimaryLight dark:bg-thirdDefaultDark dark:border dark:border-secondaryBorderDark shadow-md flex flex-row items-center justify-start gap-2'>
                                        <div className={`w-6 h-2 ${notification.status == 0 ? 'bg-primaryDefaultLight' : 'bg-gray-300 dark:bg-gray-600'} rounded-full`}></div>
                                        <div className='flex flex-col w-full'>
                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark font-bold'>{action}</p>
                                            <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{notification.description}</p>
                                        </div>
                                    </div>
                                })
                                :
                                <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Nenhuma notificação</a>

                        }

                    </div>
                </div>
                <div ref={modalRef} className={`${showMyProfile ? 'flex' : 'hidden'} sm:items-baseline items-center text-center justify-center sm:justify-start w-full bottom-24 sm:w-auto z-[300] absolute boxShadow top-0 md:top-2 left-0 sm:left-14 flex-col bg-bgPrimaryLight dark:bg-primaryDefaultDark p-6 gap-8 sm:gap-2 border border-secondaryDefaultLight dark:border dark:border-thirdDefaultDark sm:h-48`}>
                    <button onClick={() => setShowMyProfile(!showMyProfile)} className='sm:hidden flex flex-col items-center text-white justify-center absolute right-8 top-8 border p-2 border-primaryDefaultLight rounded-full w-10 h-10 text-titleBlackTextLight font-bold bg-primaryDefaultLight'>X</button>
                    <div className='flex flex-col w-full sm:items-start md:items-center lg:items-center justify-start'>
                        <h2 className='text-primaryDefaultLight dark:text-titleGrayTextDark mt-1 mb-4 font-bold text-4xl sm:text-base'>{formatName(userData?.firstname)}</h2>
                    </div>
                    <div onClick={() => { navegate(`/myProfile`); setShowMyProfile(false) }} className='cursor-pointer w-60 flex flex-row sm:gap-2 gap-2 md:w-full  items-center sm:justify-start justify-start hover:brightness-50 duration-200 transition-all text-primaryDefaultLight dark:text-titleGrayTextDark '>
                        <div className='flex flex-row w-12 sm:w-auto items-start justify-start'>
                            <FaUserAlt className='lg:text-lg text-4xl' />
                        </div>
                        <a className='sm:text-sm text-3xl hover:brightness-125 text-primaryDefaultLight dark:text-titleGrayTextDark '>Meu perfil</a>
                    </div>
                    <div onClick={() => { setShowExitModal(true); setShowExitModal(true) }} title='Sair do sistema' className='dark:text-titleGrayTextDark cursor-pointer flex flex-row sm:gap-2 gap-2 md:w-full w-60 items-center sm:justify-start  justify-start hove duration-200 transition-all text-primaryDefaultLight'>
                        <div className='flex flex-row w-12 sm:w-auto items-start justify-start'>
                            <FaDoorOpen className='lg:text-lg text-4xl' />
                        </div>
                        <a className='sm:text-sm text-3xl hover:brightness-125 text-primaryDefaultLight dark:text-titleGrayTextDark'>Sair</a>
                    </div>
                </div>
                <div ref={openModalRef} onClick={() => setShowMyProfile(!showMyProfile)} key={`menu-1-profile`} id={`myProfile`} className={`${userPic?.length > 0 ? 'bg-transparent' : 'bg-secondaryDefaultLight dark:bg-secondaryDefaultDark'}  hover:bg-gradient-to-t hover:from-secondaryDefaultLight hover:to-primaryDefaultLight p-8 h-full sm:h-auto text-titleGrayTextLight dark:text-primaryDefaultLight w-full flex flex-col gap-2 items-center justify-center text-2xl cursor-pointer md:hover:scale-110 transition-all duration-200 py-2`}>
                    <div className={`${userPic?.length > 0 ? 'bg-transparent' : 'bg-secondaryDefaultLight dark:bg-secondaryDefaultDark'} h-10 w-10 bg-primaryDefaultLight rounded-full flex flex-col items-center justify-center`}>
                        {
                            userPic?.length > 0 ?
                                <div className='flex flex-col items-center justify-center rounded-full w-10 h-10 overflow-hidden'>
                                    <img key={userPic} src={userPic}></img>
                                </div>
                                :
                                <a className='text-titleGrayTextLight'>{userData?.firstname?.slice(0, 1)}</a>
                        }
                    </div>
                </div>

                {
                    filteredIconsItens.map((item, index) => {
                        if (![1, 2, 3, 5, 6, 7].includes(userData?.typeAccess_id) && item.name == 'Calendário') {
                            return
                        }
                        return screenX > 640 ?
                            <Tippy key={`${index}-tippy`} content={<span>{item?.name}</span>}
                                arrow={true}
                                animation='perspective'
                                placement='right'
                                delay={100}>
                                {
                                    item.path === '/notify' && hasNotify ?
                                        <div ref={openNotifyRef} key={`${item.name}-${index}`} id={`${item.name}-${index}`} onClick={() => { setShowNotify(!showNotify); setShowModificationModal(false) }} className='
                                        animate-[wiggle_.8s_ease-in-out]
                                        relative text-titleGrayTextLight dark:text-primaryDefaultDarkColor w-full flex flex-col gap-2 items-center justify-center text-2xl cursor-pointer hover:scale-110 transition-all duration-200'>
                                            {
                                                notificationsNumber > 0 ?
                                                    <div className={` bg-red-600 rounded-full h-4 w-4 absolute right-0 top-0 -translate-x-1 -translate-y-2 flex flex-col items-center justify-center`}>
                                                        <a className='text-xs text-white'>{notificationsNumber}</a>
                                                    </div>
                                                    : <></>
                                            }
                                            {item.icon}
                                        </div>
                                        :
                                        <div key={`${item.name}-${index}`} id={`${item.name}-${index}`} onClick={() => { setSelectedMode(1); navegate(item.path); setShowModificationModal(false); }} className='
                                        animate-[wiggle_.8s_ease-in-out]                                    
                                        relative text-titleGrayTextLight dark:text-primaryDefaultDarkColor w-full flex flex-col gap-2 items-center justify-center text-2xl cursor-pointer hover:scale-110 transition-all duration-200'>

                                            {item.icon}
                                        </div>
                                }

                            </Tippy>
                            :
                            <>
                                {
                                    item.path === '/notify' && hasNotify ?
                                        <div ref={openNotifyRef} key={`${item.name}-${index}`} id={`${item.name}-${index}`} onClick={() => { setShowNotify(!showNotify); setShowModificationModal(false) }} className='
                                        animate-[wiggle_.8s_ease-in-out]
                                        relative text-titleGrayTextLight dark:text-primaryDefaultDarkColor w-full flex flex-col gap-2 items-center justify-center text-2xl cursor-pointer hover:scale-110 transition-all duration-200'>
                                            {
                                                notificationsNumber > 0 ?
                                                    <div className={` bg-red-600 rounded-full h-4 w-4 absolute right-0 top-0 -translate-x-1 -translate-y-2 flex flex-col items-center justify-center`}>
                                                        <a className='text-xs text-white'>{notificationsNumber}</a>
                                                    </div>
                                                    : <></>
                                            }
                                            {item.icon}
                                        </div>
                                        :
                                        <div key={`${item.name}-${index}`} id={`${item.name}-${index}`} onClick={() => { navegate(item.path); setShowModificationModal(false); }} className='
                                        animate-[wiggle_.8s_ease-in-out]                                    
                                        relative text-titleGrayTextLight dark:text-primaryDefaultDarkColor w-full flex flex-col gap-2 items-center justify-center text-2xl cursor-pointer hover:scale-110 transition-all duration-200'>

                                            {item.icon}
                                        </div>
                                }
                            </>
                    })
                }
                {
                    removedMenuItens.length > 0 ? (
                        <div onMouseOver={() => { setDisplayMenuHide('flex'); setDisplayCom('none') }} onMouseLeave={() => handleStatusMenu()} className={`${styles.subMenu} flex flex-col hover:flex-col-reverse justify-center items-center w-full h-12 '`} onClick={() => setShowSubMenu(!showSubMenu)}>
                            <span style={{ display: displayCom }} className=' text-white font-extrabold cursor-pointer' >...</span>
                            <div style={{ display: displayMenuHide }} onMouseOver={() => { setIsToHideMenu(false) }} onMouseLeave={() => { handleMenuSubListDisplay() }} id='menuHideDiv' className={`${styles.menuHideDiv} w-full h-36 flex flex-col items-center justify-center '`}>
                                <a className='text-white font-bold'>...</a>
                                <ul id='ulHide' onMouseOver={() => changeUlHide(false)} onMouseLeave={() => changeUlHide(true)} className={`${styles.menuHoverEffect} pt-4 pb-4 rounded-lg boxShadow flex flex-col gap-2 items-start justify-center`}>
                                    {
                                        removedMenuItens.map((item) => {                                            
                                            return <li id={item.name} className='flex flex-row pt-2 pb-2 items-center justify-between pl-2 cursor-pointer w-full hover:bg-hoverLight transition-all duration-300' onClick={() => { navegate(item.path); setShowModificationModal(false) }}>
                                                <a className='text-titleBlackTextLight dark:text-primaryDefaultDarkColor text-base 2xl:text-sm font-light'>{item.name}</a>
                                            </li>


                                        })
                                    }
                                </ul>
                            </div>
                        </div>) : ''
                }
            </div>
        </>
    )
}