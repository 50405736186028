import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'
import { FaArrowLeft, FaArrowRight, FaFireExtinguisher } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

export function CalendarMainPartner({ onClick, externalSelectedDay }) {
    const [value, setValue] = useState(moment().locale('pt-br'))
    const startDay = value.clone().startOf('month').startOf('week')
    const endDay = value.clone().endOf('month').endOf('week')
    const [selectedDay, setSelectedDay] = useState(0)
    const [monthsToView, setMonthsToView] = useState([])
    const [showMonthView, setShowMonthView] = useState(false)

    const [calendar, setCalendar] = useState([])

    useEffect(() => {
        if (externalSelectedDay) {
            setSelectedDay(externalSelectedDay)
            setValue(moment().locale('pt-br'))
            let startDay = value.clone().startOf('month').startOf('week')
            let endDay = value.clone().endOf('month').endOf('week')

            const day = startDay.clone().subtract(1, 'day')
            const a = []
            while (day.isBefore(endDay, "day")) {
                a.push(
                    Array(7).fill(0).map(() => day.add(1, "day").clone())
                )
            }
            setCalendar(a)
        }

    }, [externalSelectedDay])

    useEffect(() => {

        const day = startDay.clone().subtract(1, 'day')
        const a = []
        while (day.isBefore(endDay, "day")) {
            a.push(
                Array(7).fill(0).map(() => day.add(1, "day").clone())
            )
        }
        setCalendar(a)
    }, [value])

    function listYear() {

        const monthsOfYear = []

        let controlMonth = value.clone()
        let aux = controlMonth.month()

        monthsOfYear.push(controlMonth.clone())

        while (aux < 11) {
            monthsOfYear.push(controlMonth.add(1, 'month').clone())
            aux++
        }

        controlMonth = value.clone()
        aux = controlMonth.month()

        while ((aux > 0)) {
            monthsOfYear.push(controlMonth.subtract(1, 'month').clone())
            aux--
        }

        setMonthsToView(monthsOfYear.sort(function (a, b) {
            return a.clone().month() < b.clone().month() ? -1 : a.clone().month() > b.clone().month() ? 1 : 0
        }))
    }

    function selectMonth(month) {

        if (month.clone().month() < value.clone().month()) {
            setValue(value.clone().subtract((value.clone().month() - month.clone().month()), 'month'))
            setShowMonthView(false)
            return value.clone().subtract((value.clone().month() - month.clone().month()), 'month')
        } else {
            setValue(value.clone().add(month.clone().month() - value.clone().month(), 'month'))
            setShowMonthView(false)
            return value.clone().add(month.clone().month() - value.clone().month(), 'month')
        }
    }

    function currentMonth() {
        return value.format("MMMM")
    }

    function currentYear() {
        return value.format("YYYY")
    }

    function nextMonth() {

        return value.clone().add(1, "month")
    }

    function prevMonth() {

        return value.clone().subtract(1, "month")
    }

    function nextYear() {

        return value.clone().add(1, "year")
    }

    function prevYear() {

        return value.clone().subtract(1, "year")
    }

    async function animateLeft() {

        const monthCalendar = document.getElementById('mainCalendar')

        monthCalendar.style.transition = '0.120s'
        monthCalendar.style.marginLeft = '-100px'
        monthCalendar.style.opacity = '0'

        await new Promise(resolve => setTimeout(resolve, 70))

        monthCalendar.style.transition = '0s'
        monthCalendar.style.marginLeft = '100px'

        await new Promise(resolve => setTimeout(resolve, 70))

        monthCalendar.style.transition = '0.120s'
        monthCalendar.style.opacity = '1'
        monthCalendar.style.marginLeft = '0px'
    }

    async function animateRight() {

        const monthCalendar = document.getElementById('mainCalendar')

        monthCalendar.style.transition = '0.120s'
        monthCalendar.style.marginLeft = '100px'
        monthCalendar.style.opacity = '0'

        await new Promise(resolve => setTimeout(resolve, 70))

        monthCalendar.style.transition = '0s'
        monthCalendar.style.marginLeft = '-100px'

        await new Promise(resolve => setTimeout(resolve, 70))

        monthCalendar.style.transition = '0.120s'
        monthCalendar.style.opacity = '1'
        monthCalendar.style.marginLeft = '0px'
    }

    return (

        <div className='select-none flex flex-col w-full items-center justify-start overflow-hidden'>
            {
                showMonthView ?
                    <div className='flex flex-row gap-4 bg-bgPrimaryLight dark:bg-primaryDefaultDark p-4 w-full  items-center justify-center text-lg text-titleBlackTextLight '>
                        <FaArrowLeft className='dark:text-white' onClick={() => { animateRight(); setValue(prevYear()) }} />
                        <FaArrowRight className='dark:text-white' onClick={() => { animateLeft(); setValue(nextYear()) }} />
                        <div className=' flex flex-col items-start justify-start w-56' onClick={() => { setShowMonthView(!showMonthView); listYear() }}>
                            <h2>{`${currentYear().toUpperCase()}`}</h2>
                        </div>
                    </div>
                    :
                    <>
                        <div className='flex flex-row gap-3 bg-bgPrimaryLight dark:bg-primaryDefaultDark p-2 w-full items-center justify-center text-lg text-titleBlackTextLight'>
                            <FaArrowLeft className='cursor-pointer dark:text-white' onClick={() => { animateRight(); setValue(prevMonth()) }} />
                            <FaArrowRight className='cursor-pointer dark:text-white' onClick={() => { animateLeft(); setValue(nextMonth()) }} />
                            <div title='Selecionar Mês' className='cursor-pointer   p-1 rounded-lg transition-all duration-100 flex flex-col items-start justify-start w-56' onClick={() => { setShowMonthView(!showMonthView); listYear() }}>
                                <h2 className='text-titleBlackTextLight  text-sm dark:text-white'>{`${currentMonth().toUpperCase()} DE ${currentYear().toUpperCase()}`}</h2>
                            </div>
                        </div>
                        <div className='flex flex-row w-full items-center justify-center'>
                            {["D", "S", "T", "Q", "Q", "S", "S"].map((dayWeek) => {
                                return <div className='bg-bgPrimaryLight dark:bg-primaryDefaultDark text-base text-titleBlackTextLight p-2 flex flex-col w-full justify-center items-center'><p className='dark:text-gray-500 text-gray-400 text-sm'>{dayWeek}</p></div>
                            })}
                        </div>
                    </>
            }

            {
                showMonthView ?
                    <div id='mainCalendar' className='grid grid-cols-3 w-full gap-2 p-1 bg-bgPrimaryLight'>
                        {
                            monthsToView.map(month => <div className='hover:bg-blue-100 rounded-lg transition-all duration-200 mb-4 cursor-pointer flex flex-col items-center justify-center' onClick={() => selectMonth(month)}>
                                <p className={`'bg-primaryDefaultLight  text-titleBlackTextLight p-2 rounded-full w-8 h-8 flex flex-col items-center justify-center`}>
                                    {month.format("MMMM").toString()?.slice(0, 3)}
                                </p>
                            </div>)
                        }
                    </div>
                    :
                    <div id='mainCalendar' className='flex-col w-full relative'>
                        {
                            calendar.map(week => <div className='grid grid-cols-7'>
                                {week.map(day => <div id={`day-${day.clone().format('d')}`} onClick={() => { onClick(day); setSelectedDay(day) }} className={`hover:bg-zinc-400 hover:rounded-full cursor-pointer bg-bgPrimaryLight duration-200 transition-all 'bg-bgPrimaryLight' dark:bg-primaryDefaultDark w-full  dark:border-primaryBorderDark text-sm text-titleBlackTextLight dark:text-titleGrayTextDark flex flex-col items-center justify-start `}>
                                    <p className={`${day.date() === moment().date() && day.month() === moment().month() && day.year() === moment().year() ? 'border-2 border-blue-600 dark:border-blue-300' : selectedDay !== 0 ?  selectedDay.date() === day.date() && selectedDay.month() === day.month() && selectedDay.year() === day.year() ? 'dark:bg-blue-600 bg-blue-300 ' : '' : ``} p-2 rounded-full w-8 h-8 flex flex-col items-center justify-center`}>
                                        {day.format("D").toString()}
                                    </p>
                                </div>)}
                            </div>)
                        }
                    </div>
            }
        </div>
    )

}