// Hooks
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
// Context
import { useAuth } from "../../../contexts/useAuth";
import { useThemeContext } from "../../../contexts/themeContext";
// Components
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Body } from "../../../components/container/Body";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
// Utils
import { getUsers, getPartners } from "../../../services/api/callAPIsFunctions/defaultCalls.api";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { TableDefault } from "../../../components/table/table.default";
import { TableMobile } from "../../../components/table/table.mobile";
import { TablePaginationDefault } from "../../../components/table/tablePagination.default";
import { api } from "../../../services/api/api";
import { Button } from "../../../components/buttons/button.default";
import { FaEdit, FaTrash } from "react-icons/fa";
import { responseError } from "../../../utils/responsesFunctions/error.response";

export function LinkUsers() {

    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, showNotification, setShowNotication, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [loadingTable, setLoadingTable] = useState(false)
    const [usersList, setUsersList] = useState([])
    const [usersListLinked, setUsersListLinked] = useState([])
    const [partnersListLinked, setPartnersListLinked] = useState([])
    const [partnersList, setPartnersList] = useState([])
    const [selectedPartner, setSelectedPartner] = useState('')
    const [selectedUser, setSelectedUser] = useState('')
    const [modalLinksUsers, setModalLinksUsers] = useState(false)
    const [modalLinksPartners, setModalLinksPartners] = useState(false)
    const [modalRemoveUsers, setModalRemoveUsers] = useState(false)
    const [modalRemovePartners, setModalRemovePartners] = useState(false)
    const [selectedUsersList, setSelectedUsersList] = useState([])
    const [selectedUsersListRemove, setSelectedUsersListRemove] = useState([])
    const [usersForLinked, setUsersForLinked] = useState([])
    const [partnersForLinked, setPartnersForLinked] = useState([])
    const [seletedOptionLink, setSeletedOptionLink] = useState('')
    const [info, setInfo] = useState(false)
    let ArrayUsersId = []
    let ArrayUsersIdRemove = []

    function handleSelectedUsers(user) {
        ArrayUsersId = ArrayUsersId.filter(userToFilter => userToFilter !== user?.id)
        setSelectedUsersList(ArrayUsersId)
    }

    function handleSelectedUsersRemove(user) {
        ArrayUsersIdRemove = ArrayUsersIdRemove.filter(userToFilter => userToFilter !== user?.id)
        setSelectedUsersListRemove(ArrayUsersIdRemove)
    }

    useEffect(() => {
        setLoading(true)

        async function getUsersAPI() {
            const getUsersList = await getUsers(userData?.token, 1) // 1 = Status ativo

            const filteredUsers = getUsersList?.data.filter(user => {
                return user?.id != userData?.id && [2].includes(user?.typeAccess_id)
            })

            setUsersList(filteredUsers.map((user) => {
                return {
                    ...user,
                    fullname: `${user?.firstname} ${user?.lastname}`,
                    check: <input type='checkbox' className='cursor-pointer companyTransferCheckbox' onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedUsersList((prev) => ([...prev, user?.id]))
                            ArrayUsersId.push(user?.id)

                        } else {
                            handleSelectedUsers(user)
                        }
                    }} />
                }
            }))

        }

        async function getPartnersAPI() {

            const getPartnersList = await getPartners(userData?.token, 1) // 1 = Status Ativo

            const filteredPartnersMaster = getPartnersList?.data.filter(partner => {
                return partner?.isMaster === true
            })

            setPartnersList(filteredPartnersMaster.map((partner) => {
                return {
                    ...partner,
                    check: <input type='checkbox' className='cursor-pointer companyTransferCheckbox' onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedUsersList((prev) => ([...prev, partner?.id]))
                            ArrayUsersId.push(partner?.id)

                        } else {
                            handleSelectedUsers(partner)
                        }
                    }} />
                }
            }))
            setLoading(false)

        }

        getUsersAPI()
        getPartnersAPI()

    }, [])

    useEffect(() => {
        let ArrayUsersId = []
        let ArrayUsersIdRemove = []
        setSelectedUsersList([])
        setSelectedUsersListRemove([])
    }, [seletedOptionLink])

    async function handleLinkedUsers(partner) {
        setLoading(true)

        try {

            const linkedUsersAPI = await api.get(`api/v1/linkUsers/${partner?.id}`, {
                headers: {
                    authorization: `Bearer ${userData.token}`,
                },
            })

            const filteredUsersForLinked = []

            linkedUsersAPI?.data?.data.map((user => {
                filteredUsersForLinked.push(user?.id)
            }))

            setUsersForLinked(filteredUsersForLinked)

            setUsersListLinked(linkedUsersAPI?.data?.data?.map(user => {
                return {
                    ...user,
                    fullname: `${user.firstname} ${user.lastname}`,
                    check: <input type='checkbox' className='cursor-pointer companyTransferCheckbox' onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedUsersListRemove((prev) => ([...prev, user?.id]))
                            ArrayUsersIdRemove.push(user?.id)

                        } else {
                            handleSelectedUsersRemove(user)
                        }
                    }} />
                }
            }))

            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    async function handleLinkedPartners(user) {
        setLoading(true)

        try {

            const linkedPartnersAPI = await api.get(`api/v1/linkUsers/user/${user?.id}`, {
                headers: {
                    authorization: `Bearer ${userData.token}`,
                },
            })

            const filteredPartnersLinked = []

            linkedPartnersAPI?.data?.data.map((partner => {
                filteredPartnersLinked.push(partner?.id)
            }))

            setPartnersForLinked(filteredPartnersLinked)

            setPartnersListLinked(linkedPartnersAPI?.data?.data?.map(partner => {
                return {
                    ...partner,
                    check: <input type='checkbox' className='cursor-pointer companyTransferCheckbox' onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedUsersListRemove((prev) => ([...prev, partner?.id]))
                            ArrayUsersIdRemove.push(partner?.id)

                        } else {
                            handleSelectedUsersRemove(partner)
                        }
                    }} />
                }
            }))

            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    const tableThead = [
        {
            "name": 'Código',
            "original_name": 'id'
        },
        {
            "name": 'Nome',
            "original_name": 'firstname'
        },
        {
            "name": 'Sobrenome',
            "original_name": "lastname"
        },
        {
            "name": 'E-mail',
            "original_name": 'email'
        }
    ]

    const tableTheadPartner = [
        {
            "name": 'Código',
            "original_name": 'id'
        },
        {
            "name": 'Nome',
            "original_name": 'name'
        },
        {
            "name": 'E-mail',
            "original_name": 'email'
        }
    ]

    const tableTheadModal = [
        {
            "name": 'Ação',
            "original_name": 'check'
        },
        {
            "name": 'Código',
            "original_name": 'id'
        },
        {
            "name": 'Nome',
            "original_name": 'firstname'
        },
        {
            "name": "Tipo de acesso",
            "original_name": "typeAccessDescription"
        }
    ]

    const tableTheadModalPartners = [
        {
            "name": 'Ação',
            "original_name": 'check'
        },
        {
            "name": 'Código',
            "original_name": 'id'
        },
        {
            "name": 'Nome',
            "original_name": 'name'
        },
    ]

    const tableTheadModalRemove = [
        {
            "name": 'Ação',
            "original_name": 'check'
        },
        {
            "name": 'Código',
            "original_name": 'id'
        },
        {
            "name": 'Nome Completo',
            "original_name": 'fullname'
        }
    ]

    const tableTheadModalRemovePartner = [
        {
            "name": 'Ação',
            "original_name": 'check'
        },
        {
            "name": 'Código',
            "original_name": 'id'
        },
        {
            "name": 'Nome',
            "original_name": 'name'
        }
    ]

    async function handleUsersForPartner() {
        setLoadingTable(true)
        try {

            await api.post(`api/v1/linkUsers`, {
                partners_id: selectedPartner?.id,
                users: selectedUsersList
            }, {
                headers: {
                    authorization: `Bearer ${userData.token}`,
                },
            })

            setSelectedUsersList([])
            ArrayUsersId = []
            setModalLinksUsers(false)
            handleLinkedUsers(selectedPartner)
            selectedUser && handleLinkedPartners(selectedUser)
            setShowModificationModal(true)
            setShowNotificationModalText('Usuários vinculados com sucesso')
            setShowNotificationModalSuccess(true)
            setLoadingTable(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoadingTable(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoadingTable(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handlePartnersForUser() {
        setLoadingTable(true)
        try {

            await api.post(`api/v1/linkUsers/user`, {
                users_id: selectedUser?.id,
                partners: selectedUsersList
            }, {
                headers: {
                    authorization: `Bearer ${userData.token}`,
                },
            })

            setSelectedUsersList([])
            ArrayUsersId = []
            setModalLinksPartners(false)
            handleLinkedPartners(selectedUser)
            selectedPartner && handleLinkedUsers(selectedPartner)
            setShowModificationModal(true)
            setShowNotificationModalText('Parceiros vinculados com sucesso')
            setShowNotificationModalSuccess(true)
            setLoadingTable(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoadingTable(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoadingTable(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleUsersForPartnerRemove() {
        setLoadingTable(true)

        try {

            await api.put(`api/v1/linkUsers`, {
                partners_id: selectedPartner?.id,
                users: selectedUsersListRemove
            }, {
                headers: {
                    authorization: `Bearer ${userData.token}`,
                },
            })

            setSelectedUsersListRemove([])
            ArrayUsersIdRemove = []
            setModalRemoveUsers(false)
            handleLinkedUsers(selectedPartner)
            selectedUser && handleLinkedPartners(selectedUser)
            setShowModificationModal(true)
            setShowNotificationModalText('Usuários desvinculados com sucesso')
            setShowNotificationModalSuccess(true)
            setLoadingTable(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoadingTable(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoadingTable(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handlePartnersForUserRemove() {
        setLoadingTable(true)
        try {

            await api.put(`api/v1/linkUsers/user`, {
                users_id: selectedUser?.id,
                partners: selectedUsersListRemove
            }, {
                headers: {
                    authorization: `Bearer ${userData.token}`,
                },
            })

            setSelectedUsersListRemove([])
            ArrayUsersIdRemove = []
            setModalRemovePartners(false)
            handleLinkedPartners(selectedUser)
            selectedPartner && handleLinkedUsers(selectedPartner)
            setShowModificationModal(true)
            setShowNotificationModalText('Parceiros desvinculados com sucesso')
            setShowNotificationModalSuccess(true)
            setLoadingTable(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoadingTable(false)
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoadingTable(false)
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const customFunctions = {
        isValid: true,
        collumn: 0
    }

    const customFunctionsRemove = {
        isValid: true,
        collumn: 0
    }

    const linkOption = [
        {
            id: 1,
            name: 'Parceiro'
        },
        {
            id: 2,
            name: 'Usuário'
        }
    ]

    return (
        <>
            <Container>
                <div className={`${modalLinksUsers ? `opacity-1 flex` : `opacity-0 hidden`} flex-col justify-center items-center w-full h-full absolute z-[9999999993] top-0 left-0`}>
                    <div className="absolute z-[9999999992] h-[35rem] sm:h-max-[40rem] p-6 bg-white dark:text-titleGrayTextDark dark:bg-primaryDefaultDark shadow-lg rounded-lg flex flex-col items-center justify-center">
                        <h4 className="text-sm">Selecione os usuários que deseja vincular</h4>
                        {
                            loading ?
                                <DefaultLoader />
                                :
                                <>
                                    <div className='hidden sm:flex flex-col h-fit mt-5 lg:w-[50rem] w-[20rem] shadow-xl rounded-md justify-center items-center overflow-auto top-0 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-300'>
                                        <TableDefault
                                            title={tableTheadModal}
                                            data={usersList.filter(e => { return !usersForLinked.includes(e?.id) })} //Não trazer usuários que já estão vinculados com o parceiro
                                            cursorPointer={true}
                                            customFunctions={customFunctions}
                                            collumns={["check", "id", "firstname", "typeAccessDescription"]}
                                        />
                                    </div>
                                    <div className='sm:hidden flex lg:w-[50rem] w-[20rem] overflow-auto top-0 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-300'>
                                        <TableMobile
                                            // collumns={["check", "id", "firstname", "typeAccessDescription"]}
                                            // functions={[{ "title": "Editar", "icon": <FaEdit /> }, { "title": "Excluir", "icon": <FaTrash /> }]}
                                            title={tableTheadModal}
                                            data={usersList.filter(e => { return !usersForLinked.includes(e?.id) })}
                                        />
                                    </div>

                                    <div className="flex flex-row gap-2 items-center justify-center mt-7">
                                        <Button shadow={true} onClick={() => { handleUsersForPartner() }}>Vincular usuários</Button>
                                        <Button shadow={true} onClick={() => setModalLinksUsers(false)} approval={false}>Cancelar</Button>
                                    </div>
                                </>
                        }
                    </div>
                    <div className="w-full h-full absolute z-[999999991] opacity-75 bg-zinc-500"></div>
                </div>
                <div className={`${modalLinksPartners ? `opacity-1 flex` : `opacity-0 hidden`} flex-col justify-center items-center w-full h-full absolute z-[9999999993] top-0 left-0`}>
                    <div className="absolute z-[9999999992] h-[35rem] sm:h-max-[40rem] p-6 bg-white dark:text-titleGrayTextDark dark:bg-primaryDefaultDark shadow-lg rounded-lg flex flex-col items-center justify-center">
                        <h4 className="lg:text-xl">Selecione os parceiros que deseja vincular</h4>
                        {
                            loadingTable ?
                                <DefaultLoader />
                                :
                                <>
                                    <div className='hidden sm:flex flex-col h-fit mt-5 lg:w-[50rem] w-[20rem] shadow-xl rounded-md justify-center items-center overflow-auto top-0 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-300'>
                                        <TableDefault
                                            title={tableTheadModalPartners}
                                            data={partnersList.filter(e => { return !partnersForLinked.includes(e?.id) })}
                                            cursorPointer={true}
                                            customFunctions={customFunctions}
                                            collumns={["check", "id", "name"]}
                                        />
                                    </div>
                                    <div className='sm:hidden flex lg:w-[50rem] w-[20rem] overflow-auto top-0 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-300'>
                                        <TableMobile
                                            // collumns={["check", "id", "firstname", "typeAccessDescription"]}
                                            // functions={[{ "title": "Editar", "icon": <FaEdit /> }, { "title": "Excluir", "icon": <FaTrash /> }]}
                                            title={tableTheadModalPartners}
                                            data={partnersList.filter(e => { return !partnersForLinked.includes(e?.id) })}
                                        />
                                    </div>
                                    <div className="flex flex-row gap-2 items-center justify-center mt-7">
                                        <Button shadow={true} onClick={() => { handlePartnersForUser() }}>Vincular parceiros</Button>
                                        <Button shadow={true} onClick={() => setModalLinksPartners(false)} approval={false}>Cancelar</Button>
                                    </div>
                                </>
                        }
                    </div>
                    <div className="w-full h-full absolute z-[999999991] opacity-75 bg-zinc-500"></div>
                </div>
                <div className={`${modalRemoveUsers ? `opacity-1 flex` : `opacity-0 hidden`} flex-col justify-center items-center w-full h-full absolute z-[9999999993] top-0 left-0`}>
                    <div className="absolute z-[9999999992] h-[35rem] sm:h-max-[40rem] p-6 bg-white dark:text-titleGrayTextDark dark:bg-primaryDefaultDarkColor shadow-lg rounded-lg flex flex-col items-center justify-center">
                        <h4 className="text-sm">Selecione os usuários que deseja desvincular</h4>
                        {
                            loadingTable ?
                                <DefaultLoader />
                                :
                                <>
                                    <div className='hidden sm:flex flex-col h-fit mt-5 lg:w-[50rem] w-[20rem] shadow-xl rounded-md justify-center items-center overflow-auto top-0 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-300'>
                                        <TableDefault
                                            title={tableTheadModalRemove}
                                            data={usersListLinked}
                                            cursorPointer={true}
                                            customFunctions={customFunctionsRemove}
                                            collumns={["check", "id", "fullname"]}
                                        />
                                    </div>
                                    <div className='sm:hidden flex lg:w-[50rem] w-[20rem] overflow-auto top-0 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-300'>
                                        <TableMobile
                                            // collumns={["check", "id", "firstname", "typeAccessDescription"]}
                                            // functions={[{ "title": "Editar", "icon": <FaEdit /> }, { "title": "Excluir", "icon": <FaTrash /> }]}
                                            title={tableTheadModalRemove}
                                            data={usersListLinked}
                                        />
                                    </div>
                                    <div className="flex flex-row gap-2 items-center justify-center mt-7">
                                        <Button shadow={true} onClick={() => { handleUsersForPartnerRemove() }}>Desvincular usuários</Button>
                                        <Button shadow={true} onClick={() => setModalRemoveUsers(false)} approval={false}>Cancelar</Button>
                                    </div>
                                </>
                        }
                    </div>
                    <div className="w-full h-full absolute z-[999999991] opacity-75 bg-zinc-500"></div>
                </div>
                <div className={`${modalRemovePartners ? `opacity-1 flex` : `opacity-0 hidden`} flex-col justify-center items-center w-full h-full absolute z-[9999999993] top-0 left-0`}>
                    <div className="absolute z-[9999999992] h-[35rem] sm:h-max-[40rem] p-6 bg-white dark:text-titleGrayTextDark dark:bg-primaryDefaultDarkColor shadow-lg rounded-lg flex flex-col items-center justify-center">
                        <h4 className="text-xl">Selecione os parceiros que deseja desvincular</h4>
                        {
                            loadingTable ?
                                <DefaultLoader />
                                :
                                <>
                                    <div className='hidden sm:flex flex-col h-fit mt-5 lg:w-[50rem] w-[20rem] shadow-xl rounded-md justify-center items-center overflow-auto top-0 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-300'>
                                        <TableDefault
                                            title={tableTheadModalRemovePartner}
                                            data={partnersListLinked}
                                            cursorPointer={true}
                                            customFunctions={customFunctionsRemove}
                                            collumns={["check", "id", "name"]}
                                        />
                                    </div>
                                    <div className='sm:hidden flex lg:w-[50rem] w-[20rem] overflow-auto top-0 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-300'>
                                        <TableMobile
                                            // collumns={["check", "id", "firstname", "typeAccessDescription"]}
                                            // functions={[{ "title": "Editar", "icon": <FaEdit /> }, { "title": "Excluir", "icon": <FaTrash /> }]}
                                            title={tableTheadModalRemovePartner}
                                            data={partnersListLinked}
                                        />
                                    </div>
                                    <div className="flex flex-row gap-2 items-center justify-center mt-7">
                                        <Button shadow={true} onClick={() => { handlePartnersForUserRemove() }}>Desvincular parceiros</Button>
                                        <Button shadow={true} onClick={() => setModalRemovePartners(false)} approval={false}>Cancelar</Button>
                                    </div>
                                </>
                        }
                    </div>
                    <div className="w-full h-full absolute z-[999999991] opacity-75 bg-zinc-500"></div>
                </div>
                <TitlePage displayNotification={showNotification} setDisplayNotification={e => setShowNotication(e)}>
                    <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Vinculos de Parceiros e Supervisores'}></Title>
                        </div>
                        {
                            selectedPartner && seletedOptionLink?.id == 1 ?
                                <div className='flex flex-col items-center justify-center w-[50%] md:w-auto'>
                                    <Button shadow={true} onClick={() => { setModalLinksUsers(true) }}>+ Vincular Usuários</Button>
                                </div>
                                : selectedUser && seletedOptionLink?.id == 2 ?
                                    <Button shadow={true} onClick={() => { setModalLinksPartners(true) }}>+ Vincular Parceiros</Button>
                                    : <></>
                        }
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                            <DefaultLoader />
                            :
                            <div className="w-full">
                                {
                                    selectedPartner && usersListLinked.length >= 0 && seletedOptionLink?.id == 1 ?
                                        <>

                                            <div className="flex lg:flex-row flex-col items-center justify-between">
                                                <Button approval={false} className={`flex`} onClick={() => { setModalRemoveUsers(true) }}>Desvincular usuários</Button>
                                                <div className="flex lg:flex-row flex-col gap-4">
                                                    <LabelInput>
                                                        <InputAutoComplete
                                                            selectedLabel={'name'}
                                                            id='linkOption'
                                                            data={linkOption}
                                                            optionList={['id', 'name']}
                                                            preSelectedValue={seletedOptionLink?.name ? seletedOptionLink?.name : ''}
                                                            onChange={e => {
                                                                if (e) {
                                                                    if (typeof (e) == 'object') {
                                                                        setSeletedOptionLink(e)
                                                                    }
                                                                }
                                                            }}
                                                            placeHolder={' - '}
                                                            separator={'Parceiro ou Usuário'}
                                                        />
                                                    </LabelInput>
                                                    <LabelInput>
                                                        <InputAutoComplete
                                                            selectedLabel={'name'}
                                                            id='partners_id'
                                                            data={partnersList}
                                                            optionList={['id', 'name']}
                                                            preSelectedValue={selectedPartner?.name ? selectedPartner?.name : ''}
                                                            onChange={e => {
                                                                if (e) {
                                                                    if (typeof (e) == 'object') {
                                                                        setSelectedPartner(e)
                                                                        handleLinkedUsers(e)
                                                                    }
                                                                }
                                                            }}
                                                            placeHolder={' - '}
                                                            separator={'Parceiro'}
                                                        />
                                                    </LabelInput>
                                                </div>
                                            </div>
                                        </>
                                        : selectedUser && partnersListLinked.length >= 0 && seletedOptionLink?.id == 2 ?
                                            <div className="flex lg:flex-row flex-col items-center justify-between">
                                                <Button approval={false} className={`flex`} onClick={() => { setModalRemovePartners(true) }}>Desvincular parceiros</Button>
                                                <div className="flex lg:flex-row flex-col gap-4">
                                                    <LabelInput>
                                                        <InputAutoComplete
                                                            selectedLabel={'name'}
                                                            id='linkOption'
                                                            data={linkOption}
                                                            optionList={['id', 'name']}
                                                            preSelectedValue={seletedOptionLink?.name ? seletedOptionLink?.name : ''}
                                                            onChange={e => {
                                                                if (e) {
                                                                    if (typeof (e) == 'object') {
                                                                        setSeletedOptionLink(e)
                                                                    }
                                                                }
                                                            }}
                                                            placeHolder={' - '}
                                                            separator={'Parceiro ou Usuário'}
                                                        />
                                                    </LabelInput>
                                                    <LabelInput>
                                                        <InputAutoComplete
                                                            selectedLabel={'fullname'}
                                                            id='users_id'
                                                            data={usersList}
                                                            optionList={['id', 'fullname']}
                                                            preSelectedValue={selectedUser?.fullname ? selectedUser?.fullname : ''}
                                                            onChange={e => {
                                                                if (e) {
                                                                    if (typeof (e) == 'object') {
                                                                        setSelectedUser(e)
                                                                        handleLinkedPartners(e)
                                                                    }
                                                                }
                                                            }}
                                                            placeHolder={' - '}
                                                            separator={'Parceiro'}
                                                        />
                                                    </LabelInput>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                }
                                {
                                    !seletedOptionLink &&

                                    <div className="mt-8 items-center justify-center flex flex-col gap-4">
                                        <div className="bg-gray-50 border border-gray-100 p-2 w-full flex flex-col items-start justify-start dark:bg-[#22272E] dark:border-0">
                                            <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark text-center">Selecione  ''1''  para vincular <strong>parceiro a um ou mais usuarios.</strong> </p>
                                            <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark text-center">Selecione  ''2''  para vincular <strong>usuários a um parceiro.</strong> </p>
                                        </div>
                                        <LabelInput>
                                            <InputAutoComplete
                                                selectedLabel={'name'}
                                                id='linkOption'
                                                data={linkOption}
                                                optionList={['id', 'name']}
                                                preSelectedValue={seletedOptionLink?.name ? seletedOptionLink?.name : ''}
                                                onChange={e => {
                                                    if (e) {
                                                        if (typeof (e) == 'object') {
                                                            setSeletedOptionLink(e)
                                                            setInfo(true)
                                                        }
                                                    }
                                                }}
                                                placeHolder={' - '}
                                                separator={'Parceiro ou Usuário'}
                                            />
                                        </LabelInput>
                                    </div>
                                }
                            </div>
                    }
                    {
                        seletedOptionLink?.id == 1 && selectedPartner && usersListLinked.length >= 0 ?
                            <>
                                <div id='clientsTableContent' className='hidden  sm:flex flex-col items-center justify-center w-full mt-3 boxShadow overflow-y-auto max-h-96 rounded-lg'>
                                    <TableDefault
                                        title={tableThead}
                                        data={usersListLinked}
                                        cursorPointer={true}
                                        collumns={["id", "firstname", "lastname", "email"]}
                                    />
                                </div>
                                <div id='clientsTableContentMobile' className='sm:hidden lg:w-[50rem] w-[20rem] flex'>

                                    <TableMobile
                                        // collumns={["id", "firstname", "lastname", "email"]}
                                        // functions={[{ "title": "Editar", "icon": <FaEdit /> }, { "title": "Excluir", "icon": <FaTrash /> }]}
                                        title={tableThead}
                                        data={usersListLinked}
                                    />
                                </div>


                            </>
                            : seletedOptionLink?.id == 2 && selectedUser && partnersListLinked.length >= 0 ?
                                <>
                                    <div id='clientsTableContent' className='hidden sm:flex flex-col items-center justify-center w-full mt-3 boxShadow overflow-y-auto max-h-96 rounded-lg'>
                                        <TableDefault
                                            title={tableTheadPartner}
                                            data={partnersListLinked}
                                            cursorPointer={true}
                                            collumns={["id", "name", "email"]}
                                        />
                                    </div>
                                    <div className='sm:hidden flex lg:w-[50rem] w-[20rem]'>

                                        <TableMobile
                                            // collumns={["id", "firstname", "lastname", "email"]}
                                            // functions={[{ "title": "Editar", "icon": <FaEdit /> }, { "title": "Excluir", "icon": <FaTrash /> }]}
                                            title={tableTheadPartner}
                                            data={partnersListLinked}

                                        />
                                    </div>
                                </>
                                : seletedOptionLink?.id == 1 ?
                                    <>
                                        <div className="bg-gray-50 border border-gray-100 p-2 w-full flex flex-col items-start justify-start dark:bg-[#22272E] dark:border-0">
                                            <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark text-center">Selecione um parceiro para vincular usuários</p>
                                        </div>
                                        <LabelInput>
                                            <InputAutoComplete
                                                selectedLabel={'name'}
                                                id='partners_id'
                                                data={partnersList}
                                                optionList={['id', 'name']}
                                                preSelectedValue={selectedPartner?.name ? selectedPartner?.name : ''}
                                                onChange={e => {
                                                    if (e) {
                                                        if (typeof (e) == 'object') {
                                                            setSelectedPartner(e)
                                                            handleLinkedUsers(e)
                                                        }
                                                    }
                                                }}
                                                placeHolder={' - '}
                                                separator={'Parceiro'}
                                            />
                                        </LabelInput>
                                    </>
                                    : seletedOptionLink?.id == 2 &&
                                    <>
                                        <div className="bg-gray-50 border border-gray-100 p-2 w-full flex flex-col items-start justify-start dark:bg-[#22272E] dark:border-0">
                                            <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark text-center">Somente usuários do tipo <strong>supervisão poderão ser vinculados aos parceiros. </strong></p>
                                            <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark text-center">Após ser criado o vinculo o usuário que foi vinculado ao parceiro(s) poderá criar novos agendamentos apenas para este(s) parceiro(s).</p>
                                        </div>
                                        <LabelInput>
                                            <InputAutoComplete
                                                selectedLabel={'fullname'}
                                                id='users_id'
                                                data={usersList}
                                                optionList={['id', 'fullname']}
                                                preSelectedValue={selectedUser?.fullname ? selectedUser?.fullname : ''}
                                                onChange={e => {
                                                    if (e) {
                                                        if (typeof (e) == 'object') {
                                                            setSelectedUser(e)
                                                            handleLinkedPartners(e)
                                                        }
                                                    }
                                                }}
                                                placeHolder={' - '}
                                                separator={'Usuário'}
                                            />
                                        </LabelInput>
                                    </>
                    }
                </Body>
            </Container>
        </>
    )
}