import React, { useState } from 'react'
import { NotificationModal } from '../modals/notification/notification.modal'

export function TitlePage({ children, dontShowNotification }) {

    return (

        <div style={{minHeight: '80px'}} className='shadow-sm mb-2 bg-bgPrimaryLight border-t border-zinc-200 dark:bg-primaryDefaultDark dark:border-b dark:border-primaryBorderDark flex flex-col items-center justify-center w-full pl-8 pr-8 min-h-20 h-20'>
            <NotificationModal dontShow={dontShowNotification}>

            </NotificationModal>
            <div className='flex flex-col w-full justify-center items-start max-w-[1950px]'>
                {children}
            </div>
        </div>
    )
}