// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// components 
import { FaDownload, FaEdit, FaFilter } from 'react-icons/fa'
import { FiSlash } from 'react-icons/fi'
import { TableMobile } from '../../../components/table/table.mobile'
import { TitlePage } from '../../../components/titlePages/title.page'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import { Button } from '../../../components/buttons/button.default'
import { Title } from '../../../components/titlePages/title'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Input } from '../../../components/input/input'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
//services
import { api } from '../../../services/api/api'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { useSocket } from '../../../contexts/socketContext'
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { TablePaginationDefault } from '../../../components/table/tablePagination.default'
//utils
import { justNumber } from '../../../utils/validators/justNumber'

export function UsersTable() {

    const navigate = useNavigate()
    const { setShowUniversalModal } = useThemeContext()
    const { userData } = useAuth()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const [filterConstructor, setFilterConstructor] = useState({ typeAccess_id: '1,2,3,4' })
    const [filter, setFilter] = useState({ typeAccess_id: '1,2,3,4', active: 1 })
    const [usersList, setUsersList] = useState([])
    const [typeAccessList, setTypeAccessList] = useState([])
    // const [filteredUsers, setFilteredUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const { screenY, screenX } = useScreenSizeContext()
    const screenSize = screenY - 200
    const [quantity, setQuantity] = useState(0)
    // const [usersList, setUsersList] = useState([])

    useEffect(() => {

        async function getAccess() {

            const getAccess = await api.get('/api/v1/typeAccess', {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })

            const filterAccess = getAccess.data.data.filter(access => {
                return access.id < 5
            })

            setTypeAccessList(filterAccess)
        }

        getAccess()

    }, [])

    function handleEdit(data) {
        navigate(`/usersEdit/${data.id}`)
    }
    async function getExport() {

        const blob = await api.get('/api/v1/users/export', {
            headers: {
                authorization: `Bearer ${userData.token}`,
            },
            responseType: 'blob',
        })

        const url = URL.createObjectURL(blob.data)
        const linkDownload = document.createElement('a')
        linkDownload.setAttribute('href', url)
        linkDownload.setAttribute('download', `usuarios_${new Date().toISOString().split('T')[0]}`)
        linkDownload.click()
    }

    return (
        <>
            
            <ScreenModal title={'Filtros'}>
            {
                loading
                    ? <DefaultLoader />
                    : (
                        <div className='flex flex-col pt-2 pb-10 px-12 gap-4 items-center'>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                <Input
                                    charLimit={255} id='id' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                    value={filterConstructor?.id} autoFocus={true}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                <Input
                                    charLimit={255} id='firstname' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, firstname: e.target.value })}
                                    value={filterConstructor?.firstname} autoFocus={true}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Sobrenome *</a>
                                <Input
                                    charLimit={255} id='lastname' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, lastname: e.target.value })}
                                    value={filterConstructor?.lastname} autoFocus={true}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                <Input
                                    charLimit={255} id='email' type='text'
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, email: e.target.value })}
                                    value={filterConstructor?.email} autoFocus={true}
                                ></Input>
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Tipos de Acesso *</a>
                                <InputAutoComplete
                                    data={typeAccessList}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                    onChange={e => setFilterConstructor({ ...filterConstructor, typeAccess_id: e.id })}
                                    preSelectedValue={typeAccessList ? typeAccessList?.filter(type => type.id == filter.typeAccess_id)[0]?.description : ''}
                                    id='typeAccess'
                                    width={'318px'}
                                />
                            </label>
                            <label className='flex flex-col gap-1 items-start justify-center'>
                                <a className='text-sm text-inputPlaceholderLight'>Status *</a>
                                <InputAutoComplete
                                    data={[{ id: 0, description: 'Inativo' }, { id: 1, description: 'Ativo' }]}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                    onChange={e => setFilterConstructor({ ...filterConstructor, active: e.id })}
                                    preSelectedValue={[{ id: 0, description: 'Inativo' }, { id: 1, description: 'Ativo' }]?.filter(filter => filter == filterConstructor.active)[0]?.description}
                                    id='active'
                                    width={'318px'}
                                />
                            </label>
                            <div className='mt-5'>
                                <Button onClick={() => {
                                    setFilter(filterConstructor)
                                    setShowUniversalModal(false)
                                }}>Filtrar</Button>
                            </div>
                        </div>
                    )
            }
            </ScreenModal>
            <Container>
                {/* <div className='md:p-2 flex flex-col w-full bg-bgSecondaryLight md:gap-2 items-center dark:border-primaryBorderDark dark:bg-primaryDefaultDark shadow-lg'> */}
                <TitlePage displayNotification={showNotification} success={successData} setDisplayNotification={e => setShowNotication(e)}>
                    <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Usuários'}></Title>
                            <div className='flex flex-row gap-2 justify-between items-center'>
                                <h3 className='text-base font-light dark:text-titleGrayTextDark'>{quantity} Usuários</h3>
                                {
                                    [1,2].includes(userData?.typeAccess_id) ?
                                        <Tippy key={`generateDownloads`} content={<span>Exportar lista em excel</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='right'
                                            delay={100}>
                                            <div onClick={() => getExport()} id='generateDownloads' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                                <FaDownload className='dark:text-titleGrayTextDark text-titleBlackTextLight text-lg' />
                                            </div>
                                        </Tippy>
                                    : <></>
                                }
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/userCreate')}>
                            <Button shadow={true} onClick={() => {
                            }}>+ Novo usuário</Button>
                        </div>
                    </div>
                </TitlePage>
                <Body>                    
                    <div className='w-full sm:mt-0 mt-2 flex items-center gap-4'>
                        <Button onClick={() => setShowUniversalModal(true)}>
                            <FaFilter className='text-white' /> <p className='text-white pl-2'> Filtrar </p>
                        </Button>
                        {filter && JSON.stringify(filter) != JSON.stringify({ typeAccess_id: '1,2,3,4', active: 1 })
                            ? <Tippy
                                key={`clearFilter`}
                                content={<span>Limpar filtro</span>}
                                arrow={true}
                                animation='perspective'
                                placement='right'
                                delay={100}>
                                <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                    <FiSlash className='cursor-pointer' onClick={async () => {
                                        setLoading(true)
                                        setFilter({ typeAccess_id: '1,2,3,4', active: 1 })
                                        setFilterConstructor({ typeAccess_id: '1,2,3,4' })
                                        await new Promise(resolve => setTimeout(resolve, 500))
                                        setLoading(false)
                                    }} />
                                </div>
                            </Tippy>
                            : <></>
                        }
                    </div>
                    <TablePaginationDefault
                        onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                        quantity={(e) => setQuantity(e)}
                        apiUrl={'/api/v1/users/filter'}
                        filters={filter}
                        mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                        collumns={[
                            {
                                name: 'id',
                                type: 'number',
                                description: 'Código'
                            },
                            {
                                name: 'firstname',
                                type: 'string',
                                description: 'Nome'
                            },
                            {
                                name: 'lastname',
                                type: 'string',
                                description: 'Sobrenome'
                            },
                            {
                                name: 'email',
                                type: 'string',
                                description: 'E-mail'
                            },
                            {
                                name: 'typeAccessDescription',
                                type: 'string',
                                description: 'Tipo de acesso'
                            },
                            {
                                name: 'created_at',
                                type: 'date',
                                description: 'Criado em'
                            }
                        ]}
                    />                              
            </Body>
        </Container>
        </>
    )


}