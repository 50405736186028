import React from 'react'

export function Button({children, height, width, shadow, onClick, action, approval}){

    const widthPixel = width > 0 ? `w-[${width}px]` : 'w-40'
    const widthDefault = width > 0 ? `w-${width}` : 'w-40'

    const heightPixel = height > 0 ? `h-[${height}px]` : 'h-12 sm:h-8'
    const heightDefault = height > 0 ? `h-${height}` : 'h-8 sm:h-8'

    return (
        <button onClick={ () => onClick(action)} className={`flex fle-row gap-1 items-center justify-center text-sm ${approval === false ?'bg-red-800 hover:bg-red-900' : 'bg-primaryDefaultLight dark:bg-primaryDefaultDarkColor hover:bg-secondaryDefaultLight hover:dark:bg-primaryDefaultLight'} rounded-3xl  transition-all duration-300 text-titleGrayTextLight ${shadow ? 'shadow-md shadow-gray-500/50 dark:shadow-md dark:shadow-secondaryDefaultDark' : ''}
        
        px-4 py-1`}>{children}</button>
    )
}