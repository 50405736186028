//hooks
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
//context
import { useThemeContext } from '../../../contexts/themeContext'
import { FaQuestionCircle, FaTrashAlt } from 'react-icons/fa'
import { FiPlus, FiMinus, FiCopy } from 'react-icons/fi'
import { useAuth } from '../../../contexts/useAuth'
//components
import { Container } from '../../../components/container/container'
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Tooltip } from '../../../components/toolTip/toolTip'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Toggle } from '../../../components/toggle/toggleButton'
import { Footer } from '../../../components/footer/Footer'
import { LogHistory } from '../../../components/logHistory/logHistory'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { cnpjMask } from '../../../utils/validators/cnpjMask'
import { mCPF } from '../../../utils/validators/cpfMask'
import { validateFields } from '../../../utils/form.validator'
import { maskPhone } from '../../../utils/validators/phoneMask'
import { validateEmail } from '../../../utils/validators/email.validator'
import { maskCep } from '../../../utils/validators/cepMask'
import { api } from '../../../services/api/api'
import { formatSizeCep } from '../../../utils/validators/formatSizeCep'
import { formatSizeCpf } from '../../../utils/validators/formatSizeCpf'
import { oabValidate } from '../../../utils/validators/oab.validator'
import { justNumber } from '../../../utils/validators/justNumber'
import { listHours as hoursList } from '../../../utils/listHours'
import { textMask } from '../../../utils/validators/textMask'
import { validatorHour } from '../../../utils/validators/validatorHour'
import { v4 as uuid } from 'uuid'
import { removePontuaction } from '../../../utils/validators/removePontuaction'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';

export function PartnersEdit() {
    const [nameTitle, setNameTitle] = useState('')
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const { id } = useParams()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [showLogs, setShowLogs] = useState(false)
    //dados matriz
    const [name, setName] = useState('')
    const [selectedTypePerson, setSelectedTypePerson] = useState('')
    const [cnpjcpf, setCnpjCpf] = useState('')
    const [oabMaster, setOabMaster] = useState('')
    const [isValidOab, setIsValidOab] = useState(true)
    const [email, setEmail] = useState('')
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [dddphone, setDddphone] = useState('')
    const [phone, setPhone] = useState('')
    const [cep, setCep] = useState('')
    const [newAddressState, setNewAddressState] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressStateList, setAddressStateList] = useState([])
    const [addressCityList, setAddressCityList] = useState([])
    const [addressCity, setAddressCity] = useState('')
    const [addressDistrict, setAddressDistrict] = useState('')
    const [addressRoad, setAddressRoad] = useState('')
    const [addressNumber, setAddressNumber] = useState(0)
    const [addressComplement, setAddressComplement] = useState('')
    const [selectedPartnerCompanyType, setSelectedPartnerCompanyType] = useState('')
    const [listMatriz, setListMatriz] = useState([])
    const [master_id, setMaster_id] = useState('')
    const [activePartner, setActivePartner] = useState(false)
    const [listHours, setListHours] = useState([])
    const [hours, setHours] = useState({})
    const [selectedPartnerBlock, setSelectedPartnerBlock] = useState('')
    const [selectedIsRemote, setSelectedIsRemote] = useState('')
    //dados externos
    const [addressState_externalId, setAddressState_externalId] = useState('')
    const [addressCity_externalId, setAddressCity_externalId] = useState('')
    const [microregion_externalId, setMicroregion_externalId] = useState('')
    const [mesoregion_externalId, setMesoregion_externalId] = useState('')
    //dados filiais
    const [branch, setBranch] = useState({})
    const [branchRows, setBranchRows] = useState(Object.keys(branch))
    const [timeInterval, setTimeInterval] = useState('')

    const partnerType = [
        { 'id': true, 'name': 'Matriz' },
        { 'id': false, 'name': 'Filial' },
    ]

    const typePerson = [
        { 'id': 'PF', 'name': 'Pessoa Física' },
        { 'id': 'PJ', 'name': 'Pessoa Jurídica' }
    ]

    const weekday = [
        { 'id': 0, 'name': 'Domingo' },
        { 'id': 1, 'name': 'Segunda-Feira' },
        { 'id': 2, 'name': 'Terça-Feira' },
        { 'id': 3, 'name': 'Quarta-Feira' },
        { 'id': 4, 'name': 'Quinta-Feira' },
        { 'id': 5, 'name': 'Sexta-Feira' },
        { 'id': 6, 'name': 'Sábado' }
    ]

    useEffect(() => {

        async function getStates() {

            setLoading(true)

            try {

                const getState = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
                const getStateData = await getState.json()

                setAddressStateList(getStateData.sort((a, b) => a.sigla > b.sigla ? 1 : -1))

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }
        getStates()


        async function getPartnersType() {
            try {

                const searchPartners = await api.get(`/api/v1/partners?type=m`, {
                    headers: {
                        authorization: `Bearer ${userData.token}`
                    }
                })

                const filterPartners = searchPartners?.data?.data.filter(e => {
                    return e.isMaster === true && e.id != id
                })

                setListMatriz(filterPartners)
                setShowModificationModal(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        async function getPartners() {

            const getPartnersData = await api.get(`/api/v1/partners/${id}`, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })
            setNameTitle(getPartnersData.data.data[0]?.name)
            setName(getPartnersData.data.data[0]?.name)
            setSelectedTypePerson(getPartnersData.data.data[0]?.partnersType)
            setCnpjCpf(formatSizeCpf(getPartnersData.data.data[0]?.cnpjcpf))
            setOabMaster(getPartnersData.data.data[0]?.oab ? getPartnersData.data.data[0]?.oab.toUpperCase() : '')
            setEmail(getPartnersData.data.data[0]?.email)
            setDddphone(getPartnersData.data.data[0]?.dddphone)
            setPhone(maskPhone(getPartnersData.data.data[0]?.phone))
            setCep(getPartnersData.data?.data[0]?.cep ? formatSizeCep(maskCep(getPartnersData.data?.data[0]?.cep)) : '')
            setAddressState(getPartnersData.data.data[0]?.addressState)
            setNewAddressState(addressStateList.filter((item) => {
                return item?.sigla == getPartnersData.data.data[0]?.addressState
            })[0])
            setAddressCity(getPartnersData.data.data[0]?.addressCity)
            setAddressDistrict(getPartnersData.data.data[0]?.addressDistrict)
            setAddressRoad(getPartnersData.data.data[0]?.addressRoad)
            setAddressNumber(getPartnersData.data.data[0]?.addressNumber)
            setAddressComplement(getPartnersData.data.data[0]?.addressComplement)
            setSelectedPartnerCompanyType(getPartnersData.data.data[0]?.isMaster)
            setMaster_id(getPartnersData.data.data[0]?.master_id)
            setActivePartner(getPartnersData.data.data[0]?.active)
            setAddressState_externalId(getPartnersData?.data?.data[0]?.addressState_externalId)
            setAddressCity_externalId(getPartnersData?.data?.data[0]?.addressCity_externalId)
            setMicroregion_externalId(getPartnersData?.data?.data[0]?.microregion_externalId)
            setMesoregion_externalId(getPartnersData?.data?.data[0]?.mesoregion_externalId)
            setTimeInterval(getPartnersData?.data?.data[0]?.timeInterval ? getPartnersData?.data?.data[0]?.timeInterval : 0)
            setSelectedPartnerBlock(getPartnersData.data.data[0]?.partnerBlock ? getPartnersData.data.data[0]?.partnerBlock : false)
            setSelectedIsRemote(getPartnersData.data.data[0]?.isRemote ? getPartnersData.data.data[0]?.isRemote : false)

        }
        getPartners()


        async function getPartnerHours() {

            try {

                const getHoursPartner = await api.get(`/api/v1/partnersHours/${id}`, {
                    headers: {
                        authorization: `Bearer ${userData.token}`
                    }
                })

                const object = {}

                getHoursPartner?.data?.data.forEach(hour => {
                    object[uuid()] = {
                        weekday: hour.weekday,
                        startHour: hour.startHour,
                        finishHour: hour.finishHour,
                    }
                });

                setHours(object)

                setListHours(Object.keys(object))

                setLoading(false)

            } catch (error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Parceiro não tem horários definidos.')
            }
        }

        getPartnersType()
        getPartnerHours()

    }, [])

    async function getCityApi(data) {

        setLoading(true)

        try {

            const getCity = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data?.id}/municipios`)
            const getCityData = await getCity.json()

            setAddressCityList(getCityData)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSubmit() {

        setLoading(true)

        const requiredFields = [
            { name: 'name', value: name, required: true, type: 'string' },
            { name: 'typePerson', value: selectedTypePerson.id, required: true, type: 'string' },
            { name: 'cnpjcpf', value: cnpjcpf, required: true, type: 'string' },
            { name: 'addressCity', value: addressCity, required: true, type: 'string' }
        ]


        if (!newAddressState) {
            requiredFields.push({ name: 'addressState', value: newAddressState, required: true, type: 'string' })
        }

        if (!selectedPartnerCompanyType) {
            requiredFields.push({ name: 'master_id', value: master_id, required: true, type: 'string' })
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (Object.keys(hours).length > 0) {
            if (validatorHour(hours).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha os horários em vermelho')
            }
        }


        try {

            if (isValidEmail === false) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText('Email inválido')
                return setLoading(false)
            }
            if (isValidOab === false) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText('OAB inválida')
                return setLoading(false)
            }
            await api.put(`api/v1/partners/${id}`, {
                name: name ? name : undefined,
                cep: cep ? parseInt(cep.replace('-', '')) : null,
                addressRoad: addressRoad ? addressRoad : null,
                addressNumber: addressNumber ? addressNumber : null,
                addressDistrict: addressDistrict ? addressDistrict : null,
                addressCity: addressCity?.nome ? addressCity?.nome : undefined,
                addressState: newAddressState?.sigla ? newAddressState?.sigla : addressState ? addressState : undefined,
                addressComplement: addressComplement ? addressComplement : null,
                addressState_externalId: newAddressState?.id ? newAddressState?.id.toString() : addressState_externalId,
                addressCity_externalId: addressCity?.id ? addressCity?.id.toString() : addressCity_externalId,
                microregion_externalId: addressCity?.microrregiao?.id ? addressCity?.microrregiao?.id.toString() : microregion_externalId,
                mesoregion_externalId: addressCity?.microrregiao?.mesorregiao?.id ? addressCity?.microrregiao?.mesorregiao?.id.toString() : mesoregion_externalId,
                dddphone: dddphone ? dddphone : null,
                phone: phone ? parseInt(removePontuaction(phone)) : null,
                email: email ? email : null,
                oab: oabMaster ? oabMaster : undefined,
                cnpjcpf: cnpjcpf ? cnpjcpf.replace('-', '').replace('/', '').replaceAll('.', '') : undefined,
                partnersType: selectedTypePerson ? selectedTypePerson : undefined,
                isMaster: selectedPartnerCompanyType,
                master_id: !selectedPartnerCompanyType ? master_id : undefined,
                timeInterval: timeInterval > 0 ? timeInterval : undefined,
                partnerBlock: selectedPartnerBlock,
                isRemote: selectedIsRemote
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('@auth:token')}`
                }
            })

            if (Object.keys(hours).length == 0) {
                await api.put(`api/v1/partnersHours/${id}`, {}, {
                    headers: {
                        authorization: `Bearer ${userData.token}`
                    }
                })
            }

            if (Object.keys(hours).length > 0) {

                await api.post('api/v1/partnersHours', {
                    partners_id: id,
                    hours: Object.keys(hours).map(key => hours[key])
                }, {
                    headers: {
                        authorization: `Bearer ${userData.token}`
                    }
                })

            }


            setShowModificationModal(true)
            setShowNotificationModalText('Parceiro atualizado com sucesso!')
            setShowNotificationModalSuccess(true)
            setLoading(false)
            navigate('/partners')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    async function handleDelete() {
        setLoading(true)

        try {

            await api.delete(`/api/v1/partners/${id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('@auth:token')}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/partners')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    async function partnerStatus() {

        const status = !activePartner
        setActivePartner(status)
        setLoading(true)

        try {

            await api.put(`/api/v1/partners/${id}/active/${status ? 1 : 0}`, {}, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })

            setShowNotificationModalSuccess(true)
            setShowModificationModal(true)
            setLoading(false)
            setShowNotificationModalText(status === false ? `Parceiro ${name} desativado com sucesso.` : `Parceiro ${name} ativado com sucesso.`)

        } catch (error) {
            setLoading(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            }
        }
    }

    function addHour(duplicate) {

        const id = uuid()
        let add

        if (duplicate) {
            const lastId = listHours[listHours.length - 1]

            add = Object.assign(hours, {
                [id]: {
                    weekday: hours[lastId]?.weekday + 1 <= 6 ? hours[lastId]?.weekday + 1 : undefined,
                    startHour: hours[lastId]?.startHour,
                    finishHour: hours[lastId]?.finishHour
                }
            })
        } else {
            add = Object.assign(hours, {
                [id]: {
                    weekday: null,
                    startHour: null,
                    finishHour: null
                }
            })
        }
        setHours(add)

        setListHours([...listHours, id])

    }

    async function removeHour(id) {

        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 50))

        const replicaHours = hours
        const replicaListHours = listHours

        const position = listHours.indexOf(id)
        replicaListHours.splice(position, 1)

        delete replicaHours[id]

        setHours(replicaHours)
        setListHours([...replicaListHours])
        setLoading(false)
    }

    const intervalList = [
        {
            id: 0,
            value: 0,
            description: 'Livre'
        },
        {
            id: 1,
            value: 15,
            description: '15 minutos'
        },
        {
            id: 1,
            value: 30,
            description: '30 minutos'
        },
        {
            id: 1,
            value: 60,
            description: '1 hora'
        }
    ]

    const options = [
        {
            description: 'Sim',
            value: true
        },
        {
            description: 'Não',
            value: false
        },
    ]

    return (
        <>
            <ScreenModal title={'Exlusão de usuário'} width={400} height={200} >
                <div className='w-full p-2 h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir o parceiro {`${name}`} ?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex flex-row w-full justify-between'>
                        <Title text={` Editar parceiro - ${nameTitle}`}></Title>
                        {
                            [1,2].includes(userData?.typeAccess_id) &&
                            <Button onClick={() => navigate(`/blockSchedule/${id}`)}>Bloqueios Agenda</Button>
                        }
                    </div>
                </TitlePage>
                <Body hasFooter={true}>
                    <div className='flex flex-col items-start justify-start w-full'>
                        {
                            loading ?
                                <div className='flex flex-col w-full items-center justify-center'>
                                    <DefaultLoader />
                                </div>
                                :
                                <>
                                    <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                                        <form className='flex flex-col items-center  lg:grid lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>
                                            <LabelInput text={'Nome *'}>
                                                <Input type={'text'} value={name} id='name' onChange={(e) => setName(textMask(e.target.value))} charLimit={100} />
                                            </LabelInput>
                                            <LabelInput text={'Tipo Pessoa *'}>
                                                <InputAutoComplete
                                                    preSelectedValue={selectedTypePerson ? typePerson.filter(type => type.id == selectedTypePerson)[0].name : ''}
                                                    id='typePerson'
                                                    selectedLabel={'name'}
                                                    data={typePerson}
                                                    optionList={['name']}
                                                    onChange={e => {
                                                        if (e.id !== selectedTypePerson) {
                                                            setCnpjCpf('')
                                                        }
                                                        setSelectedTypePerson(e.id)
                                                    }}
                                                >
                                                </InputAutoComplete>
                                            </LabelInput>
                                            {
                                                selectedTypePerson === 'PJ' ?
                                                    <LabelInput text={'CNPJ *'}>
                                                        <Input type={'text'} value={cnpjMask(cnpjcpf)} id='cnpjcpf' onChange={(e) => setCnpjCpf(e.target.value)} charLimit={18} />
                                                    </LabelInput>
                                                    : selectedTypePerson === 'PF' ?
                                                        <LabelInput text={'CPF *'}>
                                                            <Input type={'text'} value={mCPF(cnpjcpf)} id='cnpjcpf' onChange={(e) => setCnpjCpf(e.target.value)} charLimit={14} />
                                                        </LabelInput>
                                                        :
                                                        <></>
                                            }
                                            <LabelInput valid={!isValidOab ? false : true} text={'OAB'}>
                                                <Input type={'text'} id='oab' value={oabMaster} onChange={(e) => {
                                                    const isValid = oabValidate(e.target.value)
                                                    if (!isValid) {
                                                        setIsValidOab(false)
                                                    } else {
                                                        setIsValidOab(true)

                                                    }
                                                    setOabMaster(e.target.value)
                                                }} charLimit={8} />
                                            </LabelInput>
                                            <LabelInput text={'E-mail'} valid={!isValidEmail ? false : true}>
                                                <Input type={'text'} value={email} id='email' onChange={(e) => {
                                                    const isValid = validateEmail(e.target.value)
                                                    if (isValid === true) {
                                                        setIsValidEmail(true)
                                                    } else {
                                                        setIsValidEmail(false)
                                                    }
                                                    setEmail(e.target.value)
                                                }} charLimit={255} />
                                            </LabelInput>
                                            {/* <div className='flex lg:hidden flex-row gap-2'>
                                                <LabelInput text={'DDD'}>
                                                    <Input width={'56px'} type={'text'} id='dddphone' value={dddphone} onChange={(e) => setDddphone(justNumber(e.target.value))} charLimit={2} />
                                                </LabelInput>
                                                <LabelInput text={'Telefone'}>
                                                    <Input type={'text'} width={'255px'} value={phone} id='phone' onChange={(e) => setPhone(maskPhone(e.target.value))} charLimit={9} />
                                                </LabelInput>
                                            </div> */}
                                            <div className='hidden lg:flex flex-col sm:flex-row gap-2'>
                                                <LabelInput text={'DDD'}>
                                                    <Input width={'56px'} type={'text'} value={dddphone} onChange={(e) => setDddphone(justNumber(e.target.value))} charLimit={2} />
                                                </LabelInput>
                                                <LabelInput text={'Telefone'}>
                                                    <Input width={'255px'} type={'text'} value={phone} onChange={(e) => setPhone(maskPhone(e.target.value))} charLimit={9} />
                                                </LabelInput>
                                            </div>
                                            <LabelInput text={'CEP'}>
                                                <Input type={'text'} value={cep} id='cep' onChange={(e) => setCep(maskCep(e.target.value))} charLimit={9} />
                                            </LabelInput>
                                            <LabelInput text={'Estado *'}>
                                                <InputAutoComplete
                                                    preSelectedValue={newAddressState ? newAddressState?.nome : addressStateList.filter((item) => {
                                                        return item?.sigla == addressState
                                                    })[0]?.nome}
                                                    selectedLabel={'nome'} id='addressState'
                                                    data={addressStateList} optionList={['nome']}
                                                    onChange={e => {
                                                        if (typeof(e) == 'object'){
                                                            setAddressCity('')
                                                            setNewAddressState(e)
                                                            e?.nome !== undefined && getCityApi(e)
                                                        }
                                                    }}
                                                >
                                                </InputAutoComplete>
                                            </LabelInput>
                                            {
                                                !newAddressState?.nome ?
                                                    <LabelInput text={'Cidade *'}>
                                                        <Input type={'text'} value={addressCity} id='addressCity' disabled={true} />
                                                    </LabelInput>
                                                    :
                                                    <LabelInput text={'Cidade *'}>
                                                        <InputAutoComplete
                                                            preSelectedValue={addressCity ? addressCity : ''}
                                                            value={addressCity ? addressCity?.nome : addressCityList?.nome}
                                                            selectedLabel={'nome'} id='addressCity'
                                                            data={addressCityList} optionList={['nome']}
                                                            onChange={e => {
                                                                setAddressCity(e)
                                                            }}>
                                                        </InputAutoComplete>
                                                    </LabelInput>
                                            }
                                            <LabelInput text={'Bairro'}>
                                                <Input type={'text'} value={addressDistrict} onChange={e => setAddressDistrict(e.target.value)} />
                                            </LabelInput>
                                            <LabelInput text={'Logradouro'}>
                                                <Input type={'text'} value={addressRoad} onChange={e => setAddressRoad(e.target.value)} />
                                            </LabelInput>
                                            <LabelInput text={'Número'}>
                                                <Input type={'text'} id='addressNumber' value={addressNumber} onChange={(e) => setAddressNumber(justNumber(e.target.value))} charLimit={8} />
                                            </LabelInput>
                                            <LabelInput text={'Complemento'}>
                                                <Input type={'text'} value={addressComplement} onChange={(e) => setAddressComplement(e.target.value)} charLimit={100} />
                                            </LabelInput>
                                            <LabelInput text={'Tipo *'}>
                                                <InputAutoComplete
                                                    preSelectedValue={selectedPartnerCompanyType ? partnerType[0].name : partnerType[1].name}
                                                    selectedLabel={'name'} id={'partnerType'}
                                                    data={partnerType} optionList={['name']}
                                                    onChange={e => setSelectedPartnerCompanyType(e.id)}>
                                                </InputAutoComplete>
                                            </LabelInput>
                                            {
                                                !selectedPartnerCompanyType ?
                                                    <LabelInput text={'Selecione a matriz *'}>
                                                        <InputAutoComplete
                                                            preSelectedValue={!selectedPartnerCompanyType ? listMatriz.filter(item => item?.id == master_id)[0]?.name : ''}
                                                            selectedLabel={'name'} id='master_id'
                                                            data={listMatriz} optionList={['name']}
                                                            onChange={e => {
                                                                setMaster_id(e?.id)
                                                            }}>
                                                        </InputAutoComplete>
                                                    </LabelInput>
                                                    :
                                                    <></>
                                            }
                                            <div className='flex flex-col relative'>
                                                <LabelInput text={'Agenda fixa *'}>
                                                    <InputAutoComplete 
                                                        preSelectedValue={timeInterval ? intervalList.filter(e => {return e?.value == timeInterval})[0]?.description : timeInterval == 0 ? intervalList.filter(e => {return e?.value == timeInterval})[0]?.description : ''}
                                                        selectedLabel={'name'} id={'schedulesType'} 
                                                        data={intervalList} 
                                                        optionList={['description']} 
                                                        onChange={e => {
                                                            setTimeInterval(e?.value)
                                                        }}>
                                                    </InputAutoComplete>
                                                </LabelInput>
                                                <Tippy content={<span>Defina o tempo máximo da agenda desse parceiro por atendimento, marque livre para que o operador que irá agendar possa escolher o horáro final</span>}
                                                    arrow={true}
                                                    animation='perspective'
                                                    placement='top'
                                                    delay={100}>

                                                    <div className='absolute right-2'>
                                                        <FaQuestionCircle />
                                                    </div>

                                                </Tippy>
                                            </div>
                                            <div className='flex flex-col relative'>
                                                <LabelInput text={'Bloquear agenda'}>
                                                    <InputAutoComplete
                                                        preSelectedValue={options.find(item => item?.value == selectedPartnerBlock).description}
                                                        selectedLabel={'description'} 
                                                        id={'partnerBlock'}
                                                        data={options}
                                                        optionList={['description']}
                                                        onChange={e => {
                                                            if (typeof(e) == 'object'){
                                                                setSelectedPartnerBlock(e?.value)
                                                            }
                                                        }}>
                                                    </InputAutoComplete>
                                                </LabelInput>
                                                <Tippy content={<span>Defina se a agenda do parceiro vai ser bloqueada, caso não atualize os agendamentos em 7 dias.</span>}
                                                    arrow={true}
                                                    animation='perspective'
                                                    placement='top'
                                                    delay={100}>

                                                    <div className='absolute right-2'>
                                                        <FaQuestionCircle />
                                                    </div>

                                                </Tippy>
                                            </div>
                                            <LabelInput text={'Atendimento online *'}>
                                                <InputAutoComplete
                                                    preSelectedValue={options?.find(item => item?.value == selectedIsRemote)?.description}
                                                    value={selectedIsRemote ? options.find(item => item?.value == selectedIsRemote).description : ''}
                                                    selectedLabel={'description'} 
                                                    id={'selectedIsRemote'}
                                                    data={options}
                                                    optionList={['description']}
                                                    onChange={e => {
                                                        if (typeof(e) == 'object'){
                                                            setSelectedIsRemote(e?.value)
                                                        }
                                                    }}>
                                                </InputAutoComplete>
                                            </LabelInput>
                                            <div className='flex flex-col w-full items-center lg:items-start justify-center mt-7'>
                                                <Tippy content={<span>{activePartner ? 'Desativar' : 'Ativar'}</span>}
                                                    arrow={true}
                                                    animation='perspective'
                                                    placement='top'
                                                    delay={100}>
                                                    <div>
                                                        <Toggle status={activePartner ? true : false} onClick={(e) => partnerStatus()} />
                                                    </div>
                                                </Tippy>
                                            </div>
                                        </form>
                                        {
                                            listHours.length > 0 ?
                                                <>
                                                    <br />
                                                    <br />
                                                    {
                                                        Object.keys(hours).map((item) => {
                                                            return (
                                                                <>
                                                                    <div className='hidden lg:flex flex-col lg:flex-row gap-2  rounded-t-2xl items-center  justify-between pt-1 px-36 lg:w-[65rem] h-8 dark:shadow-secondaryDefaultDark bg-primaryDefaultLight'>
                                                                        <label className='text-white'>Dia da Semana</label>
                                                                        <label className='text-white'>Horário Inicio</label>
                                                                        <label className='text-white'> Horário Fim </label>
                                                                    </div>
                                                                    <div className='lg:pl-10 mb-10 flex flex-col lg:flex-row rounded-b-2xl gap-2 items-center justify-start dark:bg-primaryDefaultDark lg:w-[65rem] dark:shadow-secondaryDefaultDark lg:bg-white lg:h-24 lg:shadow-xl'>
                                                                        <LabelInput text={'Dia da Semana'}>
                                                                            <InputAutoComplete
                                                                                preSelectedValue={weekday.filter(day => day.id == hours[item].weekday)[0]?.name}
                                                                                selectedLabel={'name'}
                                                                                data={weekday} optionList={['name']}
                                                                                id={`${item} - weekday`}
                                                                                onChange={e => {
                                                                                    hours[item].weekday = e?.id
                                                                                    setHours(hours)
                                                                                    setListHours([...listHours])
                                                                                }} charLimit={100}>
                                                                            </InputAutoComplete>
                                                                        </LabelInput>
                                                                        <LabelInput text={'Horário Inicio'}>
                                                                            <InputAutoComplete
                                                                                preSelectedValue={hoursList.filter(hour => hour.id == hours[item].startHour)[0]?.name}
                                                                                selectedLabel={'name'}
                                                                                data={hoursList} optionList={['name']}
                                                                                id={`${item} - startHour`}
                                                                                onChange={e => {
                                                                                    hours[item].startHour = e?.id
                                                                                    setHours(hours)
                                                                                    setListHours([...listHours])
                                                                                }} charLimit={100}>
                                                                            </InputAutoComplete>
                                                                        </LabelInput>
                                                                        <LabelInput text={'Horário Fim'}>
                                                                            <InputAutoComplete
                                                                                preSelectedValue={hoursList.filter(hour => hour.id == hours[item].finishHour)[0]?.name}
                                                                                selectedLabel={'name'}
                                                                                data={hoursList} optionList={['name']}
                                                                                id={`${item} - finishHour`}
                                                                                onChange={e => {
                                                                                    hours[item].finishHour = e?.id
                                                                                    setHours(hours)
                                                                                    setListHours([...listHours])
                                                                                }} charLimit={100}>
                                                                            </InputAutoComplete>
                                                                        </LabelInput>
                                                                        <div className='flex flex-row gap-3 mt-4 lg:translate-x-11 lg:-translate-y-2'>
                                                                            <Tippy content={<span>Adicionar</span>}
                                                                                arrow={true}
                                                                                animation='perspective'
                                                                                placement='top'
                                                                                delay={100}>
                                                                                <div>
                                                                                    <FiPlus className='text-secondaryDefaultLight dark:secondaryDefaultLight text-3xl cursor-pointer' onClick={() => addHour()} />
                                                                                </div>
                                                                            </Tippy>
                                                                            <Tippy content={<span>Remover</span>}
                                                                                arrow={true}
                                                                                animation='perspective'
                                                                                placement='top'
                                                                                delay={100}>
                                                                                <div>
                                                                                    <FiMinus className='text-secondaryDefaultLight dark:secondaryDefaultLight text-3xl cursor-pointer' approval={true} shadow={true} onClick={() => removeHour(item)} />
                                                                                </div>
                                                                            </Tippy>
                                                                            <Tippy content={<span>Duplicar</span>}
                                                                                arrow={true}
                                                                                animation='perspective'
                                                                                placement='top'
                                                                                delay={100}>
                                                                                <div>
                                                                                    <FiCopy className='text-secondaryDefaultLight dark:secondaryDefaultLight text-2xl cursor-pointer' onClick={(e) => addHour(e)} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>
                                                : (
                                                    <div className='flex flex-col justify-center items-center w-full gap-1 mt-8'>
                                                        <div className='flex flex-col text-white gap-1 items-center  mt-8 left-28'>
                                                            <LabelInput>
                                                                <Title text={'Adicionar Horário'} />
                                                            </LabelInput>
                                                            <FiPlus className='text-secondaryDefaultLight dark:secondaryDefaultLight text-3xl cursor-pointer' onClick={() => addHour()} />
                                                        </div>
                                                    </div>
                                                )
                                        }
                                        <hr className='border-t border-gray-400 w-full py-2'/>
                                        {
                                            !showLogs ?
                                            <div className="flex w-full items-center justify-center pb-52">
                                                <Button onClick={() => setShowLogs(true)}>Mostrar Histórico</Button>
                                            </div>
                                            :
                                            <LogHistory 
                                                apiData={{
                                                    params: `?table_name=partners&table_id=${id}`
                                                }}
                                            />
                                        }
                                    </div>
                                </>
                        }
                    </div>
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button
                            id='cancelUserEdit'
                            approval={false}
                            shadow={true}
                            onClick={() => { navigate('/partners'); setShowModificationModal(false) }}
                        >Voltar
                        </Button>
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <Button
                                id='deleteUserEdit'
                                approval={false}
                                shadow={true}
                                onClick={() => setShowUniversalModal(true)}
                            >Excluir
                            </Button>
                            <Button
                                id='approvalUserEdit'
                                approval={true}
                                shadow={true}
                                onClick={() => handleSubmit()}
                            >Editar parceiro
                            </Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
}