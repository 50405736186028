
// hooks
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { FaTrashAlt } from 'react-icons/fa'
// components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { validateFields } from '../../../utils/form.validator'
import { validateEmail } from '../../../utils/validators/email.validator'
import { Tooltip } from '../../../components/toolTip/toolTip'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
//context
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { getClientEspecific } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { formatSizeCpf } from '../../../utils/validators/formatSizeCpf'
//utils
import { mCPF } from '../../../utils/validators/cpfMask'
import { maskCep } from '../../../utils/validators/cepMask'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { api } from '../../../services/api/api'
import { justNumber } from '../../../utils/validators/justNumber'
import { formatSizeCep } from '../../../utils/validators/formatSizeCep'
import { maskPhone } from '../../../utils/validators/phoneMask'
import { removePontuaction } from '../../../utils/validators/removePontuaction'
import { Footer } from '../../../components/footer/Footer'
export function ClientsEdit() {
    const [nameTitle, setNameTitle] = useState('')
    const { id } = useParams()
    const { userData } = useAuth()
    const navegate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [loading, setLoading] = useState(true)
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(true)
    const [cpf, setCpf] = useState('')
    const [gender, setGender] = useState('')
    const [birthday, setBirthday] = useState('')
    const [dddmobile, setDddmobile] = useState('')
    const [mobile, setMobile] = useState('')
    const [dddphone, setDddphone] = useState('')
    const [phone, setPhone] = useState('')
    const [cep, setCep] = useState('')
    const [newAddressState, setNewAddressState] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressStateList, setAddressStateList] = useState([])
    const [addressCityList, setAddressCityList] = useState([])
    const [addressCity, setAddressCity] = useState('')
    const [addressDistrict, setAddressDistrict] = useState('')
    const [addressRoad, setAddressRoad] = useState('')
    const [addressNumber, setAddressNumber] = useState(0)
    const [addressComplement, setAddressComplement] = useState('')
    const [observation, setObservation] = useState('')
    const [selectedIsRemote, setSelectedIsRemote] = useState(false)
    const [addressState_externalId, setAddressState_externalId] = useState('')
    const [addressCity_externalId, setAddressCity_externalId] = useState('')
    const [microregion_externalId, setMicroregion_externalId] = useState('')
    const [mesoregion_externalId, setMesoregion_externalId] = useState('')

    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        },
    ]

    useEffect(() => {

        async function getStates() {

            setLoading(true)

            try {

                const getState = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
                const getStateData = await getState.json()

                setAddressStateList(getStateData.sort((a, b) => a.sigla > b.sigla ? 1 : -1))

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        async function getClientData() {

            try {

                const getDataClient = await getClientEspecific(userData.token, id)
                setNameTitle(getDataClient.data[0].firstname)

                setFirstname(getDataClient.data[0]?.firstname)
                setLastname(getDataClient.data[0]?.lastname)
                setEmail(getDataClient.data[0]?.email)
                setCpf(formatSizeCpf(getDataClient.data[0]?.cpf))
                setGender(getDataClient.data[0]?.gender)
                setBirthday(getDataClient.data[0]?.birthday ? getDataClient.data[0].birthday.split('T')[0] : '')
                setDddmobile(getDataClient.data[0]?.dddmobile)
                setMobile(maskPhone(getDataClient.data[0]?.mobile))
                setDddphone(getDataClient.data[0]?.dddphone)
                setPhone(maskPhone(getDataClient.data[0]?.phone))
                setCep(getDataClient.data[0]?.cep ? formatSizeCep(getDataClient.data[0]?.cep) : '')
                setNewAddressState(addressStateList.filter((item) => {
                    return item?.sigla == getDataClient.data[0]?.addressState
                })[0])
                setAddressState(getDataClient.data[0]?.addressState)
                setAddressCity(getDataClient.data[0]?.addressCity)
                setAddressDistrict(getDataClient.data[0]?.addressDistrict)
                setAddressRoad(getDataClient.data[0]?.addressRoad)
                setAddressNumber(getDataClient.data[0]?.addressNumber)
                setAddressComplement(getDataClient.data[0]?.addressComplement)
                setObservation(getDataClient.data[0]?.observation)
                setAddressState_externalId(getDataClient.data[0]?.addressState_externalId)
                setAddressCity_externalId(getDataClient.data[0]?.addressCity_externalId)
                setMicroregion_externalId(getDataClient.data[0]?.microregion_externalId)
                setMesoregion_externalId(getDataClient.data[0]?.mesoregion_externalId)
                setSelectedIsRemote(getDataClient.data[0]?.isRemote ? getDataClient.data[0]?.isRemote : false)

                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        getStates()
        getClientData()

    }, [])

    async function getCityApi(data) {

        setLoading(true)

        try {

            const getCity = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data?.id}/municipios`)
            const getCityData = await getCity.json()

            setAddressCityList(getCityData)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleEdit() {

        const requiredFields = [
            { name: 'firstname', value: firstname, required: true, type: 'string' },
            { name: 'cpf', value: cpf, required: true, type: 'string' },
            { name: 'addressState', value: addressState, required: true, type: 'string' },
            { name: 'addressCity', value: addressCity, required: true, type: 'string' },
            { name: 'dddMobile', value: dddmobile, required: true, type: 'string' },
            { name: 'mobile', value: mobile, required: true, type: 'string' },
        ]

        if (!newAddressState) {
            requiredFields.push({ name: 'addressState', value: newAddressState, required: true, type: 'string' })
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            if (!emailValid) {
                // setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('E-mail inválido')
            }

            await api.put(`/api/v1/clients/${id}`, {
                firstname: firstname ? firstname : undefined,
                lastname: lastname ? lastname : lastname == null ? null : undefined,
                email: email ? email : email == null ? null : undefined,
                birthday: birthday ? birthday : birthday == null ? null : undefined,
                cpf: cpf ? parseInt(cpf.replace('-', '').replaceAll('.', '')) : undefined,
                gender: gender ? gender : gender == null ? null : undefined,
                cep: cep ? parseInt(cep.replace('-', '')) : null,
                addressCity: addressCity?.nome ? addressCity?.nome : undefined,
                addressState: newAddressState?.sigla ? newAddressState?.sigla : addressState ? addressState : undefined,
                addressDistrict: addressDistrict ? addressDistrict : null,
                addressRoad: addressRoad ? addressRoad : null,
                addressNumber: addressNumber ? addressNumber : null,
                addressComplement: addressComplement ? addressComplement : null,
                dddmobile: dddmobile ? parseInt(dddmobile) : dddmobile == null ? null : undefined,
                mobile: mobile ? parseInt(removePontuaction(mobile)) : mobile == null ? null : undefined,
                dddphone: dddphone ? parseInt(dddphone) : dddphone == null ? null : undefined,
                phone: phone ? parseInt(removePontuaction(phone)) : phone == null ? null : undefined,
                observation: observation ? observation : observation == null ? null : undefined,
                addressState_externalId: addressState?.id ? addressState?.id.toString() : undefined,
                addressCity_externalId: addressCity?.id ? addressCity?.id.toString() : undefined,
                microregion_externalId: addressCity?.microrregiao?.id ? addressCity?.microrregiao?.id.toString() : undefined,
                mesoregion_externalId: addressCity?.microrregiao?.mesorregiao?.id ? addressCity?.microrregiao?.mesorregiao?.id.toString() : undefined,
                isRemote: selectedIsRemote
            }, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Cliente atualizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navegate('/clients')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setLoading(false)

    }

    async function handleDelete() {
        setLoading(true)

        try {

            await api.delete(`/api/v1/clients/${id}`, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navegate('/clients')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const options = [
        {
            description: 'Sim',
            value: true
        },
        {
            description: 'Não',
            value: false
        },
    ]

    return (
        <>
            <ScreenModal title={'Exlusão de usuário'} width={400} height={200} >
                <div className='w-full p-2 h-96 flex flex-col items-center justify-center'>
                    <p className='text-base text-titleBlackTextLight'>Deseja realmente excluir o cliente {`${firstname}`} ?</p>
                    <div className='flex flex-row gap-1 mt-10'>
                        <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                        <Button shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                    </div>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Editar Cliente - ${nameTitle}`} />
                    </div>
                </TitlePage>
                <Body hasFooter={true}>
                    {loading ?

                        <div className="flex flex-col items-center justify-center h-full">
                            <DefaultLoader />
                        </div>
                        :
                        <div className='flex flex-col items-center lg:items-start justify-start w-full'>
                            <form className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                                <LabelInput text={'Nome *'}>
                                    <Input charLimit={255} id='firstname' type='text' value={firstname} onChange={(e) => setFirstname(e.target.value)}></Input>
                                </LabelInput>
                                <LabelInput text={'Sobrenome'}>
                                    <Input charLimit={255} id='lastname' type='text' value={lastname} onChange={(e) => setLastname(e.target.value)}></Input>
                                </LabelInput>
                                <LabelInput text={'E-mail'} valid={!emailValid ? false : true}>
                                    <Input charLimit={255} id='email' type='e-mail' value={email} onChange={(e) => {
                                        const isvalid = validateEmail(e.target.value)
                                        if (isvalid === true) {
                                            setEmailValid(true)
                                        } else {
                                            setEmailValid(false)
                                        }
                                        setEmail(e.target.value)
                                    }}></Input>
                                </LabelInput>
                                <LabelInput text={'Data de nascimento '}>
                                    <Input id='birthday' value={birthday} type={'date'} onChange={(e) => setBirthday(e.target.value)}></Input>
                                </LabelInput>
                                <LabelInput text={'CPF *'}>
                                    <Input charLimit={14} id='cpf' type='text' value={mCPF(cpf)} onChange={(e) => setCpf(e.target.value)}></Input>
                                </LabelInput>
                                <LabelInput text={'Gênero'}>
                                    <InputAutoComplete
                                        data={sexTypes}
                                        selectedLabel={'name'}
                                        optionList={['id', 'name']}
                                        placeHolder=' - '
                                        onChange={e => setGender(e.id)}
                                        preSelectedValue={gender ? sexTypes.filter(sex => sex.id == gender)[0]?.name : ''}
                                        id='user_gender_select'
                                        width={'318px'}
                                    />
                                </LabelInput>
                                <LabelInput text={'CEP'}>
                                    <Input type={'text'} value={maskCep(cep)} id='cep' onChange={(e) => setCep(e.target.value)} charLimit={9} />
                                </LabelInput>
                                <LabelInput text={'Estado *'}>
                                    <InputAutoComplete
                                        preSelectedValue={newAddressState ? newAddressState?.nome : addressStateList.filter((item) => {
                                            return item?.sigla == addressState
                                        })[0]?.nome}
                                        selectedLabel={'nome'} id='addressState'
                                        data={addressStateList} optionList={['nome']}
                                        onChange={e => {
                                            {
                                                setAddressCity('')
                                                setNewAddressState(e)
                                                e?.nome !== undefined && getCityApi(e)
                                            }
                                        }}>
                                    </InputAutoComplete>
                                </LabelInput>
                                {
                                    !newAddressState?.nome ?
                                        <LabelInput text={'Cidade *'}>
                                            <Input type={'text'} value={addressCity} id='addressCity' disabled={true} />
                                        </LabelInput>
                                        :
                                        <LabelInput text={'Cidade *'}>
                                            <InputAutoComplete
                                                preSelectedValue={addressCity ? addressCity?.nome : addressCityList?.nome}
                                                selectedLabel={'nome'} id='addressCity'
                                                data={addressCityList} optionList={['nome']}
                                                onChange={e => {
                                                    setAddressCity(e)
                                                }}>
                                            </InputAutoComplete>
                                        </LabelInput>
                                }
                                <LabelInput text={'Bairro'}>
                                    <Input type={'text'} value={addressDistrict} onChange={e => setAddressDistrict(e.target.value)} />
                                </LabelInput>
                                <LabelInput text={'Logradouro'}>
                                    <Input type={'text'} value={addressRoad} onChange={e => setAddressRoad(e.target.value)} />
                                </LabelInput>
                                <LabelInput text={'Número'}>
                                    <Input type={'text'} id='addressNumber' value={addressNumber} onChange={(e) => setAddressNumber(justNumber(e.target.value))} charLimit={8} />
                                </LabelInput>
                                <LabelInput text={'Complemento'}>
                                    <Input type={'text'} id='addressComplement' value={addressComplement} onChange={(e) => setAddressComplement(e.target.value)} charLimit={100} />
                                </LabelInput>
                                <div className='flex flex-row gap-2'>
                                    <LabelInput text={'DDD *'}>
                                        <Input id={'dddMobile'} charLimit={2} width={'56px'} type={'text'} value={dddmobile} onChange={(e) => setDddmobile(justNumber(e.target.value))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Celular *'}>
                                        <Input id={'mobile'} charLimit={9} width={'255px'} type={'text'} value={mobile} onChange={(e) => setMobile(maskPhone(e.target.value))}></Input>
                                    </LabelInput>
                                </div>
                                <div className='flex flex-row gap-2'>
                                    <LabelInput text={'DDD'}>
                                        <Input width={'56px'} type={'text'} id='dddphone' value={dddphone} onChange={(e) => setDddphone(justNumber(e.target.value))} charLimit={2} />
                                    </LabelInput>
                                    <LabelInput text={'Telefone'}>
                                        <Input type={'text'} width={'255px'} value={phone} id='phone' onChange={(e) => setPhone(maskPhone(e.target.value))} charLimit={9} />
                                    </LabelInput>
                                </div>
                                <div>
                                    <LabelInput text={'Observação'}>
                                        <textarea className='outline-none border border-gray-400 border-opacity-75 rounded-lg w-80 h-36 lg:h-32 resize-none
                                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark
                                                    focus:border focus:border-primaryDefaultLight
                                                    dark:focus:border dark:focus:border-primaryDefaultLight p-2' value={observation} maxLength={250} id='observation' onChange={e => setObservation(e.target.value)} />
                                    </LabelInput>
                                </div>
                                <LabelInput text={'Atendimento online *'}>
                                    <InputAutoComplete
                                        preSelectedValue={options?.find(item => item?.value == selectedIsRemote).description}
                                        value={selectedIsRemote ? options.find(item => item?.value == selectedIsRemote).description : ''}
                                        selectedLabel={'description'} 
                                        id={'selectedIsRemote'}
                                        data={options}
                                        optionList={['description']}
                                        onChange={e => {
                                            if (typeof(e) == 'object'){
                                                setSelectedIsRemote(e?.value)
                                            }
                                        }}>
                                    </InputAutoComplete>
                                </LabelInput>
                            </form>
                        </div>
                    }
                </Body >
                <Footer>
                    <div className='flex flex-col-reverse lg:flex-row gap-3 w-full items-center h-full justify-between'>
                        <Button
                            approval={false}
                            shadow={true}
                            onClick={() => { navegate('/clients'); setShowModificationModal(false) }}
                        >Cancelar
                        </Button>
                        <div className='flex flex-row gap-3 items-center justify-center'>
                            {
                                ![1].includes(userData.typeAccess_id) ?
                                    <></>
                                    :
                                    <Button
                                        id='deleteUserEdit'
                                        approval={false}
                                        shadow={true}
                                        onClick={() => setShowUniversalModal(true)}
                                    >Excluir
                                    </Button>
                            }
                            {
                                userData.typeAccess_id < 5 &&
                                <Button
                                    approval={true}
                                    shadow={true}
                                    onClick={(e) => handleEdit()}
                                >Editar
                                </Button>
                            }
                        </div>
                    </div>
                </Footer>
            </Container >
        </>
    )
}
