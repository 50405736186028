import {
    FaChartPie, FaCog, FaCalendarAlt, FaUserTie, FaUsers, FaUserTag, FaLink, FaFonticonsFi, FaBell, FaUsersCog, FaTable, FaRegListAlt
} from 'react-icons/fa'
import {
    HiOutlineDocumentReport
} from 'react-icons/hi'

export const menuItems = [

    {
        "icon": <FaChartPie />,
        "name": "Dashboard",
        "type": "normal",
        "path": "/",
    },
    {
        "icon": <FaBell />,
        "name": "Notificações",
        "type": "normal",
        "path": "/notify",
    },
    {
        "icon": <FaRegListAlt />,
        "name": "Agendar",
        "type": "normal",
        "path": "/operatorSchedule",
    },
    {
        "icon": <FaCalendarAlt />,
        "name": "Calendário",
        "type": "normal",
        "path": "/operatorScheduleCalendar",
    },
  
    {
        "icon": <FaUserTag />,
        "name": "Clientes",
        "type": "list",
        "path": "/clients",

    },
    {
        "icon": <HiOutlineDocumentReport />,
        "name": "Relatório",
        "type": "normal",
        "path": "/reports",

    },
    {
        "icon": <FaCog />,
        "name": "Gerenciar",
        "type": "list",
        "path": "/configuration",
        "list": [
            {
                "icon": <FaCog />,
                "name": "Consultar",
                "type": "normal",
                "path": "/configuration/consultar",
            },
            {
                "icon": <FaCog />,
                "name": "Cadastrar",
                "type": "normal",
                "path": "/configuration/consultar",
            }

        ]
    },
   
]