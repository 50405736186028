// hooks 
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { TablePaginationDefault } from '../../../components/table/tablePagination.default'
// components
import { Title } from '../../../components/titlePages/title'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { FiSlash } from 'react-icons/fi'
import { FaEdit, FaFilter } from 'react-icons/fa'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Input } from '../../../components/input/input'

export function TagsTable() {

    const navigate = useNavigate()
    const { setShowUniversalModal } = useThemeContext()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const { userData } = useAuth()
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState({ active: true  })
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(0)

    function handleEdit(data) {
        navigate(`/tagsEdit/${data.id}`)
    }

    return (
        <>
            <ScreenModal title={'Filtros'}>
            {
                loading ? 
                <DefaultLoader />
                : 
                <div className='flex flex-col pt-2 pb-10 px-12 gap-4 items-center'>
                    <label className='flex flex-col gap-1 items-start justify-center'>
                        <a className='text-sm text-inputPlaceholderLight'>Descrição *</a>
                        <Input
                            charLimit={255} id='description' type='text'
                            onChange={(e) => setFilterConstructor({ ...filterConstructor, description: e.target.value })}
                            value={filterConstructor?.description} autoFocus={true}
                        ></Input>
                    </label>
                    <label className='flex flex-col gap-1 items-start justify-center'>
                        <a className='text-sm text-inputPlaceholderLight'>Status *</a>
                        <InputAutoComplete
                            data={[{ id: false, description: 'Inativo' }, { id: true, description: 'Ativo' }]}
                            selectedLabel={'description'}
                            optionList={['description']}
                            onChange={e => setFilterConstructor({ ...filterConstructor, active: e.id })}
                            preSelectedValue={[{ id: false, description: 'Inativo' }, { id: true, description: 'Ativo' }]?.filter(filter => filter == filterConstructor.active)[0]?.description}
                            id='active'
                            width={'318px'}
                        />
                    </label>
                    <div className='mt-5'>
                        <Button onClick={() => {
                            setFilter(filterConstructor)
                            setShowUniversalModal(false)
                        }}>Filtrar</Button>
                    </div>
                </div>
            }
            </ScreenModal>
            <Container>
                <TitlePage displayNotification={showNotification} success={successData} setDisplayNotification={e => setShowNotication(e)}>
                    <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Teses'}/>
                            <div className='flex flex-row gap-2 justify-between items-center'>
                                <h3 className='text-base font-light dark:text-titleGrayTextDark'>{quantity} Teses</h3>
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/tagsCreate')}>
                            <Button shadow={true} onClick={() => {
                            }}>+ Nova tese</Button>
                        </div>
                    </div>
                </TitlePage>
                <Body>                    
                    <div className='w-full sm:mt-0 mt-2 flex items-center gap-4'>
                        <Button onClick={() => setShowUniversalModal(true)}>
                            <FaFilter className='text-white' /> <p className='text-white pl-2'> Filtrar </p>
                        </Button>
                        {filter && JSON.stringify(filter) != JSON.stringify({ active: true })
                            ? <Tippy
                                key={`clearFilter`}
                                content={<span>Limpar filtro</span>}
                                arrow={true}
                                animation='perspective'
                                placement='right'
                                delay={100}>
                                <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                    <FiSlash className='cursor-pointer' onClick={async () => {
                                        setLoading(true)
                                        setFilter({ active: true })
                                        setFilterConstructor({})
                                        await new Promise(resolve => setTimeout(resolve, 500))
                                        setLoading(false)
                                    }} />
                                </div>
                            </Tippy>
                            : <></>
                        }
                    </div>
                    <TablePaginationDefault
                        onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                        quantity={(e) => setQuantity(e)}
                        apiUrl={'/api/v1/tags'}
                        filters={filter}
                        mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                        collumns={[
                            {
                                name: 'id',
                                type: 'number',
                                description: 'Código'
                            },
                            {
                                name: 'description',
                                type: 'string',
                                description: 'Descrição',
                                func: (item) => {
                                    if (item?.description?.length > 75){
                                        return `${item?.description?.slice(0,75)}...`
                                    } else {
                                        return item?.description
                                    }
                                }
                            },
                            {
                                name: 'active',
                                type: 'string',
                                description: 'Status',
                                func: (item) => {
                                    if (item?.active){
                                        return 'Ativo'
                                    } else {
                                        return 'Inativo'
                                    }
                                }
                            },
                            {
                                name: 'created_at',
                                type: 'date',
                                description: 'Criado em'
                            }
                        ]}
                    />                              
                </Body>
            </Container>
        </>
    )

}