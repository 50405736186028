import React, { createContext, useContext, useState, useEffect } from "react";

const ThemeContext = createContext()

export function ThemeContextProvider({ children }) {
    //user
    const [bgPrimaryLight, setBgPrimaryLight] = useState('#fcfdff')
    const [bgSecondaryLight, setBgSecondaryLight] = useState('#edf1fa')

    const [primaryDefaultLight, setPrimaryDefaultLight] = useState('#0B2E58')
    const [primaryDefaultDarkColor, setPrimaryDefaultDarkColor] = useState('#4c75f5')
    const [secondaryDefaultLight, setSecondaryDefaultLight] = useState('#175699')
    const [hoverLight, setHoverLight] = useState('#d8e4e8')
    const [titleBlackTextLight, setTitleBlackTextLight] = useState('#0f1214')
    const [titleGrayTextLight, setTitleGrayTextLight] = useState('#FFFF')
    const [inputPlaceholderLight, setInputPlaceholderLight] = useState('#8F8F8F')
    const [primaryLightSuccess, setPrimaryLightSuccess] = useState('#64B694')
    const [primaryDefaultDark, setPrimaryDefaultDark] = useState('#1C2128')
    const [secondaryDefaultDark, setSecondaryDefaultDark] = useState('#22272E')
    const [thirdDefaultDark, setThirdDefaultDark] = useState('#20242b')
    const [hoverDark, setHoverDark] = useState('#2a2f36')
    const [titleBlackTextDark, setTitleBlackTextDark] = useState('#2a2f36')
    const [titleGrayTextDark, setTitleGrayTextDark] = useState('#C4C4C4')
    const [primaryBorderDark, setPrimaryBorderDark] = useState('#363E48')
    const [secondaryBorderDark, setSecondaryBorderDark] = useState('#444c56')
    const [darkMode, setDarkMode] = useState(false)

    const [showUniversalModal, setShowUniversalModal] = useState(false)
    const [showNotificationModal, setShowModificationModal] = useState(false)
    const [showNotificationModalText, setShowNotificationModalText] = useState('')
    const [showNotificationModalSuccess, setShowNotificationModalSuccess] = useState(false)


    useEffect(() => {

        if (localStorage.getItem('darkMode') === 'true') {
            document.documentElement.classList.add('dark')
            setDarkMode(true)
        } else {
            document.documentElement.classList.remove('dark')
        }

        const typeUser = localStorage.getItem('typeUser')

        if (typeUser != '1') {
            //white colors
            if (!localStorage.getItem('primaryDefaultLight')) { document.documentElement.style.setProperty('--primaryDefaultLight', primaryDefaultLight) }
            else {
                document.documentElement.style.setProperty('--primaryDefaultLight', localStorage.getItem('primaryDefaultLight'))
                setPrimaryDefaultLight(localStorage.getItem('primaryDefaultLight'))

            }

            !localStorage.getItem('secondaryDefaultLight') ? document.documentElement.style.setProperty('--secondaryDefaultLight', secondaryDefaultLight) :
                document.documentElement.style.setProperty('--secondaryDefaultLight', localStorage.getItem('secondaryDefaultLight'))


            !localStorage.getItem('primaryDefaultDarkColor') ? document.documentElement.style.setProperty('--primaryDefaultDarkColor', primaryDefaultDarkColor) :
                document.documentElement.style.setProperty('--primaryDefaultDarkColor', localStorage.getItem('primaryDefaultDarkColor'))


            //dark colors user    
            !localStorage.getItem('primaryDefaultDark') ? document.documentElement.style.setProperty('--primaryDefaultDark', primaryDefaultDark) :
                document.documentElement.style.setProperty('--primaryDefaultDark', localStorage.getItem('primaryDefaultDark'))

            !localStorage.getItem('secondaryDefaultDark') ? document.documentElement.style.setProperty('--secondaryDefaultDark', secondaryDefaultDark) :
                document.documentElement.style.setProperty('--secondaryDefaultDark', localStorage.getItem('secondaryDefaultDark'))

            !localStorage.getItem('thirdDefaultDark') ? document.documentElement.style.setProperty('--thirdDefaultDark', thirdDefaultDark) :
                document.documentElement.style.setProperty('--thirdDefaultDark', localStorage.getItem('thirdDefaultDark'))

        } else {
            //cores padroes cliente
            document.documentElement.style.setProperty('--primaryDefaultLight', "#2041a8")
            document.documentElement.style.setProperty('--secondaryDefaultLight', "#0d2059")
            !localStorage.getItem('primaryDefaultDarkColor') ? document.documentElement.style.setProperty('--primaryDefaultDarkColor', primaryDefaultDarkColor) :
            document.documentElement.style.setProperty('--primaryDefaultDarkColor', localStorage.getItem('primaryDefaultDarkColor'))

            //dark colors clients
            document.documentElement.style.setProperty('--primaryDefaultDark', primaryDefaultDark)
            document.documentElement.style.setProperty('--secondaryDefaultDark', secondaryDefaultDark)
            document.documentElement.style.setProperty('--thirdDefaultDark', thirdDefaultDark)
        }

        if (!localStorage.getItem('bgPrimaryLight')) { document.documentElement.style.setProperty('--bgPrimaryLight', bgPrimaryLight) }
        else {
            document.documentElement.style.setProperty('--bgPrimaryLight', localStorage.getItem('bgPrimaryLight'))
            setBgPrimaryLight(localStorage.getItem('bgPrimaryLight'))
        }

        !localStorage.getItem('bgSecondaryLight') ? document.documentElement.style.setProperty('--bgSecondaryLight', bgSecondaryLight) :
            document.documentElement.style.setProperty('--bgSecondaryLight', localStorage.getItem('bgSecondaryLight'))

        !localStorage.getItem('hoverLight') ? document.documentElement.style.setProperty('--hoverLight', hoverLight) :
            document.documentElement.style.setProperty('--hoverLight', localStorage.getItem('hoverLight'))

        !localStorage.getItem('titleBlackTextLight') ? document.documentElement.style.setProperty('--titleBlackTextLight', titleBlackTextLight) :
            document.documentElement.style.setProperty('--titleBlackTextLight', localStorage.getItem('titleBlackTextLight'))

        !localStorage.getItem('titleGrayTextLight') ? document.documentElement.style.setProperty('--titleGrayTextLight', titleGrayTextLight) :
            document.documentElement.style.setProperty('--titleGrayTextLight', localStorage.getItem('titleGrayTextLight'))

        !localStorage.getItem('inputPlaceholderLight') ? document.documentElement.style.setProperty('--inputPlaceholderLight', inputPlaceholderLight) :
            document.documentElement.style.setProperty('--inputPlaceholderLight', localStorage.getItem('inputPlaceholderLight'))

        !localStorage.getItem('primaryLightSuccess') ? document.documentElement.style.setProperty('--primaryLightSuccess', primaryLightSuccess) :
            document.documentElement.style.setProperty('--primaryLightSuccess', localStorage.getItem('primaryLightSuccess'))

        //darkColors
        !localStorage.getItem('hoverDark') ? document.documentElement.style.setProperty('--hoverDark', hoverDark) :
            document.documentElement.style.setProperty('--hoverDark', localStorage.getItem('hoverDark'))

        !localStorage.getItem('titleBlackTextDark') ? document.documentElement.style.setProperty('--titleBlackTextDark', titleBlackTextDark) :
            document.documentElement.style.setProperty('--titleBlackTextDark', localStorage.getItem('titleBlackTextDark'))

        !localStorage.getItem('titleGrayTextDark') ? document.documentElement.style.setProperty('--titleGrayTextDark', titleGrayTextDark) :
            document.documentElement.style.setProperty('--titleGrayTextDark', localStorage.getItem('titleGrayTextDark'))

        !localStorage.getItem('primaryBorderDark') ? document.documentElement.style.setProperty('--primaryBorderDark', primaryBorderDark) :
            document.documentElement.style.setProperty('--primaryBorderDark', localStorage.getItem('primaryBorderDark'))

        !localStorage.getItem('secondaryBorderDark') ? document.documentElement.style.setProperty('--secondaryBorderDark', secondaryBorderDark) :
            document.documentElement.style.setProperty('--secondaryBorderDark', localStorage.getItem('secondaryBorderDark'))


    }, [])

    function changeTheme(darkMode) {
        darkMode ? localStorage.setItem('darkMode', true) : localStorage.setItem('darkMode', '')
        darkMode ? document.documentElement.classList.add('dark') : document.documentElement.classList.remove('dark')
        setDarkMode(darkMode)
    }

    return <ThemeContext.Provider value={{

        bgPrimaryLight, setBgPrimaryLight,
        bgSecondaryLight, setBgSecondaryLight,
        primaryDefaultLight, setPrimaryDefaultLight,
        secondaryDefaultLight, setSecondaryDefaultLight,
        hoverLight, setHoverLight,
        titleBlackTextLight, setTitleBlackTextLight,
        titleGrayTextLight, setTitleGrayTextLight,
        inputPlaceholderLight, setInputPlaceholderLight,
        primaryLightSuccess, setPrimaryLightSuccess,
        primaryDefaultDark, setPrimaryDefaultDark,
        secondaryDefaultDark, setSecondaryDefaultDark,
        thirdDefaultDark, setThirdDefaultDark,
        hoverDark, setHoverDark,
        titleBlackTextDark, setTitleBlackTextDark,
        primaryBorderDark, setPrimaryBorderDark,
        secondaryBorderDark, setSecondaryBorderDark,
        darkMode,
        changeTheme,
        showUniversalModal, setShowUniversalModal,
        showNotificationModal, setShowModificationModal,
        showNotificationModalText, setShowNotificationModalText,
        showNotificationModalSuccess, setShowNotificationModalSuccess
    }}>

        {children}
    </ThemeContext.Provider>
}

export function useThemeContext() {

    return useContext(ThemeContext)
}