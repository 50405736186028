 
export function textMask(text) {
    const alphabet = ["a","A","b","B","c","C","d","D","e","E","f","F","g","G","h","H","i","I","j","J","k","K","l","L","m","M","n","N","o","O","p","P","q","Q","r","R","s","S","t","T","u","U","v","V","w","W","x","X","y","Y","z","Z"];
      if (alphabet.includes(text.substring(0,1)) ){
        return text
    } else {
        return ''
    }

}
