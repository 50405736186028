import React, { useEffect } from 'react'

export function TableMobile({title, data, collumns, functions, onClick, notTHead}) {

    return (
        <div className='flex flex-col w-full gap-2 h-full'>
            {
                data?.map((apiData, index) => {
                    return <div key={index} className='flex flex-col h-full pb-4'>
                        <div className='flex flex-col w-full boxShadow mt-2'>
                            <div className='flex flex-col w-full'>
                                {
                                    title?.map((titleColumn, indexMobile) => {

                                        return <div key={`${index}-${indexMobile}`} className='flex flex-row w-full '>
                                            <div className='h-12 w-20 overflow-x-auto  flex flex-col  bg-primaryDefaultLight dark:bg-thirdDefaultDark text-bgPrimaryLight font-extrabold justify-center items-start pl-2'>
                                                <a className='text-sm font-extrabold text-bgPrimaryLight dark:text-titleGrayTextLight'>{titleColumn?.name}</a>
                                            </div>
                                            <div className='h-12 overflow-x-auto  flex flex-col justify-center items-start'>
                                                <a className='text-sm  text-titleBlackTextLight dark:text-titleGrayTextDark pl-2'>{apiData[titleColumn?.original_name]}</a>
                                            </div>

                                        </div>
                                    })
                                }
                            </div>
                            {/* <div className='w-full bg-primaryDefaultLight dark:bg-thirdDefaultDark h-12 flex flex-row items-center justify-start pl-2 gap-10 border-t border-primebg-primaryDefaultLight'>
                                <div>
                                    <a className='text-sm text-bgPrimaryLight font-extrabold'>Opções</a>
                                </div>
                                {functions.map((func, indexFuncIcon) => {
                                    return <div key={`${index}-${indexFuncIcon}-`} onClick={()=> onClick({data: apiData, func: func})} className='flex flex-col items-center justify-center cursor-pointer h-full w-12' title={func.title}>
                                        <div className='text-2xl text-bgPrimaryLight'>{func.icon}</div>                                        
                                    </div>
                                })}
                            </div> */}
                        </div>
                    </div>
                })
            }
        </div>
    )
}

