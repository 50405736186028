// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
//css
import styles from './login.module.css'
// img
import logoNobre from '../../assets/logos/grupoNobreTransparent.png'
import logoSoftWhite from '../../assets/logos/logoSoftWhite.png'
import logoMailou from '../../assets/logos/Mailou Dev branca.png'
import logoSoft from '../../assets/logos/logoSoft.png'
// contexts
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { useAuth } from '../../contexts/useAuth'
import { useThemeContext } from '../../contexts/themeContext'
//components
import { LoginLoader } from '../../components/loaders/loginLoader'
import { LabelInput } from '../../components/label/label.input'
import { Input } from '../../components/input/input'
import { NotificationModal } from '../../components/modals/notification/notification.modal'
//services
import { api } from '../../services/api/api'
import { Button } from '../../components/buttons/button.default'
import { FaArrowAltCircleLeft, FaQuestionCircle, FaUser } from 'react-icons/fa'
import { getPermissionGroupEspecific } from '../../services/api/callAPIsFunctions/defaultCalls.api'
//utils
import { responseError } from '../../utils/responsesFunctions/error.response'

export function Login(props) {

    const { screenX, screenY } = useScreenSizeContext()
    const { setShowModificationModal, setShowNotificationModalText, setShowNotificationModalSuccess } = useThemeContext()
    const { setSigned, userData, setUserData } = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [animateHelp, setAnimateHelp] = useState(false)
    const [loginAnimationEnd, setLoginAnimationEnd] = useState(false)
    const [forgetPassword, setForgetPassword] = useState(false)
    const [recoverPassword, setRecoverPassword] = useState(false)
    const [email, setEmail] = useState('')

    const [selectedUserName, setSelectedUserName] = useState('')
    const [selectedUserPassword, setSelectedUserPassword] = useState('')

    async function getPic(user) {

        try {
            const response = await api.get(`/api/v1/users/photo/filename/${user.photo}`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            })
            return URL.createObjectURL(response.data)

        } catch (error) {
            console.log(error)
        }
    }

    async function handleLogin() {

        setLoading(true)

        try {
            const userData = await api.post('auth/logon', {
                "email": selectedUserName,
                "password": selectedUserPassword
            })

            if (userData.data.status === 201) {
                setShowModificationModal(false)
                let newUserData = userData.data.userData
                newUserData.token = userData.data.token

                newUserData.photoFile = await getPic(newUserData)
                setUserData(newUserData)
                setSigned(true)
                localStorage.setItem('@auth:token', userData.data.token)
                navigate('/')
            }

        } catch (error) {
            if (error?.response?.data?.message){
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalText(error?.response?.data?.message)
                setShowNotificationModalSuccess(false)
            } else {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalText('Erro ao fazer login, verifique os dados.')
                setShowNotificationModalSuccess(false)
            }
        }
    }

    const handlePassword = async () => {
        try {

            await api.post(`/auth/redefinePassword`, {
                email
            })
            setEmail('')
            setRecoverPassword(true)
            setForgetPassword(false)

        } catch (error) {
            setErrorMessage('Ocorreu um erro ao alterar a senha, se o problema persistir entre em contato com o suporte')
            setShowErrorModal(true)
            console.log(error)
        }
    }

    useEffect(() => {
        setShowModificationModal(false)
        async function changeHelpDisplay() {
            await new Promise(resolve => setTimeout(resolve, 100))
            showHelp ? setAnimateHelp(true) : setAnimateHelp(false)

        }

        changeHelpDisplay()
    }, [showHelp])

    return (
        <div id={styles.loginPage} className={`w-full ${screenY < 600 ? 'pt-6 h-full' : 'h-[100vh]'} flex flex-col  items-center justify-center`}>
            {loading ?
                <div className='h-full w-full flex flex-col items-center justify-center'>
                    <LoginLoader />
                </div>
                :
                <>
                    <NotificationModal isAuth={false} />
                    <div className='z-30 absolute border-t border-zinc-600 bottom-0 flex flex-row gap-2 items-center justify-center w-full py-1 rounded-t-3xl bg-transparent' id='logoImage'>
                        <p className='text-gray-50 dark:text-titleBlackTextLight text-xs font-base'>Desenvolvido por</p>
                        <a className='flex dark:hidden' href='https://www.softjoin.com.br' target='__blank'>
                            <img width={'80px'} src={logoMailou}></img>
                        </a>
                        <a className='dark:flex hidden' href='https://www.softjoin.com.br' target='__blank'>
                            <img width={'80px'} src={logoSoftWhite}></img>
                        </a>

                    </div>
                    {
                        showError ?
                        <div className='bg-transparent flex flex-col items-center justify-center'>
                            <p className='text-lg text-titleGrayTextLight'>Erro ao entrar</p>
                            <p className='text-lg text-titleGrayTextLight'>Verifique os dados informados</p>
                            <div className='mt-10' onClick={() => setShowError(false)}>
                                <Button shadow={true}>Voltar</Button>
                            </div>
                        </div>
                        :
                        <>
                            <div className='flex flex-col'>
                                <div className={`${showHelp ? 'flex' : 'hidden'} w-[100%] h-[100%] ${animateHelp ? '' : 'bg-transparent'} transition-all duration-100 z-50 absolute inset-0 boxShadow flex flex-col items-center justify-center`}>
                                    <div className={`${animateHelp ? 'translate-y-0 opacity-1 bg-bgPrimaryLight' : 'translate-y-10 opacity-0 bg-transparent'} transition-all duration-300  w-96 h-80 shadow-xl shadow-zinc-700 flex flex-col items-center justify-start p-4 rounded-xl absolute z-30`}>
                                        <div className='w-full flex flex-row justify-between h-6'>
                                            <h2 className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'>Ajuda</h2>
                                            <button onClick={() => setShowHelp(false)} className='bg-gradient-to-t from-primaryDefaultLight to-secondaryDefaultLight text-xl font-bold bg-clip-text text-transparent'>x</button>
                                        </div>
                                        <div className='h-[100%] flex flex-col items-center justify-center'>
                                            <h2 className='text-titleBlackTextLight font-base'>Precisa de ajuda?</h2>
                                            <p className='text-titleBlackTextLight mb-10'>Entre em contato com 47 3026-6006</p>
                                            {/* <button className='bg-gradient-to-r from-primaryDefaultLight to-secondaryDefaultLight hover:shadow-lg hover:shadow-zinc-400/60 hover:brightness-90 transition-all duration-200 ease-in-out w-40 h-12 text-titleGrayTextLight mt-5 rounded-3xl '>Chamar Whatsapp</button> */}

                                        </div>
                                    </div>
                                </div>
                                <div className={`${showHelp ? 'flex' : 'hidden'} w-[100%] h-[100%] ${animateHelp ? 'bg-zinc-700' : 'bg-transparent'} transition-all duration-100 opacity-75 z-40 absolute inset-0 boxShadow flex flex-col items-center justify-center`}>
                                </div>
                            </div>

                            <div onClick={() => setShowHelp(!showHelp)} className='z-30 absolute bottom-0 right-4 w-16 h-16 rounded-full text-2xl text-titleGrayTextLight cursor-pointer hover:brightness-50' title='Ajuda'>
                                <FaQuestionCircle />
                            </div>

                            <div className='z-20 absolute animate-[wiggle_1s_ease-in-out] backdrop-blur-sm bg-transparent  w-full h-full md:w-[350px] md:h-[480px] 2xl:w-[500px] 2xl:h-[600px] border border-zinc-800 flex flex-row items-center justify-center rounded-3xl'>
                                <div className='flex flex-col gap-2 items-center w-full'>
                                    <form className='w-full h-full flex flex-col items-center gap-2' onSubmit={e => e.preventDefault()}>
                                        {
                                            forgetPassword ?
                                                <div className='flex flex-col items-center justify-center gap-4 w-full flex-wrap'>
                                                    <p className='mb-4 text-2xl text-gray-50 text-titleBlackTextLight'><strong>Esqueceu</strong> sua senha?</p>
                                                    <p className='mb-8 text-titleBlackTextLight text-gray-50 text-center'>Enviaremos um e-mail com instruções para redefinir sua senha</p>
                                                    <div onClick={() => setForgetPassword(false)} className='cursor-pointer hover:brightness-90 absolute top-6 left-0 sm:left-8 text-2xl text-gray-50 flex flex-col w-full items-start justify-start'>
                                                        <FaArrowAltCircleLeft />
                                                    </div>

                                                    <LabelInput text={'E-mail *'}>
                                                        <Input width={screenX < 1400 ? 80 : ''} id='email' value={email} type='e-mail' onChange={(e) => setEmail(e.target.value)}></Input>
                                                    </LabelInput>
                                                    <button onClick={handlePassword} className={`w-32  h-8 flex fle-row gap-1 items-center justify-center text-sm bg-blue-900 dark:bg-secondaryDefaultLight hover:brightness-125 hover:dark:bg-primaryDefaultLight' rounded-3xl  transition-all duration-300 text-titleGrayTextLight shadow-md shadow-gray-900 dark:shadow-md dark:shadow-secondaryDefaultDark`}>Redefinir</button>

                                                    {/* <Button onClick={handlePassword} approval={true}>Redefinir</Button> */}
                                                </div>
                                                :
                                                recoverPassword ?
                                                    <div className='animate-wiggle flex flex-col items-center justify-center w-full'>
                                                        <p className='mb-4 text-2xl text-titleBlackTextLight text-center text-gray-50'><strong>Senha</strong> redefinida com sucesso</p>
                                                        <p className='mb-10 text-titleBlackTextLight text-center text-gray-50'>Acesse seu e-mail para verificar a nova senha</p>
                                                        <Button onClick={() => { setForgetPassword(false); setRecoverPassword(false) }}>Login</Button>
                                                    </div>
                                                    :
                                                    <>
                                                        <div className='2xl:mb-10 flex p-12 flex-col items-center justify-center w-full h-48 2xl:h-56    '>
                                                            <img src={logoNobre} width={screenX < 600 || screenY < 800 ? 300 : ''} />
                                                        </div>
                                                        <LabelInput text={'Usuário'}>
                                                            <input value={selectedUserName} onChange={(e) => setSelectedUserName(e.target.value)} className='bg-transparent outline-none w-64 border-b text-titleGrayTextLight h-10 pl-2 border-zinc-400'></input>
                                                        </LabelInput>
                                                        <LabelInput text={'Senha'}>
                                                            <input value={selectedUserPassword} onChange={(e) => setSelectedUserPassword(e.target.value)} type={'password'} className='bg-transparent outline-none w-64 border-b text-titleGrayTextLight h-10 pl-2 border-zinc-400'></input>
                                                        </LabelInput>
                                                        <button type='submit' id='loginButton' className='mt-4 2xl:mt-10 bg-blue-900 hover:bg-secondaryDefaultLight outline-none focus:bg-secondaryDefaultLight transition-all duration-300 w-64 h-12 rounded-full text-white text-base' onClick={() => handleLogin()}>Acessar</button>
                                                        <p className='mt-1 text-gray-50 text-sm text-primaryDefaultLight underline cursor-pointer' onClick={() => setForgetPassword(true)}>Esqueci minha senha</p>
                                                    </>
                                        }
                                    </form>
                                </div>
                            </div>
                            <div className='z-10 animate-[wiggle_1s_ease-in-out] bg-gradient-to-t from-primaryDefaultLight to-zinc-100 opacity-10  w-full h-full md:w-[350px] md:h-[480px] 2xl:w-[500px] 2xl:h-[600px] flex flex-row items-center justify-center rounded-3xl'></div>
                        </>
                    }
                </>
            }

        </div>
    )
}