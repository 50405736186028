import React, { useContext, createContext, useState } from 'react'

const ScheduleContext = createContext()

export function ScheduleContextProvider({ children }) {


    const [selectedMode, setSelectedMode] = useState(1)

    return (
        <ScheduleContext.Provider value={{
            selectedMode,
            setSelectedMode
        }}>
            {children}
        </ScheduleContext.Provider>
    )
}

export function contextSchedule() {
    return useContext(ScheduleContext)
}