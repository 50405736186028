// hooks 
import React from 'react'
import { useNavigate } from 'react-router-dom' 
// components 
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Container } from '../../../components/container/container'
import { Body } from '../../../components/container/Body'
import { TableDefault } from '../../../components/table/table.default'
import { Button } from '../../../components/buttons/button.default'
import { Navigate } from 'react-router-dom'

export function ReservationCategory() {

    const navigate = useNavigate()

    const tableThead = [
        {
            "name": 'Categoria',
            "original_name": 'categoryName'
        },
        {
            "name": 'Abertura de reservas',
            "original_name": 'reserve_open_in'
        },
        {
            "name": 'Tipo de reserva',
            "original_name": "reservation_type"
        },
        
    ]
    const apiData = [

        {
            "category_name": 'Quadras',
            "reserve_open_in": "7 dias",
            "reservation_type": "Mensal",            
        },

        {
            "category_name": 'Churrasqueira',
            "reserve_open_in": "6 dias",
            "reservation_type": "Mensal",            
        },
        {
            "category_name": 'Restaurante',
            "reserve_open_in": "7 dias",
            "reservation_type": "Mensal",            

        },

      
    ]


    return (
        <Container>
            <TitlePage>
                <div className='flex flex-row gap-1 items-start justify-between w-full'>
                <Title text={'Categorias de reserva'}></Title>
                <Button shadow={true} onClick={() => navigate('/reservationCategoryCreate')}>+ Nova categoria</Button>
                </div>
            </TitlePage>
            <Body>
                <div id='reservationCategoryTableContent' className='hidden sm:flex flex-col items-center justify-center w-full mt-8 boxShadow overflow-y-auto max-h-96 rounded-lg'>
                    <TableDefault  title={tableThead} data={apiData} collumns={["category_name", "reserve_open_in", 'reservation_type']} />
                </div>
            </Body>
        </Container>
    )
}