
// hooks
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// components
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { TitlePage } from '../../../components/titlePages/title.page'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { validateFields } from '../../../utils/form.validator'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Body } from '../../../components/container/Body'
import { LabelInput } from '../../../components/label/label.input'
import { Footer } from '../../../components/footer/Footer'
// utils
import { validateEmail } from '../../../utils/validators/email.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { mCPF } from '../../../utils/validators/cpfMask'
import { justNumber } from '../../../utils/validators/justNumber'
import { maskPhone } from '../../../utils/validators/phoneMask'
import { textMask } from '../../../utils/validators/textMask'
//services
import { api } from '../../../services/api/api'
import { removePontuaction } from '../../../utils/validators/removePontuaction'

export function UserCreate() {

    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { screenY } = useScreenSizeContext()

    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const [typeAccessList, setTypeAccessList] = useState([])
    //selectedVariables
    const [typeAccess, setTypeAccess] = useState('')
    const [selectedPermissionGroup, setSelectedPermissionGroup] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [birthday, setBirthday] = useState('')
    const [gender, setGender] = useState('')
    const [dddmobile, setDddmobile] = useState('')
    const [mobile, setMobile] = useState('')
    const [dddphone, setDddphone] = useState('')
    const [phone, setPhone,] = useState('')
    const [cpf, setCpf] = useState('')

    // function cleanState() {
    //     setTypeAccess('')
    //     setFirstname('')
    //     setLastname('')
    //     setEmail('')
    //     setCpf('')
    //     setDddmobile('')
    //     setMobile('')
    //     setBirthday('')
    // }

    useEffect(() => {

        async function getTypeAccess() {

            try {

                const getAccess = await api.get('/api/v1/typeAccess', {
                    headers: {
                        authorization: `Bearer ${userData.token}`
                    }
                })

                const filterAccess = getAccess.data.data.filter(access => {
                    return access.id < 5
                })

                setTypeAccessList(filterAccess)
                setShowModificationModal(false)
                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        getTypeAccess()

    }, [])

    async function handleSubmit() {

        const requiredFields = [
            { name: 'firstname', value: firstname, required: true, type: 'string' },
            { name: 'lastname', value: lastname, required: true, type: 'string' },
            { name: 'email', value: email, required: true, type: 'string' },
            { name: 'typeAccess', value: typeAccess, required: true, type: 'string' },
        ]

        setLoading(true)

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (!validateEmail(email)) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('E-mail inválido')
        }

        if (typeAccess === '') {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Selecione um tipo de acesso')
        }

        try {
            await api.post('api/v1/users', {
                firstname,
                lastname,
                email,
                typeAccess_id: parseInt(typeAccess.id),
                birthday: birthday ? birthday : undefined,
                gender: gender ? gender?.id : undefined,
                cpf: cpf ? parseInt(removePontuaction(cpf)) : undefined,
                dddmobile: dddmobile ? dddmobile : undefined,
                mobile: mobile ? parseInt(removePontuaction(mobile)) : undefined,
                dddphone: dddphone ? dddphone : undefined,
                phone: phone ? parseInt(removePontuaction(phone)) : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/users')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        // cleanState()
        setLoading(false)

    }

    //temporary API
    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        }
    ]

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={'Cadastro de Usuários'} />
                </div>
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ?
                        <div className='flex flex-col items-center justify-center w-full h-full'>
                            <DefaultLoader />
                        </div>
                        :
                        <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                            <form className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                    <Input charLimit={255} id='firstname' type='text' value={firstname} onChange={(e) => setFirstname(textMask(e.target.value))} autoFocus={true}></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Sobrenome *</a>
                                    <Input charLimit={255} id='lastname' type='text' value={lastname} onChange={(e) => setLastname(textMask(e.target.value))}></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                    <Input charLimit={255} id='email' type='e-mail' value={email} onChange={(e) => { validateEmail(e.target.value); setEmail(e.target.value) }}></Input>
                                </label>
                                <LabelInput text={'Data de nascimento '}>
                                    <Input id='birthday' value={birthday} type={'date'} onChange={(e) => setBirthday(e.target.value)}></Input>
                                </LabelInput>
                                <label className='relative flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Tipo de acesso *</a>
                                    <InputAutoComplete
                                        data={typeAccessList}
                                        selectedLabel={'description'}
                                        optionList={['description']}
                                        onChange={typeAccess => setTypeAccess(typeAccess)}
                                        preSelectedValue={typeAccessList ? typeAccessList.filter(type => type == typeAccess)[0]?.description : ''}
                                        id='typeAccess'
                                        width={'318px'}
                                    />
                                </label>
                                <LabelInput text={'Gênero'}>
                                    <InputAutoComplete
                                        data={sexTypes}
                                        selectedLabel={'name'}
                                        optionList={['id', 'name']}
                                        placeHolder=' - '
                                        onChange={e => setGender(e)}
                                        preSelectedValue={gender ? sexTypes.filter(sex => sex.id == gender.id)[0]?.name : ''}
                                        id='user_gender_select'
                                    />
                                </LabelInput>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>CPF</a>
                                    <Input charLimit={14} id='cpf' type='text' value={cpf} onChange={(e) => setCpf(mCPF(e.target.value))}></Input>
                                </label>
                                <div className='flex flex-row gap-3'>
                                    <LabelInput text={'DDD'}>
                                        <Input charLimit={2} width={'50px'} type={'text'} value={dddmobile} onChange={(e) => setDddmobile(justNumber(e.target.value))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Celular'}>
                                        <Input charLimit={10} width={'255px'} type={'text'} value={mobile} onChange={(e) => setMobile(maskPhone(e.target.value))}></Input>
                                    </LabelInput>
                                </div>
                                <div className='flex flex-row gap-3'>
                                    <LabelInput text={'DDD'}>
                                        <Input charLimit={2} width={'50px'} type={'text'} onChange={(e) => setDddphone(justNumber(e.target.value))} value={dddphone}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Telefone'}>
                                        <Input charLimit={10} width={'255px'} type={'text'} onChange={(e) => setPhone(maskPhone(e.target.value))} value={phone}></Input>
                                    </LabelInput>
                                </div>

                            </form>
                        </div>
                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button
                        id='cancelUserEdit'
                        approval={false}
                        shadow={true}
                        onClick={() => { navigate('/users'); setShowModificationModal(false) }}
                    >Voltar
                    </Button>
                    <Button
                        id='approvalUserEdit'
                        approval={true}
                        shadow={true}
                        onClick={() => handleSubmit()}
                    >Salvar
                    </Button>
                </div>
            </Footer>
        </Container>
    )
}
