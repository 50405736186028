//hooks
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
//context
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//components
import { Container } from '../../../components/container/container'
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Toggle } from '../../../components/toggle/toggleButton'
import { LogHistory } from '../../../components/logHistory/logHistory'
import { Footer } from '../../../components/footer/Footer'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { validateEmail } from '../../../utils/validators/email.validator'
import { maskCep } from '../../../utils/validators/cepMask'
import { api } from '../../../services/api/api'
import { oabValidate } from '../../../utils/validators/oab.validator'
import { formatSizeCep } from '../../../utils/validators/formatSizeCep'
import { justNumber } from '../../../utils/validators/justNumber'
import { maskPhone } from '../../../utils/validators/phoneMask'
//services
import { getPartners, getTypeAccess, getUserEspecific } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { removePontuaction } from '../../../utils/validators/removePontuaction'
import { textMask } from '../../../utils/validators/textMask'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/perspective.css'

export function UsersPartnersEdit() {
    const [nameTitle, setNameTitle] = useState('')

    const [loading, setLoading] = useState(true)
    const { userData } = useAuth()
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const [typeAccessList, setTypeAccessList] = useState([])
    const { id } = useParams()
    const [showLogs, setShowLogs] = useState(false)
    //dados matriz
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [oab, setOab] = useState('')
    const [oabValid, setOabValid] = useState(true)
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(true)
    const [dddphone, setDddphone] = useState('')
    const [phone, setPhone] = useState('')
    const [dddmobile, setDddmobile] = useState('')
    const [mobile, setMobile] = useState('')
    const [typeAccess, setTypeAccess] = useState('')
    const [cep, setCep] = useState('')
    const [newAddressState, setNewAddressState] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressStateList, setAddressStateList] = useState([])
    const [addressCityList, setAddressCityList] = useState([])
    const [addressCity, setAddressCity] = useState('')
    const [addressDistrict, setAddressDistrict] = useState('')
    const [addressRoad, setAddressRoad] = useState('')
    const [addressNumber, setAddressNumber] = useState(0)
    const [addressComplement, setAddressComplement] = useState('')
    const [gender, setGender] = useState('')
    const [birthday, setBirthday] = useState('')
    const [listPartners, setListPartners] = useState([])
    const [partners_id, setPartners_id] = useState('')
    const [activeUsersPartner, setActiveUsersPartner] = useState(false)
    const [modalAlterPass, setModalAlterPass] = useState(false)
    //dados externos
    const [addressState_externalId, setAddressState_externalId] = useState('')
    const [addressCity_externalId, setAddressCity_externalId] = useState('')
    const [microregion_externalId, setMicroregion_externalId] = useState('')
    const [mesoregion_externalId, setMesoregion_externalId] = useState('')

    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        }
    ]

    useEffect(() => {

        async function getStates() {

            setLoading(true)

            try {

                const getState = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
                const getStateData = await getState.json()

                setAddressStateList(getStateData.sort((a, b) => a.sigla > b.sigla ? 1 : -1))

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        async function getData() {

            try {

                const searchPartners = await getPartners(userData.token)
                if (searchPartners.error) {
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(searchPartners.data)
                }

                const getPartnersUserData = await getUserEspecific(userData.token, id)
                if (getPartnersUserData.error) {
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(getPartnersUserData.data)
                }

                const getAccess = await getTypeAccess(userData.token)
                if (getAccess.error) {
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(getAccess.data)
                }
                setNameTitle(getPartnersUserData.data[0]?.firstname)
                setListPartners(searchPartners.data)
                setFirstname(getPartnersUserData.data[0]?.firstname)
                setLastname(getPartnersUserData.data[0]?.lastname)
                setEmail(getPartnersUserData.data[0]?.email)
                setBirthday(getPartnersUserData.data[0]?.birthday ? getPartnersUserData.data[0].birthday.split('T')[0] : '')
                setGender(getPartnersUserData.data[0].gender)
                setOab(getPartnersUserData.data[0]?.oab?.toUpperCase())
                setDddphone(getPartnersUserData.data[0]?.dddphone)
                setPhone(maskPhone(getPartnersUserData.data[0]?.phone))
                setDddmobile(getPartnersUserData.data[0]?.dddmobile)
                setMobile(maskPhone(getPartnersUserData.data[0]?.mobile))
                setCep(getPartnersUserData.data[0]?.cep ? formatSizeCep(getPartnersUserData.data[0]?.cep) : '')
                setAddressState(getPartnersUserData.data[0]?.addressState)
                setNewAddressState(addressStateList.filter((item) => {
                    return item?.sigla == getPartnersUserData?.data[0]?.addressState
                })[0])
                setAddressCity(getPartnersUserData.data[0]?.addressCity)
                setAddressDistrict(getPartnersUserData.data[0]?.addressDistrict)
                setAddressRoad(getPartnersUserData.data[0]?.addressRoad)
                setAddressNumber(getPartnersUserData.data[0]?.addressNumber)
                setAddressComplement(getPartnersUserData.data[0]?.addressComplement)
                setTypeAccess(getPartnersUserData.data[0]?.typeAccess_id)
                setPartners_id(getPartnersUserData.data[0]?.partners_id)
                setActiveUsersPartner(getPartnersUserData.data[0]?.active)
                setAddressState_externalId(getPartnersUserData.data[0]?.addressState_externalId)
                setAddressCity_externalId(getPartnersUserData.data[0]?.addressCity_externalId)
                setMicroregion_externalId(getPartnersUserData.data[0]?.microregion_externalId)
                setMesoregion_externalId(getPartnersUserData.data[0]?.mesoregion_externalId)
                setTypeAccessList(getAccess.data.filter(access => access.id > 4))
                setShowModificationModal(false)
                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getStates()
        getData()

    }, [])

    async function getCityApi(data) {

        setLoading(true)

        try {

            const getCity = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data?.id}/municipios`)
            const getCityData = await getCity.json()

            setAddressCityList(getCityData)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSubmit() {

        const requiredFields = [
            { name: 'firstname', value: firstname, required: true, type: 'string' },
            { name: 'lastname', value: lastname, required: true, type: 'string' },
            { name: 'typeAccess', value: typeAccess, required: true, type: 'string' },
            { name: 'email', value: email, required: true, type: 'string' },
            { name: 'addressCity', value: addressCity, required: true, type: 'string' }
        ]

        if (typeAccess !== 7){
            requiredFields.push({ name: 'partners_id', value: partners_id ?? '', required: true, type: 'string' })
        }

        if (!newAddressState) {
            requiredFields.push({ name: 'addressState', value: newAddressState, required: true, type: 'string' })
        }

        if (validateFields(requiredFields)?.length > 0) {
            // setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (!emailValid) {
            // setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('E-mail inválido')
        }
        if (!oabValid) {
            // setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('OAB inválida')
        }
        !oabValid
        if (typeAccess === '') {
            // setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Selecione um tipo de acesso')
        }

        try {

            await api.put(`api/v1/users/${id}`, {
                firstname: firstname ? firstname : undefined,
                lastname: lastname ? lastname : undefined,
                email: email ? email : undefined,
                birthday: birthday ? birthday : null,
                gender: gender ? gender : null,
                oab: oab ? oab : null,
                typeAccess_id: parseInt(typeAccess),
                partners_id: partners_id ? partners_id : undefined,
                cep: cep ? parseInt(cep.replace('-', '')) : null,
                addressRoad: addressRoad ? addressRoad : null,
                addressNumber: addressNumber ? addressNumber : null,
                addressDistrict: addressDistrict ? addressDistrict : null,
                addressCity: addressCity?.nome ? addressCity?.nome : undefined,
                addressState: newAddressState?.sigla ? newAddressState?.sigla : addressState ? addressState : undefined,
                addressComplement: addressComplement ? addressComplement : null,
                dddmobile: dddmobile ? dddmobile : null,
                mobile: mobile ? parseInt(removePontuaction(mobile)) : null,
                dddphone: dddphone ? dddphone : null,
                phone: phone ? parseInt(removePontuaction(phone)) : null,
                addressState_externalId: addressState?.id ? addressState?.id.toString() : undefined,
                addressCity_externalId: addressCity?.id ? addressCity?.id.toString() : undefined,
                microregion_externalId: addressCity?.microrregiao?.id ? addressCity?.microrregiao?.id.toString() : undefined,
                mesoregion_externalId: addressCity?.microrregiao?.mesorregiao?.id ? addressCity?.microrregiao?.mesorregiao?.id.toString() : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('@auth:token')}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Edição realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/usersPartners')
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setLoading(false)

    }

    async function handleDelete() {
        setLoading(true)

        try {

            await api.delete(`/api/v1/users/${id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('@auth:token')}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Exclusão realizada com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/usersPartners')
            setShowUniversalModal(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowUniversalModal(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function usersPartnerStatus() {

        const status = !activeUsersPartner
        setActiveUsersPartner(status)
        try {

            await api.put(`/api/v1/users/${id}/active/${status ? 1 : 0}`, {}, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })
            setShowNotificationModalSuccess(true)
            setShowModificationModal(true)
            setShowNotificationModalText(status === false ? `Usuário ${firstname} desativado com sucesso.` : `Usuário ${firstname} ativado com sucesso.`)

        } catch (error) {
            setLoading(false)
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                setShowNotificationModalText(responseError(error))
            }
        }
    }

    async function handleAlterPass() {
        setLoading(true)

        try {
            const alterPass = await api.put(`api/v1/users/alterPass/${id}`, {}, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })

            setShowModificationModal(true)
            setShowUniversalModal(false)
            setLoading(false)
            setModalAlterPass(false)
            setShowNotificationModalText(alterPass?.data?.message)
            setShowNotificationModalSuccess(true)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowUniversalModal(false)
                setModalAlterPass(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowUniversalModal(false)
                setModalAlterPass(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            {
                modalAlterPass && showUniversalModal ?
                    <ScreenModal title={'Reenviar senha'} width={400} height={170} >
                        <div className='w-full p-2 h-96 flex flex-col items-center justify-center'>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente reenviar a senha?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button shadow={true} onClick={() => { handleAlterPass() }}>Sim</Button>
                                <Button shadow={true} onClick={() => { setShowUniversalModal(false); setModalAlterPass(false) }} approval={false}>Não</Button>
                            </div>
                        </div>
                    </ScreenModal>
                    :
                    <ScreenModal title={'Exlusão de usuário parceiro'} width={400} height={200} >
                        <div className='w-full p-2 h-96 flex flex-col items-center justify-center'>
                            <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente excluir o usuário parceiro {`${firstname} ${lastname}`} ?</p>
                            <div className='flex flex-row gap-1 mt-10'>
                                <Button shadow={true} onClick={() => handleDelete()}>Sim</Button>
                                <Button shadow={true} onClick={() => setShowUniversalModal(false)} approval={false}>Não</Button>
                            </div>
                        </div>
                    </ScreenModal>
            }
            <Container>
                <TitlePage>
                    <div className='flex justify-center lg:justify-start'>
                        <Title text={`Editar Usuário - ${nameTitle} `} />
                    </div>
                </TitlePage>
                <Body hasFooter={true}>
                    <div className='flex flex-col items-start justify-start w-full'>
                        {
                            loading ?
                                <div className='flex flex-col w-full items-center justify-center'>
                                    <DefaultLoader />
                                </div>
                                :
                                <>
                                    <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                                        <form onSubmit={e => e.preventDefault()} className='flex flex-col items-start lg:grid lg:grid-cols-2 2xl:grid-cols-3 w-full lg:w-auto xl:grid-cols-3 lg:items-start justify-center gap-2'>
                                            <LabelInput text={'Nome *'}>
                                                <Input type={'text'} value={firstname} id='firstname' onChange={(e) => setFirstname(textMask(e.target.value))} charLimit={100} />
                                            </LabelInput>
                                            <LabelInput text={'Sobrenome *'}>
                                                <Input type={'text'} value={lastname} id='lastname' onChange={(e) => setLastname(textMask(e.target.value))} charLimit={100} />
                                            </LabelInput>
                                            <LabelInput text={'E-mail *'} valid={!emailValid ? false : true}>
                                                <Input type={'text'} value={email} charLimit={255} id='email' onChange={(e) => {
                                                    const isValid = validateEmail(e.target.value)
                                                    if (isValid === true) {
                                                        setEmailValid(true)
                                                    } else {
                                                        setEmailValid(false)
                                                    }
                                                    setEmail(e.target.value)
                                                }} />
                                            </LabelInput>
                                            <LabelInput text={'Data de nascimento '}>
                                                <Input id='birthday' value={birthday} type={'date'} onChange={(e) => setBirthday(e.target.value)}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Gênero'}>
                                                <InputAutoComplete
                                                    data={sexTypes}
                                                    selectedLabel={'name'}
                                                    optionList={['id', 'name']}
                                                    placeHolder=' - '
                                                    onChange={e => setGender(e?.id)}
                                                    preSelectedValue={gender ? sexTypes.filter(sex => sex.id == gender)[0]?.name : ''}
                                                />
                                            </LabelInput>
                                            <LabelInput text={'OAB'} valid={!oabValid ? false : true}>
                                                <Input type={'text'} id='oab' value={oab} onChange={(e) => {
                                                    const isValidOab = oabValidate(e.target.value)
                                                    if (isValidOab === true) {
                                                        setOabValid(true)
                                                    } else {
                                                        setOabValid(false)
                                                    }
                                                    setOab(e.target.value)
                                                }} charLimit={8} />
                                            </LabelInput>
                                            <LabelInput text={'Tipo de acesso *'}>
                                                <InputAutoComplete
                                                    data={typeAccessList}
                                                    selectedLabel={'description'}
                                                    optionList={['id', 'description']}
                                                    disabled={userData?.typeAccess_id == 1 ? false : true}
                                                    onChange={typeAccess => {
                                                        setPartners_id('')
                                                        setTypeAccess(typeAccess?.id)
                                                    }}
                                                    preSelectedValue={typeAccess ? typeAccessList?.find(type => type?.id == typeAccess)?.description : ''}
                                                    value={typeAccess ? typeAccessList?.find(type => type?.id == typeAccess)?.description : ''}
                                                    id='typeAccess'
                                                />
                                            </LabelInput>
                                            {
                                                typeAccess !== 7 ?
                                                <LabelInput text={'Selecione o parceiro *'}>
                                                    <InputAutoComplete
                                                        preSelectedValue={partners_id ? listPartners.filter(item => item?.id == partners_id)[0]?.name : ''}
                                                        value={partners_id ? listPartners.filter(item => item?.id == partners_id)[0]?.name : ''}
                                                        selectedLabel={'name'} id='partners_id'
                                                        data={typeAccess == 5 ? listPartners.filter(e => e.isMaster == true) : listPartners} 
                                                        optionList={['id', 'name']}
                                                        disabled={userData?.typeAccess_id == 1 ? false : true}
                                                        onChange={e => {
                                                            setPartners_id(e?.id)
                                                        }}>
                                                    </InputAutoComplete>
                                                </LabelInput>
                                                : <></>
                                            }
                                            <LabelInput text={'CEP'}>
                                                <Input type={'text'} value={maskCep(cep)} id='cep' onChange={(e) => setCep(e.target.value)} charLimit={9} />
                                            </LabelInput>
                                            <LabelInput text={'Estado *'}>
                                                <InputAutoComplete
                                                    preSelectedValue={newAddressState ? newAddressState?.nome : addressStateList.filter((item) => {
                                                        return item?.sigla == addressState
                                                    })[0]?.nome}
                                                    selectedLabel={'nome'}
                                                    id='addressState'
                                                    data={addressStateList}
                                                    optionList={['nome']}
                                                    onChange={e => {
                                                        {
                                                            // setAddressCity('')
                                                            setNewAddressState(e)
                                                            e?.nome !== undefined && getCityApi(e)
                                                        }
                                                    }}>
                                                </InputAutoComplete>
                                            </LabelInput>
                                            {
                                                !newAddressState?.nome ?
                                                    <LabelInput text={'Cidade *'}>
                                                        <Input type={'text'} value={addressCity} id='addressCity' disabled={true} />
                                                    </LabelInput>
                                                    :
                                                    <LabelInput text={'Cidade *'}>
                                                        <InputAutoComplete
                                                            preSelectedValue={addressCity ? addressCity?.nome : addressCityList?.nome}
                                                            selectedLabel={'nome'} id='addressCity'
                                                            data={addressCityList} optionList={['nome']}
                                                            onChange={e => {
                                                                setAddressCity(e)
                                                            }}>
                                                        </InputAutoComplete>
                                                    </LabelInput>
                                            }
                                            <LabelInput text={'Bairro'}>
                                                <Input type={'text'} value={addressDistrict} onChange={e => setAddressDistrict(e.target.value)} />
                                            </LabelInput>
                                            <LabelInput text={'Logradouro'}>
                                                <Input type={'text'} value={addressRoad} onChange={e => setAddressRoad(e.target.value)} />
                                            </LabelInput>
                                            <LabelInput text={'Número'}>
                                                <Input type={'text'} id='addressNumber' value={addressNumber} onChange={(e) => setAddressNumber(justNumber(e.target.value))} charLimit={8} />
                                            </LabelInput>
                                            <LabelInput text={'Complemento'}>
                                                <Input type={'text'} value={addressComplement} onChange={(e) => setAddressComplement(e.target.value)} charLimit={100} />
                                            </LabelInput>
                                            <div className='flex flex-row gap-3'>
                                                <LabelInput text={'DDD'}>
                                                    <Input charLimit={2} width={'50px'} type={'text'} value={dddmobile} onChange={(e) => setDddmobile(justNumber(e.target.value))}></Input>
                                                </LabelInput>
                                                <LabelInput text={'Celular'}>
                                                    <Input charLimit={9} width={'255px'} type={'text'} value={mobile} onChange={(e) => setMobile(justNumber(e.target.value))}></Input>
                                                </LabelInput>
                                            </div>
                                            <div className='flex flex-row gap-3'>
                                                <LabelInput text={'DDD'}>
                                                    <Input charLimit={2} width={'50px'} type={'text'} onChange={(e) => setDddphone(justNumber(e.target.value))} value={dddphone}></Input>
                                                </LabelInput>
                                                <LabelInput text={'Telefone'}>
                                                    <Input charLimit={8} width={'255px'} type={'text'} onChange={(e) => setPhone(justNumber(e.target.value))} value={phone}></Input>
                                                </LabelInput>
                                            </div>
                                            {
                                                userData?.typeAccess_id == 1 || userData?.typeAccess_id == 5 ?
                                                    <div className='flex flex-row lg:m-[1.4rem] gap-3 mt-[1.5rem] '>
                                                        <Button onClick={() => { setModalAlterPass(true); setShowUniversalModal(true) }}>Reenviar senha</Button>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                userData?.typeAccess_id != 5 ?
                                                    <div className='flex flex-col w-full items-start lg:items-start justify-center mt-7'>
                                                        <Tippy content={<span>{activeUsersPartner ? 'Desativar' : 'Ativar'}</span>}
                                                            arrow={true}
                                                            animation='perspective'
                                                            placement='top'
                                                            delay={100}>
                                                            <div>
                                                                <Toggle status={activeUsersPartner ? true : false} onClick={(e) => usersPartnerStatus()} />
                                                            </div>
                                                        </Tippy>

                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </form>
                                        {
                                            [1, 2, 4].includes(userData?.typeAccess_id) &&
                                            <hr className='border-t border-gray-400 w-full py-2 mt-5'/>
                                        }
                                        {
                                            !showLogs && [1, 2, 4].includes(userData?.typeAccess_id) ?
                                            <div className="flex w-full items-center justify-center pb-52">
                                                <Button onClick={() => setShowLogs(true)}>Mostrar Histórico</Button>
                                            </div>
                                            : showLogs && [1, 2, 4].includes(userData?.typeAccess_id) &&
                                            <LogHistory 
                                                apiData={{
                                                    params: `?table_name=users&table_id=${id}`
                                                }}
                                            />
                                        }
                                    </div>
                                </>
                        }
                    </div>
                </Body>
                <Footer>
                    <div className='flex flex-col-reverse lg:flex-row gap-3 w-full items-center h-full justify-between'>
                        <Button
                            id='cancelUserEdit'
                            approval={false}
                            shadow={true}
                            onClick={() => { navigate('/usersPartners'); setShowModificationModal(false) }}
                        >Voltar
                        </Button>
                        <div className='flex flex-row gap-3 items-center justify-center'>
                            {
                                userData?.typeAccess_id === 1 &&
                                <Button
                                    id='deleteUserEdit'
                                    approval={false}
                                    shadow={true}
                                    onClick={() => setShowUniversalModal(true)}
                                >Excluir
                                </Button>
                            }
                            <Button
                                id='approvalUserEdit'
                                approval={true}
                                shadow={true}
                                onClick={() => handleSubmit()}
                            >Editar usuário
                            </Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
}