export function oabValidate(oab){

    try{

        const oabFormated = oab.toUpperCase()
        if(oabFormated.length != 8){
            return false
        }
        const Uf = ['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO']
        const oabUF = oabFormated.slice(0, 2)
        if(!Uf.includes(oabUF)){
            return false
        }
        const oabSplited = oab.slice(-6)
        if(isNaN((oabSplited))){
            throw new Error()
        }
        return true
    }catch(error){
        return false
    }
    
}