import React from 'react'
import { useAuth } from '../contexts/useAuth'
import { AppRoutes } from './app.routes'
import { LoginRoutes } from './login.routes'

export function RedirectRoutes() {

    const { signed } = useAuth()

    return (
        <div>
            {
                signed ?
                    <AppRoutes />
                    :
                    <LoginRoutes />
            }
        </div>
    )
}