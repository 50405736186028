//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// Context
import { useThemeContext } from '../../contexts/themeContext'
import { useAuth } from '../../contexts/useAuth'
import { useSocket } from '../../contexts/socketContext'
//components
import { Container } from '../../components/container/container'
import { Title } from '../../components/titlePages/title'
import { TitlePage } from '../../components/titlePages/title.page'
import { Body } from '../../components/container/Body'
import { CalendarMain } from '../../components/calendar/calendar.main'
import { DayCalendar } from '../../components/calendar/day.calendar'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
//utils
import moment from 'moment'
import 'moment/locale/pt-br'
import { getLinkedOperatorToSupervisor, getPartnersHours, getPartnersLinkedForUser, getPartnersWithLinkedFromSupervisor, getScheduleByDate} from '../../services/api/callAPIsFunctions/defaultCalls.api'
import { LabelInput } from '../../components/label/label.input'
import { InputAutoComplete } from '../../components/input/input.autocomplete'
import { responseError } from '../../utils/responsesFunctions/error.response'
import { Button } from '../../components/buttons/button.default'
import { LoaderWBg } from '../../components/loaders/loaderWBg'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'
import { api } from '../../services/api/api'
import { listHours } from '../../utils/listHours'

export function OperatorScheduleCalendarView() {

    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const { screenY, screenX } = useScreenSizeContext()
    const { socketConnection } = useSocket()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText} = useThemeContext()

    const [value, setValue] = useState(moment().locale('pt-br'))
    const startDay = value.clone().startOf('month').startOf('week')
    const endDay = value.clone().endOf('month').endOf('week')

    const [events, setEvents] = useState([])
    const [filteredEvents, setFilteredEvents] = useState([])
    const [partnerList, setPartnerList] = useState([])
    const [addressStatePartnersList, setAddressStatePartnersList] = useState([])
    const [filteredPartners, setFilteredPartners] = useState([])
    const [filteredAddressStatePartnersList, setFilteredAddressStatePartnersList] = useState([])
    const [addressCity, setAddressCities] = useState([])
    const [filteredAddressCity, setFilteredAddressCities] = useState([])

    const [selectedAddressState, setSelectedAddressState] = useState([])
    const [selectedAddressCity, setSelectedAddressCity] = useState([])
    const [selectedDay, setSelectedDay] = useState(0)
    const [selectedPartner, setSelectedPartner] = useState('')
    const [hasUpdate, setHasUpdate] = useState(false)
    const [blockData, setBlockData] = useState('')

    let pos = { top: 0, left: 0, x: 0, y: 0 };
    let mouseDow = false

    async function getEvents(date) {
        const dateAPI = !date ? moment().format('YYYY-MM-DD') : date?.clone()?.format('YYYY-MM-DD')

        setLoading(true)

        let eventsData = await getScheduleByDate(userData.token, dateAPI)

        // REGRA PARA SUPERVISORES VINCULADOS COM PARCEIROS
        if ([2].includes(userData?.typeAccess_id)) {

            let idsPartnersLink = []

            const partnersLinked = await getPartnersLinkedForUser(userData.token, userData.id)
            partnersLinked.data.filter(partner => { return idsPartnersLink.push(partner?.id) })

            if (partnersLinked.data.length > 0) {

                let filteredPartnersList = []

                partnerList.map(partner => {
                    if ((idsPartnersLink.includes(partner?.id)) || (idsPartnersLink.includes(partner?.master_id))) {
                        filteredPartnersList.push(partner?.id)
                    }
                })

                eventsData.data = eventsData.data.filter(event => {
                    if (filteredPartnersList.includes(event?.partners_id)) {
                        return event
                    }
                })
            }
        }

        setEvents(eventsData)
        setFilteredEvents(eventsData)
        setLoading(false)
    }

    async function getData() {

        try {
            let partnerListData = await getPartnersHours(userData?.token)

            // REGRA PARA SUPERVISORES VINCULADOS COM PARCEIROS
            if ([2].includes(userData?.typeAccess_id)) {
                let idsPartnersLink = []

                const partnersLinked = await getPartnersLinkedForUser(userData.token, userData.id)
                partnersLinked.data.filter(partner => { return idsPartnersLink.push(partner?.id) })

                if (partnersLinked.data.length > 0) {
                    let filteredPartnersList = []

                    partnerListData.data.map(partner => {
                        if ((idsPartnersLink.includes(partner?.id)) || (idsPartnersLink.includes(partner?.master_id))) {
                            filteredPartnersList.push(partner?.id)
                        }
                    })

                    partnerListData.data = partnerListData.data.filter(partner => {
                        if (filteredPartnersList.includes(partner?.id)) {
                            return partner
                        }
                    })
                }
            }

            // REGRA PARA OPERADOR COM VINCULO NO SUPERVISOR, PARA PEGAR EMPRESA COM BASE NO SUPERVISOR
            if ([3].includes(userData?.typeAccess_id)) {
                const linkedSupervisorAPI = await getLinkedOperatorToSupervisor(userData.token, userData.id)
                if (linkedSupervisorAPI.data.length > 0){
                    const partnersToSupervisor = await getPartnersWithLinkedFromSupervisor(userData.token)
                    partnerListData.data = structuredClone(partnersToSupervisor.data)
                }
            }
            
            setPartnerList(partnerListData?.data)

            let stateArr = []
            let statesIncluded = []
            let citiesArr = []
            let citiesIncluded = []
            partnerListData?.data.map(partner => {

                if (!statesIncluded.includes(partner?.addressState)) {
                    stateArr.push({ addressState_externalId: partner?.addressState_externalId, addressState: partner?.addressState })
                    statesIncluded.push(partner?.addressState)
                }
                if (!citiesIncluded.includes(partner?.addressCity_externalId) && partner?.addressState_externalId.slice(0, 2) === partner?.addressState_externalId) {
                    citiesArr.push({ addressCity_externalId: partner?.addressCity_externalId, addressCity: partner?.addressCity })
                    citiesIncluded.push(partner?.addressCity_externalId)
                }
            })
            setAddressStatePartnersList(stateArr.sort((a, b) => a?.addressState_externalId - b?.addressState_externalId))
            setFilteredAddressStatePartnersList(stateArr.sort((a, b) => a?.addressState_externalId - b?.addressState_externalId))
            setAddressCities(citiesArr.sort((a, b) => a?.addressCity_externalId - b?.addressCity_externalId))
            setFilteredAddressCities(citiesArr.sort((a, b) => a?.addressCity_externalId - b?.addressCity_externalId))
            setFilteredPartners(partnerListData?.data)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        const day = startDay.clone().subtract(1, 'day')
        const a = []
        while (day.isBefore(endDay, "day")) {
            a.push(
                Array(7).fill(0).map(() => day.add(1, "day").clone())
            )
        }
        let thisDay = 0

        a.map((week) => {
            week.map((day) => {
                if (day.date() === moment().date() && day.month() === moment().month() && day.year() === moment().year()) {
                    if (!selectedDay) {
                        setSelectedDay(day)
                    }
                }
            })
        })
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        const calender = document.getElementById('calendarArea')
        calender.scrollTop = 100;
        calender.scrollLeft = 150;

        calender.onmousedown = function (e) {
            mouseDow = true
            pos = {
                // The current scroll
                left: calender.scrollLeft,
                top: calender.scrollTop,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY,
            };
            window.addEventListener('mousemove', (e) => mouseMoveHandler(e));
            window.addEventListener('mouseup', (e) => mouseUpHandler(e));
        };

        const mouseMoveHandler = function (e) {
            if (mouseDow) {
                // How far the mouse has been moved
                const dx = e.clientX - pos.x;
                const dy = e.clientY - pos.y;

                // Scroll the element
                calender.scrollTop = pos.top - dy;
                calender.scrollLeft = pos.left - dx;

                calender.style.cursor = 'grabbing';
                calender.style.userSelect = 'none';
            }
        };

        const mouseUpHandler = function () {
            if (mouseDow) {
                mouseDow = false
                calender.style.cursor = 'default';
                calender.style.removeProperty('user-select');
            }
        };

        return () => {
            document.removeEventListener('mousemove', (e) => mouseMoveHandler(e));
        }
    }, [])

    useEffect(() => {
        async function getDataAPI(){
            if (selectedPartner?.id) {
                await getBlockOnDateAndPartner(moment(selectedDay)?.utc(false).format('YYYY-MM-DD'), selectedPartner?.id)
                getEvents(selectedDay)
            } else {
                getEvents(selectedDay)
            }
        }

        getDataAPI()
    }, [selectedDay])

    useEffect(() => {
         
        socketConnection?.on('schedule:create', async (param) => {
            setHasUpdate(true)
        })
        socketConnection?.on('schedule:update', async (param) => {
            setHasUpdate(true)
        })
        socketConnection?.on('schedule:updateStatus', async (param) => {
            setHasUpdate(true)
        })
        socketConnection?.on('schedule:remove', async (param) => {
            setHasUpdate(true)
        })
    
        return () => {
            socketConnection?.off('schedule:create')
            socketConnection?.off('schedule:update')
            socketConnection?.off('schedule:updateStatus')
            socketConnection?.off('schedule:remove')
        }

    }, [socketConnection])

    function filterPartnerByState(state) {

        setSelectedAddressState(state)
        let filteredPartnersState = []
        let filteredPartnersCities = []
        let citiesIncluded = []

        if (state?.id === 0) {
            setFilteredPartners(partnerList)
            setFilteredEvents(events)
            setFilteredAddressCities(addressCity)
        } else {
            partnerList.filter(partner => {
                if (partner.addressState_externalId === state.addressState_externalId) {
                    filteredPartnersState.push(partner)
                }
                addressCity.map(city => {
                    if (!citiesIncluded.includes(city.addressCity_externalId) && (city.addressCity_externalId.slice(0, 2) === state.addressState_externalId)) {
                        filteredPartnersCities.push(city)
                        citiesIncluded.push(city.addressCity_externalId)
                    }
                })
            })
            setFilteredPartners(filteredPartnersState)
            let filteredEvents = { data: [] }

            events.data.map(event => {
                filteredPartnersState.map(partner => {
                    if (partner.id === event.partners_id) {
                        filteredEvents.data.push(event)
                    }
                })
            })
            setFilteredEvents(filteredEvents)
            setSelectedAddressCity({ id: 0, addressCity_externalId: 0, addressCity: `Todas` })
            setFilteredAddressCities(filteredPartnersCities)
        }
    }

    function filterPartnerByCity(city) {

        setSelectedAddressCity(city)

        let filteredPartnersState = []
        let filteredPartnersCities = []


        if (city?.id === 0) {
            partnerList.filter(partner => {
                if (partner.addressState_externalId === selectedAddressState.addressState_externalId) {
                    filteredPartnersState.push(partner)
                }

            })
            setFilteredPartners(filteredPartnersState)

            if (selectedAddressState?.addressState_externalId) {
                let filteredEvents = { data: [] }
                events.data.map(event => {
                    filteredPartnersState.map(partner => {
                        if (partner.id === event.partners_id) {
                            filteredEvents.data.push(event)
                        }
                    })
                })
                setFilteredEvents(filteredEvents)
            }
        } else {

            partnerList.filter(partner => {
                if (partner.addressCity_externalId === city.addressCity_externalId) {
                    filteredPartnersState.push(partner)
                }
            })
            setFilteredPartners(filteredPartnersState)

            let filteredEvents = { data: [] }
            events.data.map(event => {
                filteredPartnersState.map(partner => {
                    if (partner.id === event.partners_id) {
                        filteredEvents.data.push(event)
                    }
                })
            })
            setFilteredEvents(filteredEvents)
            setSelectedAddressCity(filteredPartnersCities[0])
        }
    }

    async function handleSelectedPartner(e) {
        setLoading(true)
        if (e?.id === 0) {
            setSelectedPartner(e)
            setFilteredPartners(partnerList)
            setSelectedAddressState({addressState: '', addressState_externalId: 0})
            setSelectedAddressCity({addressCity: '', addressCity_externalId: 0})
            setBlockData('')
            setFilteredEvents(events)
            setLoading(false)
        } else {
            setSelectedPartner(e)
            setSelectedAddressState(filteredAddressStatePartnersList.filter(state => state?.addressState == e?.addressState)[0])
            setSelectedAddressCity(addressCity.filter(city => city?.addressCity == e?.addressCity)[0])
            await getBlockOnDateAndPartner(moment(selectedDay)?.utc(false).format('YYYY-MM-DD'), e?.id)
            setLoading(false)
        }
    }

    async function getBlockOnDateAndPartner(date, partner){
        try {
            const getPartnersData = await api.get(`/api/v1/blockSchedule?dateOff=${date}&partners_id=${partner}`, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })
            setBlockData(getPartnersData?.data?.data[0])
        } catch (error){
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            <div className={`${hasUpdate ? 'flex overflow-hidden' : 'hidden overflow-hidden'}`}>
                <div className={`bg-orange-300`}>
                    <div className={`${hasUpdate && 'Right_To_Center'} bg-orange-300 flex flex-row justify-center duration-200 z-[999] transition-all right-0 top-0 w-[20rem] p-2 absolute rounded-md opacity-1 mt-[4.8rem] mr-[1rem] shadow-xl h-[5rem]`}>
                        <div className={`flex justify-center items-center text-white flex-col`}>
                            <p className='text-sm'>Atualizações disponíveis</p>
                            <p className='text-sm'>
                                <a className='underline text-black cursor-pointer hover:text-white duration-200' onClick={() => {setHasUpdate(false); getEvents(selectedDay)}}>Clique aqui </a> 
                                para atualizar
                            </p>
                        </div>
                        <Tippy
                            content={'Fechar'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={80}>
                            <button className={`w-[2rem] h-[2rem] absolute justify-center items-center right-0 border-none rounded-lg outline-none cursor-pointer opacity-1 mr-[0.2rem] pb-[2px] pl-[1px] hover:duration-[200ms] hover:opacity-5`} onClick={() => {setHasUpdate(false)}}>x</button>                            
                        </Tippy>
                    </div>
                </div>
            </div>
            <Container>
                <TitlePage>
                    <div className='flex flex-col md:flex-row px-2 justify-between lg:justify-start gap-2 items-center w-full'>
                        <div className='flex flex-row w-full h-full'>
                            <Title text={'Visualizar Agenda - Modo Calendário'}></Title>
                            {                                                        
                                blockData &&                                           
                                <p className='ml-20 items-center justify-center h-full flex text-sm text-red-800'>
                                    Bloqueio: {`${listHours?.filter(hour => hour?.id == blockData?.startHour)[0]?.name} às ${listHours?.filter(hour => hour?.id == blockData?.finishHour)[0]?.name}`}
                                </p>
                            }
                        </div>
                    </div>
                </TitlePage>
                <Body dontAnimate={true} hasFooter={screenX < 640 ? false : false}>
                    <>
                        <div className='select-none overflow-auto 2xl:w-[95%] xl:w-[1150px] flex flex-col items-center justify-center'>
                            <div style={{ height: screenX > 640 ? screenY - 200 : '', maxHeight: screenX > 640 ? screenY - 200 : '' }} className='flex lg:flex-row flex-col w-[340px] md:w-[800px] xl:w-[1150px] 2xl:w-full border border-zinc-200 dark:border-secondaryBorderDark'>
                                <div className=' dark:bg-secondaryDefaultDark bg-bgPrimaryLight w-60 p-2 z-[201] flex flex-col lg:ml-0 ml-[3.5rem] items-center gap-4 shadow-lg lg:shadow-right '>
                                    <div className=' dark:bg-secondaryDefaultDark bg-bgPrimaryLight lg:w-60 w-60 p-2 z-[201] flex flex-col gap-4 lg:shadow-right'>
                                        <CalendarMain onClick={(e) => { setSelectedDay(e) }}></CalendarMain>
                                    </div>
                                    <LabelInput text={'Filtrar parceiro'}>
                                        <InputAutoComplete 
                                            preSelectedValue={selectedPartner?.name} 
                                            width={56} 
                                            data={[{ name: `Todos`, id: 0 }, ...filteredPartners]} 
                                            selectedLabel={'name'} 
                                            optionList={['id', 'name']} 
                                            onChange={(e) => {if (typeof(e) == 'object') {handleSelectedPartner(e)}}}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Estado'}>
                                        <InputAutoComplete 
                                            disabled={selectedPartner?.id > 0 ? true : false}
                                            preSelectedValue={selectedAddressState.addressState} 
                                            width={56}                                                    
                                            data={[{ id: 0, addressState_externalId: 0, addressState: `Todos` }, ...filteredAddressStatePartnersList]}
                                            selectedLabel={`addressState`} 
                                            optionList={[`addressState_externalId`, `addressState`]} 
                                            onChange={(e) => {if (typeof(e) == 'object') {filterPartnerByState(e)}}} 
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Cidade'}>
                                        <InputAutoComplete 
                                            disabled={selectedPartner?.id > 0 ? true : selectedAddressState?.addressState ? false : true}
                                            preSelectedValue={selectedAddressCity?.addressCity} 
                                            width={56} 
                                            data={[{ id: 0, addressCity_externalId: 0, addressCity: `Todas` }, ...filteredAddressCity]} 
                                            selectedLabel={`addressCity`} 
                                            optionList={[`addressCity_externalId`, `addressCity`]} 
                                            onChange={(e) => {if (typeof(e) == 'object') {filterPartnerByCity(e)}}} 
                                        />
                                    </LabelInput>
                                </div>
                                <div id='calendarArea' className='flex flex-col w-[100%] overflow-auto relative scrollbar-thin scrollbar-track-zinc-100 scrollbar-thumb-zinc-300 scrollbar-thumb-rounded-lg dark:scrollbar-track-zinc-700 dark:scrollbar-thumb-zinc-500'>
                                    {
                                        loading &&
                                        <div className='w-full fixed left-[58%] top-[50%] items-center justify-center z-[999]'>
                                            <LoaderWBg />
                                        </div>
                                    }
                                    <DayCalendar editEvent={() => {}} partner={selectedPartner} events={filteredEvents} cursor={'cursor-help'}/>
                                </div>
                            </div>
                        </div>
                    </>
                </Body>
            </Container>
        </>
    )
}