//hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Input } from "../../../components/input/input";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { LabelInput } from "../../../components/label/label.input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { Button } from "../../../components/buttons/button.default";
import { MyProfilePictureEdit } from "./myProfile.editPicture";
//context
import { useAuth } from "../../../contexts/useAuth";
import { mCPF } from "../../../utils/validators/cpfMask";
import { maskCep } from "../../../utils/validators/cepMask";
import { useThemeContext } from "../../../contexts/themeContext";
//utils
import { validateFields } from "../../../utils/form.validator";
import { validateEmail } from "../../../utils/validators/email.validator";
import { formatSizeCpf } from "../../../utils/validators/formatSizeCpf";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { formatSizeCep } from "../../../utils/validators/formatSizeCep";
import { justNumber } from '../../../utils/validators/justNumber'
//services
import { getMyProfile, getTypeAccess } from "../../../services/api/callAPIsFunctions/defaultCalls.api";
import { api } from "../../../services/api/api";
import { verifyFileSize } from "../../../utils/formatImages/verifySize";


export function MyProfile() {

    const navegate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { userData, isValidToken } = useAuth()
    const [loading, setLoading] = useState(true)
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [birthday, setBirthday] = useState('')
    const [typeAccessList, setTypeAccessList] = useState([])
    const [gender, setGender] = useState('')
    const [cpf, setCpf] = useState('')
    const [dddmobile, setDddmobile] = useState('')
    const [mobile, setMobile] = useState('')
    const [dddphone, setDddphone] = useState('')
    const [phone, setPhone] = useState('')
    const [cep, setCep] = useState('')
    const [newAddressState, setNewAddressState] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressStateList, setAddressStateList] = useState([])
    const [addressCityList, setAddressCityList] = useState([])
    const [addressCity, setAddressCity] = useState('')
    const [addressDistrict, setAddressDistrict] = useState('')
    const [addressRoad, setAddressRoad] = useState('')
    const [addressNumber, setAddressNumber] = useState(0)
    const [addressComplement, setAddressComplement] = useState('')
    const [imageSrc, setImageSrc] = useState(null)
    const [picPreview, setPicturePreview] = useState('')
    const [selectButtonAlter, setSelectButtonAlter] = useState(false)
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
    const date = Date.now()
    //dados externos
    const [addressState_externalId, setAddressState_externalId] = useState('')
    const [addressCity_externalId, setAddressCity_externalId] = useState('')
    const [microregion_externalId, setMicroregion_externalId] = useState('')
    const [mesoregion_externalId, setMesoregion_externalId] = useState('')
    const [profilePic, setProfilePic] = useState('')
    const [typeAccess, setTypeAccess] = useState('')


    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        }
    ]

    useEffect(() => {
        setSelectButtonAlter(false)
        async function getStates() {
            setLoading(true)
            try {

                const getState = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
                const getStateData = await getState.json()
                setAddressStateList(getStateData.sort((a, b) => a.sigla > b.sigla ? 1 : -1))

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        async function getTypesAccessApi() {

            try {
                const getTypesAccess = await getTypeAccess(userData.token)
                setTypeAccessList(getTypesAccess.data)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }


        }

        async function getData() {

            setLoading(true)

            try {

                const myProfileData = await getMyProfile(userData.token)
                console.log(myProfileData?.data)
                setFirstname(myProfileData?.data?.firstname)
                setLastname(myProfileData?.data?.lastname)
                setEmail(myProfileData?.data?.email)
                setBirthday(myProfileData?.data?.birthday ? myProfileData?.data?.birthday.split('T')[0] : '')
                setGender(myProfileData?.data?.gender ? myProfileData?.data?.gender : '')
                setCpf(myProfileData?.data?.cpf ? mCPF(formatSizeCpf(myProfileData?.data.cpf)) : '')
                setDddmobile(myProfileData?.data?.dddmobile)
                setMobile(myProfileData?.data?.mobile)
                setDddphone(myProfileData?.data?.dddphone)
                setPhone(myProfileData?.data?.phone)
                setCep(myProfileData?.data?.cep ? formatSizeCep(myProfileData?.data?.cep) : '')
                setNewAddressState(addressStateList.filter((item) => {
                    return item?.sigla == myProfileData?.data?.addressState
                })[0])
                setAddressState(myProfileData?.data?.addressState)
                setAddressCity(myProfileData?.data?.addressCity)
                setAddressDistrict(myProfileData?.data?.addressDistrict)
                setAddressRoad(myProfileData?.data?.addressRoad)
                setAddressNumber(myProfileData?.data?.addressNumber)
                setAddressComplement(myProfileData?.data?.addressComplement)
                setTypeAccess(myProfileData?.data?.typeAccessDescription)

                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }
        getStates()
        getTypesAccessApi()
        getData()
        setProfilePic(userData.photoFile)

    }, [])

    async function getCityApi(data) {

        setLoading(true)

        try {

            const getCity = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data?.id}/distritos`)
            const getCityData = await getCity.json()

            setAddressCityList(getCityData)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSubmit() {
        setLoading(true)
        const requiredFields = [
            { name: 'firstname', value: firstname, required: true, type: 'string' },
            { name: 'lastname', value: lastname, required: true, type: 'string' },
            { name: 'email', value: email, required: true, type: 'string' }
        ]

        if (userData.typeAccess_id > 4) {
            requiredFields.push({ name: 'addressCity', value: addressCity, required: true, type: 'string' })

            if (!newAddressState) {
                requiredFields.push({ name: 'addressState', value: newAddressState, required: true, type: 'string' })
            }
        }

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (!validateEmail(email)) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('E-mail inválido')
        }

        if (selectButtonAlter) {

            if (!currentPassword) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha o campo senha')
            }

            if (!newPassword) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha o campo nova senha')
            }

            if (!newPasswordConfirm) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Preencha o campo confirmar nova senha')
            }

            if (newPassword !== newPasswordConfirm) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('As senhas não correspondem')
            }

            try {

                await api.put(`api/v1/users/alterPass`, {
                    password: currentPassword,
                    newPassword: newPassword
                }, {
                    headers: {
                        authorization: `Bearer ${userData.token}`
                    }
                })

                setSelectButtonAlter(false)
                setCurrentPassword('')
                setNewPassword('')
                setNewPasswordConfirm('')

            } catch (error) {
                setSelectButtonAlter(false)
                setCurrentPassword('')
                setNewPassword('')
                setNewPasswordConfirm('')
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setSelectButtonAlter(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setSelectButtonAlter(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        setLoading(true)

        try {

            await api.put(`api/v1/myProfile`, {
                firstname: firstname ? firstname : undefined,
                lastname: lastname ? lastname : undefined,
                email: email ? email : undefined,
                birthday: birthday ? birthday : null,
                cpf: cpf ? parseInt(cpf.replace('-', '').replaceAll('.', '')) : undefined,
                gender: gender ? gender : null,
                cep: cep ? parseInt(cep.replace('-', '')) : null,
                addressCity: addressCity?.nome ? addressCity?.nome : undefined,
                addressState: newAddressState?.sigla ? newAddressState?.sigla : addressState ? addressState : undefined,
                addressDistrict: addressDistrict ? addressDistrict : null,
                addressRoad: addressRoad ? addressRoad : null,
                addressNumber: addressNumber ? addressNumber : undefined,
                addressComplement: addressComplement ? addressComplement : null,
                dddmobile: dddmobile ? parseInt(dddmobile) : dddmobile == null ? null : undefined,
                mobile: mobile ? parseInt(mobile) : mobile == null ? null : undefined,
                dddphone: dddphone ? parseInt(dddphone) : dddphone == null ? null : undefined,
                phone: phone ? parseInt(phone) : phone == null ? null : undefined,
                addressState_externalId: addressState?.id ? addressState?.id.toString() : undefined,
                addressCity_externalId: addressCity?.municipio?.id ? addressCity?.municipio?.id.toString() : undefined,
                microregion_externalId: addressCity?.municipio?.microrregiao?.id ? addressCity?.municipio?.microrregiao?.id.toString() : undefined,
                mesoregion_externalId: addressCity?.municipio?.microrregiao?.mesorregiao?.id ? addressCity?.municipio?.microrregiao?.mesorregiao?.id.toString() : undefined,
            }, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })

            await isValidToken()

            setShowModificationModal(true)
            setLoading(false)
            setShowNotificationModalText('Perfil atualizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navegate('/')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

    }

    //picture functions
    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    function handlePicture() {

        const input = document.getElementById('myProfilePicture')
        input.click()
    }

    async function showPreview(e) {

        const file = e.target.files[0];

        const isValidFile = verifyFileSize(file)

        if (!isValidFile){
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Tamanho da imagem inválido. Máximo permitido 20MB.')
        } else {
            let imageDataUrl = await readFile(file)
            setImageSrc(imageDataUrl);
            setPicturePreview(URL.createObjectURL(file))
        }

    }

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={'Meu Perfil'} />
                </div>
            </TitlePage>
            {loading ?
                <Body>
                    <div className="flex flex-col items-center justify-center h-full">
                        <DefaultLoader />
                    </div>
                </Body>
                :
                imageSrc ?
                    <Body>
                        <MyProfilePictureEdit onChange={(e) => setImageSrc(e)} imgSrc={imageSrc} picPreview={picPreview} />
                    </Body>
                    :
                    userData.typeAccess_id < 5 ?
                        <Body>

                            <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                                <div className={`rounded-full w-24 h-24 border-2 border-gray-300 ${profilePic?.length > 0 ? 'bg-transparent' : 'bg-primaryDefaultLight cursor-pointer hover:brightness-125'}  flex flex-col items-center justify-center`}>
                                    {
                                        profilePic?.length > 0 ?
                                            <div className='flex flex-col items-center justify-center h-20 w-20 sm:h-20 sm:w-20 shadow-lg bg-transparent rounded-full overflow-hidden'>
                                                <img src={profilePic}></img>
                                            </div>
                                            :
                                            <a className='text-titleGrayTextLight text-3xl'>{userData?.firstname?.slice(0, 1)}</a>

                                    }
                                </div>
                                <a onClick={() => handlePicture()} className='mt-5 mb-5 hover:underline cursor-pointer text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Alterar foto de perfil</a>
                                <input id='myProfilePicture' type='file' onChange={(e) => showPreview(e)} hidden={true}></input>
                                <form className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                        <Input charLimit={255} id='firstname' type='text' value={firstname} onChange={(e) => setFirstname(e.target.value)}></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Sobrenome *</a>
                                        <Input charLimit={255} id='lastname' type='text' value={lastname} onChange={(e) => setLastname(e.target.value)}></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                        <Input charLimit={255} id='email' type='e-mail' value={email} onChange={(e) => { validateEmail(e.target.value); setEmail(e.target.value) }}></Input>
                                    </label>
                                    <LabelInput text={'Data de nascimento '}>
                                        <Input id='birthday' value={birthday} type={'date'} disabled={true} onChange={(e) => setBirthday(e.target.value)}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Tipo de acesso '}>
                                        <Input 
                                            id='typeAcess' 
                                            type={'text'} 
                                            disabled={true}
                                            value={typeAccess}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Gênero'}>
                                        <InputAutoComplete
                                            data={sexTypes}
                                            selectedLabel={'name'}
                                            optionList={['id', 'name']}
                                            placeHolder=' - '
                                            disabled={true}
                                            onChange={e => setGender(e.id)}
                                            preSelectedValue={gender ? sexTypes.filter(sex => sex.id == gender)[0]?.name : ''}
                                            id='user_gender_select'
                                        />
                                    </LabelInput>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>CPF</a>
                                        <Input charLimit={14} id='cpf' type='text' disabled={true} value={mCPF(cpf)} onChange={(e) => setCpf(e.target.value)}></Input>
                                    </label>
                                    <div className='flex flex-row gap-3'>
                                        <LabelInput text={'DDD'}>
                                            <Input charLimit={2} width={'50px'} type={'text'} value={dddmobile} onChange={(e) => setDddmobile(justNumber(e.target.value))}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Celular'}>
                                            <Input charLimit={9} width={'255px'} type={'text'} value={mobile} onChange={(e) => setMobile(justNumber(e.target.value))}></Input>
                                        </LabelInput>
                                    </div>
                                    <div className='flex flex-row gap-3'>
                                        <LabelInput text={'DDD'}>
                                            <Input charLimit={2} width={'50px'} type={'text'} onChange={(e) => setDddphone(justNumber(e.target.value))} value={dddphone}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Telefone'}>
                                            <Input charLimit={8} width={'255px'} type={'text'} onChange={(e) => setPhone(justNumber(e.target.value))} value={phone}></Input>
                                        </LabelInput>
                                    </div>
                                </form>
                                {
                                    selectButtonAlter ?
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3">
                                            <div className='flex flex-row gap-3'>
                                                <LabelInput text={'Senha atual *'}>
                                                    <Input type={'password'} value={currentPassword} name='currentPassword' onChange={(e) => { setCurrentPassword(e.target.value) }}></Input>
                                                </LabelInput>
                                            </div>
                                            <div className='flex flex-row gap-3 mt-6'>
                                                <a onClick={() => {
                                                    setSelectButtonAlter(false)
                                                    setCurrentPassword('')
                                                    setNewPassword('')
                                                    setNewPasswordConfirm('')
                                                }}
                                                    className="cursor-pointer text-center m-auto items-center justify hover:underline text-sm">Cancelar alteração</a>
                                            </div>
                                            <div className='flex flex-row gap-3'>
                                                <LabelInput text={'Nova senha *'}>
                                                    <Input type={'password'} value={newPassword} name='newPassword' onChange={(e) => { setNewPassword(e.target.value) }}></Input>
                                                </LabelInput>
                                            </div>
                                            <div className='flex flex-row gap-3'>
                                                <LabelInput text={'Confirmar nova senha *'}>
                                                    <Input type={'password'} value={newPasswordConfirm} name='newPasswordConfirm' onChange={(e) => { setNewPasswordConfirm(e.target.value) }}></Input>
                                                </LabelInput>
                                            </div>
                                        </div>
                                        :
                                        <div className=" gap-3 mt-3">
                                            <div className='flex flex-row gap-3'>
                                                {/* <LabelInput text={'Senha'}>
                                                    <Input width={'120px'} type={'text'} disabled={true} value={phone}></Input>
                                                </LabelInput> */}
                                            </div>
                                            <div className='flex  items-start justify-start gap-3 mt-5'>
                                                <a onClick={() => { setSelectButtonAlter(true) }} className="cursor-pointer text-sm dark:text-primaryDefaultDarkColor border-b border-secondaryDefaultLight ">Alterar senha</a>
                                            </div>
                                        </div>
                                }
                                <div className='mt-10 flex flex-row gap-1 w-full items-center justify-start'>
                                    <Button
                                        approval={false}
                                        shadow={true}
                                        onClick={() => { navegate('/'); setShowModificationModal(false) }}
                                    >Cancelar
                                    </Button>
                                    <Button
                                        approval={true}
                                        shadow={true}
                                        onClick={() => handleSubmit()}
                                    >Editar
                                    </Button>
                                </div>
                            </div>
                        </Body>
                        :
                        <Body>

                            <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                                <div className={`rounded-full w-24 h-24 border-2 border-gray-300 ${profilePic?.length > 0 ? 'bg-transparent' : 'bg-primaryDefaultLight cursor-pointer hover:brightness-125'}  flex flex-col items-center justify-center`}>
                                    {
                                        profilePic?.length > 0 ?
                                            <div className='flex flex-col items-center justify-center h-32 w-32 sm:h-20 sm:w-20 shadow-lg bg-transparent rounded-full overflow-hidden'>
                                                <img src={profilePic}></img>
                                            </div>
                                            :
                                            <a className='text-titleGrayTextLight text-3xl'>{userData?.firstname?.slice(0, 1)}</a>

                                    }
                                </div>
                                <a onClick={() => handlePicture()} className='mt-5 mb-5 hover:underline cursor-pointer text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Alterar foto de perfil</a>
                                <input id='myProfilePicture' type='file' onChange={(e) => showPreview(e)} hidden={true}></input>
                                <form className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                        <Input charLimit={255} id='firstname' type='text' value={firstname} onChange={(e) => setFirstname(e.target.value)}></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>Sobrenome *</a>
                                        <Input charLimit={255} id='lastname' type='text' value={lastname} onChange={(e) => setLastname(e.target.value)}></Input>
                                    </label>
                                    <label className='flex flex-col gap-1 items-start justify-center'>
                                        <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                        <Input charLimit={255} id='email' type='e-mail' value={email} onChange={(e) => { validateEmail(e.target.value); setEmail(e.target.value) }}></Input>
                                    </label>
                                    <LabelInput text={'Data de nascimento '}>
                                        <Input id='birthday' value={birthday} type={'date'} disabled={true} onChange={(e) => setBirthday(e.target.value)}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Tipo de acesso '}>
                                        <Input 
                                            id='typeAcess' 
                                            type={'text'} 
                                            disabled={true}
                                            value={typeAccess}
                                        />
                                    </LabelInput>
                                    <LabelInput text={'Gênero'}>
                                        <InputAutoComplete
                                            data={sexTypes}
                                            selectedLabel={'name'}
                                            optionList={['id', 'name']}
                                            placeHolder=' - '
                                            disabled={true}
                                            onChange={e => setGender(e.id)}
                                            preSelectedValue={gender ? sexTypes.filter(sex => sex.id == gender)[0]?.name : ''}
                                            id='user_gender_select'
                                        />
                                    </LabelInput>
                                    <LabelInput text={'CEP'}>
                                        <Input type={'text'} value={maskCep(cep)} id='cep' onChange={(e) => setCep(e.target.value)} charLimit={9} />
                                    </LabelInput>
                                    <LabelInput text={'Estado *'}>
                                        <InputAutoComplete
                                            preSelectedValue={newAddressState ? newAddressState?.nome : addressStateList.filter((item) => {
                                                return item?.sigla == addressState
                                            })[0]?.nome}
                                            selectedLabel={'nome'} id='addressState'
                                            data={addressStateList} optionList={['nome']}
                                            onChange={e => {
                                                {
                                                    setAddressCity('')
                                                    setNewAddressState(e)
                                                    e?.nome !== undefined && getCityApi(e)
                                                }
                                            }}>
                                        </InputAutoComplete>
                                    </LabelInput>
                                    {
                                        !newAddressState?.nome ?
                                            <LabelInput text={'Cidade *'}>
                                                <Input type={'text'} value={addressCity} id='addressCity' disabled={true} />
                                            </LabelInput>
                                            :
                                            <LabelInput text={'Cidade *'}>
                                                <InputAutoComplete
                                                    preSelectedValue={addressCity ? addressCity?.nome : addressCityList?.nome}
                                                    selectedLabel={'nome'} id='addressCity'
                                                    data={addressCityList} optionList={['nome']}
                                                    onChange={e => {
                                                        setAddressCity(e)
                                                    }}>
                                                </InputAutoComplete>
                                            </LabelInput>
                                    }
                                    <LabelInput text={'Bairro'}>
                                        <Input type={'text'} value={addressDistrict} onChange={e => setAddressDistrict(e.target.value)} />
                                    </LabelInput>
                                    <LabelInput text={'Logradouro'}>
                                        <Input type={'text'} value={addressRoad} onChange={e => setAddressRoad(e.target.value)} />
                                    </LabelInput>
                                    <LabelInput text={'Número'}>
                                        <Input type={'text'} id='addressNumber' value={addressNumber} onChange={(e) => setAddressNumber(justNumber(e.target.value))} charLimit={8} />
                                    </LabelInput>
                                    <LabelInput text={'Complemento'}>
                                        <Input type={'text'} value={addressComplement} onChange={(e) => setAddressComplement(e.target.value)} charLimit={100} />
                                    </LabelInput>
                                    <div className='flex flex-row gap-3'>
                                        <LabelInput text={'DDD'}>
                                            <Input charLimit={2} width={'50px'} type={'text'} value={dddmobile} onChange={(e) => setDddmobile(justNumber(e.target.value))}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Celular'}>
                                            <Input charLimit={9} width={'255px'} type={'text'} value={mobile} onChange={(e) => setMobile(justNumber(e.target.value))}></Input>
                                        </LabelInput>
                                    </div>
                                    <div className='flex flex-row gap-3'>
                                        <LabelInput text={'DDD'}>
                                            <Input charLimit={2} width={'50px'} type={'text'} onChange={(e) => setDddphone(justNumber(e.target.value))} value={dddphone}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Telefone'}>
                                            <Input charLimit={8} width={'255px'} type={'text'} onChange={(e) => setPhone(justNumber(e.target.value))} value={phone}></Input>
                                        </LabelInput>
                                    </div>
                                </form>
                                {
                                    selectButtonAlter ?
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3">
                                            <div className='flex flex-row gap-3'>
                                                <LabelInput text={'Senha atual *'}>
                                                    <Input type={'password'} value={currentPassword} name='currentPassword' onChange={(e) => { setCurrentPassword(e.target.value) }}></Input>
                                                </LabelInput>
                                            </div>
                                            <div className='flex flex-row gap-3 mt-6'>
                                                <a onClick={() => {
                                                    setSelectButtonAlter(false)
                                                    setCurrentPassword('')
                                                    setNewPassword('')
                                                    setNewPasswordConfirm('')
                                                }}
                                                    className="cursor-pointer text-center m-auto items-center justify hover:underline text-sm">Cancelar alteração</a>
                                            </div>
                                            <div className='flex flex-row gap-3'>
                                                <LabelInput text={'Nova senha *'}>
                                                    <Input type={'password'} value={newPassword} name='newPassword' onChange={(e) => { setNewPassword(e.target.value) }}></Input>
                                                </LabelInput>
                                            </div>
                                            <div className='flex flex-row gap-3'>
                                                <LabelInput text={'Confirmar nova senha *'}>
                                                    <Input type={'password'} value={newPasswordConfirm} name='newPasswordConfirm' onChange={(e) => { setNewPasswordConfirm(e.target.value) }}></Input>
                                                </LabelInput>
                                            </div>
                                        </div>
                                        :
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3">
                                            {/* <div className='flex flex-row gap-3'>
                                                <LabelInput text={'Senha'}>
                                                    <Input width={'120px'} type={'text'} disabled={true} ></Input>
                                                </LabelInput>
                                            </div> */}
                                            <div className='flex flex-row gap-3 mt-5'>
                                                <a onClick={() => { setSelectButtonAlter(true) }} className="cursor-pointer text-sm dark:text-primaryDefaultDarkColor border-b border-secondaryDefaultLight">Alterar senha</a>
                                            </div>
                                        </div>
                                }
                                <div className='mt-10 flex flex-row gap-4 lg:gap-1 w-full items-center justify-center lg:justify-start'>
                                    <Button
                                        approval={true}
                                        shadow={true}
                                        onClick={() => handleSubmit()}
                                    >Editar
                                    </Button>
                                    <Button
                                        approval={false}
                                        shadow={true}
                                        onClick={() => { navegate('/'); setShowModificationModal(false) }}
                                    >Cancelar
                                    </Button>
                                </div>
                            </div>
                        </Body>
            }

        </Container>
    );
}