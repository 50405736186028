export function validatorHour(array) {

    const arrayKeys = Object.keys(array)

    const errors = arrayKeys.filter((key) => {

        switch (array[key].startHour) {
            case null:
                document.getElementById(`${key} - weekday`).style.border = 'solid red 1px'
                document.getElementById(`${key} - startHour`).style.border = 'solid red 1px'
                document.getElementById(`${key} - finishHour`).style.border = 'solid red 1px'
                return key

            case '':
                document.getElementById(`${key} - weekday`).style.border = 'solid red 1px'
                document.getElementById(`${key} - startHour`).style.border = 'solid red 1px'
                document.getElementById(`${key} - finishHour`).style.border = 'solid red 1px'
                return key

            case undefined:
                document.getElementById(`${key} - weekday`).style.border = 'solid red 1px'
                document.getElementById(`${key} - startHour`).style.border = 'solid red 1px'
                document.getElementById(`${key} - finishHour`).style.border = 'solid red 1px'
                return key

            default:
                break;
        }

        switch (array[key].weekday) {
            case null:
                document.getElementById(`${key} - weekday`).style.border = 'solid red 1px'
                document.getElementById(`${key} - startHour`).style.border = 'solid red 1px'
                document.getElementById(`${key} - finishHour`).style.border = 'solid red 1px'
                return key

            case '':
                document.getElementById(`${key} - weekday`).style.border = 'solid red 1px'
                document.getElementById(`${key} - startHour`).style.border = 'solid red 1px'
                document.getElementById(`${key} - finishHour`).style.border = 'solid red 1px'
                return key

            case undefined:
                document.getElementById(`${key} - weekday`).style.border = 'solid red 1px'
                document.getElementById(`${key} - startHour`).style.border = 'solid red 1px'
                document.getElementById(`${key} - finishHour`).style.border = 'solid red 1px'
                return key

            default:
                break;
        }

        switch (array[key].finishHour) {
            case null:
                document.getElementById(`${key} - weekday`).style.border = 'solid red 1px'
                document.getElementById(`${key} - startHour`).style.border = 'solid red 1px'
                document.getElementById(`${key} - finishHour`).style.border = 'solid red 1px'
                return key

            case '':
                document.getElementById(`${key} - weekday`).style.border = 'solid red 1px'
                document.getElementById(`${key} - startHour`).style.border = 'solid red 1px'
                document.getElementById(`${key} - finishHour`).style.border = 'solid red 1px'
                return key

            case undefined:
                document.getElementById(`${key} - weekday`).style.border = 'solid red 1px'
                document.getElementById(`${key} - startHour`).style.border = 'solid red 1px'
                document.getElementById(`${key} - finishHour`).style.border = 'solid red 1px'
                return key

            default:
                break;
        }

    })

    return errors

}