
// hooks
import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
// components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { validateFields } from '../../../utils/form.validator'
import { validateEmail } from '../../../utils/validators/email.validator'
//context
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//utils
import { mCPF } from '../../../utils/validators/cpfMask'
import { maskCep } from '../../../utils/validators/cepMask'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { api } from '../../../services/api/api'
import { justNumber } from '../../../utils/validators/justNumber'
import { removeEspecialChar } from '../../../utils/validators/removeEspecialCharacter'
import { removePontuaction } from '../../../utils/validators/removePontuaction'
import { maskPhone } from '../../../utils/validators/phoneMask'
import { textMask } from '../../../utils/validators/textMask'
import { Footer } from '../../../components/footer/Footer'

export function ClientsCreate({ defaultRoute, execFuncionRoute, dontShowFooter }) {

    const { userData } = useAuth()
    const navegate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [loading, setLoading] = useState(false)
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(true)
    const [cpf, setCpf] = useState('')
    const [gender, setGender] = useState('')
    const [birthday, setBirthday] = useState('')
    const [dddmobile, setDddmobile] = useState('')
    const [mobile, setMobile] = useState('')
    const [dddphone, setDddphone] = useState('')
    const [phone, setPhone] = useState('')
    const [cep, setCep] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressStateList, setAddressStateList] = useState([])
    const [addressCityList, setAddressCityList] = useState([])
    const [addressCity, setAddressCity] = useState('')
    const [addressDistrict, setAddressDistrict] = useState('')
    const [addressRoad, setAddressRoad] = useState('')
    const [addressNumber, setAddressNumber] = useState(0)
    const [addressComplement, setAddressComplement] = useState('')
    const [observation, setObservation] = useState('')
    const [selectedIsRemote, setSelectedIsRemote] = useState(false)

    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        },
    ]

    useEffect(() => {

        async function getStates() {

            setLoading(true)

            try {

                const getState = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
                const getStateData = await getState.json()

                setAddressStateList(getStateData.sort((a, b) => a.sigla > b.sigla ? 1 : -1))
                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        getStates()

    }, [])

    useEffect(() => {

        setAddressCity('')

    }, [addressState])

    async function handleSubmit() {

        const requiredFields = [
            { name: 'firstname', value: firstname, required: true, type: 'string' },
            { name: 'cpf', value: cpf, required: true, type: 'string' },
            { name: 'addressState', value: addressState, required: true, type: 'string' },
            { name: 'addressCity', value: addressCity, required: true, type: 'string' },
            { name: 'dddMobile', value: dddmobile, required: true, type: 'string' },
            { name: 'mobile', value: mobile, required: true, type: 'string' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }
        if (!emailValid) {
            // setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('E-mail inválido')
        }

        try {

            await api.post(`/api/v1/clients`, {
                firstname: firstname ? firstname : undefined,
                lastname: lastname ? lastname : undefined,
                email: email ? email : undefined,
                birthday: birthday ? birthday : undefined,
                cpf: cpf ? parseInt(cpf.replace('-', '').replaceAll('.', '')) : undefined,
                gender: gender.id,
                cep: cep ? parseInt(cep.replace('-', '')) : undefined,
                addressCity: addressCity?.nome ? addressCity?.nome : undefined,
                addressState: addressState?.sigla ? addressState?.sigla : undefined,
                addressDistrict: addressDistrict ? addressDistrict : undefined,
                addressRoad: addressRoad ? addressRoad : undefined,
                addressNumber: addressNumber ? addressNumber : undefined,
                addressComplement: addressComplement ? addressComplement : undefined,
                dddmobile: parseInt(dddmobile),
                mobile: parseInt(removePontuaction(mobile)),
                dddphone: dddphone ? parseInt(dddphone) : undefined,
                phone: phone ? parseInt(removePontuaction(phone)) : undefined,
                observation: observation ? observation : undefined,
                addressState_externalId: addressState?.id ? addressState?.id.toString() : undefined,
                addressCity_externalId: addressCity?.id ? addressCity?.id?.toString() : undefined,
                microregion_externalId: addressCity?.microrregiao?.id ? addressCity?.microrregiao?.id.toString() : undefined,
                mesoregion_externalId: addressCity?.microrregiao?.mesorregiao?.id ? addressCity?.microrregiao?.mesorregiao?.id.toString() : undefined,
                isRemote: selectedIsRemote
            }, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Cliente cadastrado com sucesso!')
            setShowNotificationModalSuccess(true)


            defaultRoute ? navegate('/clients') : execFuncionRoute()
        } catch (error) {
            if (responseError(error).length > 0) {
                // setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                // setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setLoading(false)

    }

    async function getCityApi(data) {

        setLoading(true)

        try {

            const getCity = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data?.id}/municipios`)
            const getCityData = await getCity.json()

            setAddressCityList(getCityData)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const options = [
        {
            description: 'Sim',
            value: true
        },
        {
            description: 'Não',
            value: false
        },
    ]

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={'Cadastro de clientes'} />
                </div>
            </TitlePage>
            <Body hasFooter={true}>
                {loading ?

                    <div className="flex flex-col items-center justify-center w-[62rem] h-full">
                        <DefaultLoader />
                    </div>
                    :
                    <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                        <form className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                            <LabelInput text={'Nome *'}>
                                <Input charLimit={255} id='firstname' type='text' value={firstname} onChange={(e) => setFirstname(textMask(e.target.value))}></Input>
                            </LabelInput>
                            <LabelInput text={'Sobrenome'}>
                                <Input charLimit={255} id='lastname' type='text' value={lastname} onChange={(e) => setLastname(textMask(e.target.value))}></Input>
                            </LabelInput>
                            <LabelInput text={'E-mail'} valid={!emailValid ? false : true}>
                                <Input charLimit={255} id='email' type='e-mail' value={email} onChange={(e) => {
                                    const isValid = validateEmail(e.target.value)
                                    if (isValid === true) {
                                        setEmailValid(true)
                                    } else {
                                        setEmailValid(false)
                                    }
                                    setEmail(e.target.value)
                                }}>

                                </Input>
                            </LabelInput>
                            <LabelInput text={'Data de nascimento '}>
                                <Input id='birthday' value={birthday} type={'date'} onChange={(e) => setBirthday(e.target.value)}></Input>
                            </LabelInput>
                            <LabelInput text={'CPF *'}>
                                <Input charLimit={14} id='cpf' type='text' value={cpf} onChange={(e) => setCpf(mCPF(e.target.value))}></Input>
                            </LabelInput>
                            <LabelInput text={'Gênero'}>
                                <InputAutoComplete
                                    data={sexTypes}
                                    selectedLabel={'name'}
                                    optionList={['id', 'name']}
                                    placeHolder=' - '
                                    onChange={e => setGender(e)}
                                    preSelectedValue={gender ? gender.name : ''}
                                    id='user_gender_select'
                                    width={'318px'}
                                />
                            </LabelInput>
                            <LabelInput text={'CEP'}>
                                <Input type={'text'} value={maskCep(cep)} id='cep' onChange={(e) => setCep(e.target.value)} charLimit={9} />
                            </LabelInput>
                            <LabelInput text={'Estado *'}>
                                <InputAutoComplete
                                    preSelectedValue={addressState?.nome?.length > 0 ? addressState?.nome : ''}
                                    selectedLabel={'nome'} id='addressState'
                                    data={addressStateList} optionList={['nome']}
                                    onChange={e => {
                                        {
                                            setAddressState(e)
                                            e?.nome !== undefined && getCityApi(e)
                                        }
                                    }}>
                                </InputAutoComplete>
                            </LabelInput>
                            {
                                !addressState?.nome ?
                                    <LabelInput text={'Cidade *'}>
                                        <Input type={'text'} value={addressCity} id='addressCity' disabled={true} />
                                    </LabelInput>
                                    :
                                    <LabelInput text={'Cidade *'}>
                                        <InputAutoComplete
                                            preSelectedValue={addressCity ? addressCity?.nome : addressCityList?.nome}
                                            value={addressCity ? addressCity?.nome : addressCityList?.nome}
                                            selectedLabel={'nome'} id='addressCity'
                                            data={addressCityList} optionList={['nome']}
                                            onChange={e => {
                                                setAddressCity(e)
                                            }}>
                                        </InputAutoComplete>
                                    </LabelInput>
                            }
                            <LabelInput text={'Bairro'}>
                                <Input type={'text'} value={addressDistrict} onChange={e => setAddressDistrict(e.target.value)} />
                            </LabelInput>
                            <LabelInput text={'Logradouro'}>
                                <Input type={'text'} value={addressRoad} onChange={e => setAddressRoad(e.target.value)} />
                            </LabelInput>
                            <LabelInput text={'Número'}>
                                <Input type={'text'} id='addressNumber' value={addressNumber} onChange={(e) => setAddressNumber(justNumber(e.target.value))} charLimit={8} />
                            </LabelInput>
                            <LabelInput text={'Complemento'}>
                                <Input type={'text'} id='addressComplement' value={addressComplement} onChange={(e) => setAddressComplement(e.target.value)} charLimit={100} />
                            </LabelInput>
                            <div className='flex flex-row gap-2'>
                                <LabelInput text={'DDD *'}>
                                    <Input id={'dddMobile'} charLimit={2} width={'56px'} type={'text'} value={dddmobile} onChange={(e) => setDddmobile(justNumber(e.target.value))}></Input>
                                </LabelInput>
                                <LabelInput text={'Celular *'}>
                                    <Input id={'mobile'} charLimit={9} width={'255px'} type={'text'} value={mobile} onChange={(e) => setMobile(maskPhone(e.target.value))}></Input>
                                </LabelInput>
                            </div>
                            <div className='flex flex-row gap-2'>
                                <LabelInput text={'DDD'}>
                                    <Input width={'56px'} type={'text'} id='dddphone' value={dddphone} onChange={(e) => setDddphone(justNumber(e.target.value))} charLimit={2} />
                                </LabelInput>
                                <LabelInput text={'Telefone'}>
                                    <Input type={'text'} width={'255px'} value={phone} id='phone' onChange={(e) => setPhone(maskPhone(e.target.value))} charLimit={10} />
                                </LabelInput>
                            </div>
                            <div>
                                <LabelInput text={'Observação'}>
                                    <textarea className='outline-none border border-gray-400 border-opacity-75 rounded-lg w-80 h-36 lg:h-32 resize-none
                                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark
                                                    focus:border focus:border-primaryDefaultLight
                                                    dark:focus:border dark:focus:border-primaryDefaultLight p-2' maxLength={250} id='observation' onChange={e => setObservation(e.target.value)} />
                                </LabelInput>

                            </div>
                            <LabelInput text={'Atendimento online *'}>
                                <InputAutoComplete
                                    preSelectedValue={options?.find(item => item?.value == selectedIsRemote).description}
                                    value={selectedIsRemote ? options.find(item => item?.value == selectedIsRemote).description : ''}
                                    selectedLabel={'description'} 
                                    id={'selectedIsRemote'}
                                    data={options}
                                    optionList={['description']}
                                    onChange={e => {
                                        if (typeof(e) == 'object'){
                                            setSelectedIsRemote(e?.value)
                                        }
                                    }}>
                                </InputAutoComplete>
                            </LabelInput>
                        </form>
                    </div>
                }
            </Body >
            {
                !dontShowFooter &&
                <Footer>
                    <div className='flex lg:flex-row gap-3 w-full items-center h-full justify-between'>
                        <Button
                            approval={false}
                            shadow={true}
                            onClick={() => { defaultRoute ? navegate('/clients') : execFuncionRoute(); setShowModificationModal(false) }}
                        >Cancelar
                        </Button>
                        <Button
                            approval={true}
                            shadow={true}
                            onClick={() => handleSubmit()}
                        >+ Novo Cliente
                        </Button>
                    </div>
                </Footer>
            }

        </Container >
    )
}
