import React from 'react'

export function LabelInput({ text, required, children, flexRow , valid, id}) {

    return (
        <label className={`flex ${flexRow ? 'flex-row-reverse' : 'flex-col'} gap-1 justify-start`}>
            <a id={id} className={`text-sm ${valid === false? 'text-red-500':'text-inputPlaceholderLight'}`}>{text} {required ? '*' : ''}</a>
            {children}
        </label>
    )
}