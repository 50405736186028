import React, { useEffect, useState } from 'react'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'

export function NotificationModal({ children, isAuth, dontShow, onlyErrors }) {

    const { showNotificationModal, setShowModificationModal, showNotificationModalText, setShowNotificationModalText, showNotificationModalSuccess, setShowNotificationModalSuccess } = useThemeContext()

    function handleDisplay() {

        setShowModificationModal(false)
        setShowNotificationModalText('')
    }

    useEffect(() => {

        if (showNotificationModalSuccess) {
            awaitToHide()
        }

        async function awaitToHide() {
            await new Promise(resolve => setTimeout(resolve, 5000))
            handleDisplay()
        }

    }, [showNotificationModalSuccess])

    return (
        ((!dontShow && !onlyErrors) || (!dontShow && onlyErrors == true && showNotificationModalSuccess == false))
        ? 
            <div id='notificationModal' className={`${showNotificationModal ? 'opacity-90 ' : 'opacity-0 h-0 max-h-0 hidden'} py-1 transition-all duration-200  left-0 ${isAuth === undefined ? 'md:left-[54px]':  'md:left-0'} w-full top-0 absolute z-[999999999] ${showNotificationModalSuccess ? 'bg-primaryLightSuccess' : 'bg-red-800'} flex flex-row items-center px-4 md:px-10`}>
                <div className='text-center flex flex-row items-center justify-center w-[90%] overflow-auto '>
                    <h2 className='text-titleGrayTextLight text-sm'>{showNotificationModalText}</h2>
                </div>
                <button onClick={() => handleDisplay()} className='border border-titleGrayTextLight rounded-full h-8 w-8 flex flex-col items-center justify-center text-titleGrayTextLight hover:bg-titleGrayTextLight hover:text-titleBlackTextLight mr-0 md:mr-28'>x</button>
            </div>
        : <></>
    )
}
