export  function maskHours(hour){
    
    try {
        return hour?.hour?.toString().indexOf('.5') > 0 ? hour?.hour?.toString().replace('.5', ':30') : 
        hour?.hour?.toString().indexOf('.25') > 0 ? hour?.hour?.toString().replace('.25', ':15') : 
        hour?.hour?.toString().indexOf('.75') > 0 ? hour?.hour?.toString().replace('.75', ':45') : 
        hour?.hour?.toString() + ':00'
    } catch (error){
        return hour
    }
 }

 export function maskHoursForTable(hour){
    try {
        return hour?.toString().indexOf('.5') > 0 ? hour?.toString().replace('.5', ':30') : 
        hour?.toString().indexOf('.25') > 0 ? hour?.toString().replace('.25', ':15') : 
        hour?.toString().indexOf('.75') > 0 ? hour?.toString().replace('.75', ':45') : 
        hour?.toString() + ':00'
    }catch (error){
        return hour
    }
 }