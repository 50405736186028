export function changeThemeColor(colorTheme, color) {

    switch (colorTheme) {
        case 'primaryClientDefault':
            document.documentElement.style.setProperty('--primaryDefaultLight', color)
            localStorage.setItem('primaryDefaultLight', color)
            break;

        case 'secondaryClientDefault':
            document.documentElement.style.setProperty('--secondaryDefaultLight', color)
            localStorage.setItem('secondaryDefaultLight', color)
            break;

        case 'orange':
            document.documentElement.style.setProperty('--primaryDefaultLight', '#f97316')
            localStorage.setItem('primaryDefaultLight', '#f97316')

            document.documentElement.style.setProperty('--secondaryDefaultLight', '#c2410c')
            localStorage.setItem('secondaryDefaultLight', '#c2410c')
            break
        case 'red':
            document.documentElement.style.setProperty('--primaryDefaultLight', '#9a3412')
            localStorage.setItem('primaryDefaultLight', '#9a3412')

            document.documentElement.style.setProperty('--secondaryDefaultLight', '#7c2d12')
            localStorage.setItem('secondaryDefaultLight', '#7c2d12')
            break
        case 'green':
            document.documentElement.style.setProperty('--primaryDefaultLight', '#16a34a')
            localStorage.setItem('primaryDefaultLight', '#16a34a')

            document.documentElement.style.setProperty('--secondaryDefaultLight', '#166534')
            localStorage.setItem('secondaryDefaultLight', '#166534')
            break
        case 'emerald':
            document.documentElement.style.setProperty('--primaryDefaultLight', '#10b981')
            localStorage.setItem('primaryDefaultLight', '#10b981')

            document.documentElement.style.setProperty('--secondaryDefaultLight', '#047857')
            localStorage.setItem('secondaryDefaultLight', '#047857')
            break;
        case 'teal':
            document.documentElement.style.setProperty('--primaryDefaultLight', '#14b8a6')
            localStorage.setItem('primaryDefaultLight', '#14b8a6')

            document.documentElement.style.setProperty('--secondaryDefaultLight', '#0f766e')
            localStorage.setItem('secondaryDefaultLight', '#0f766e')
            break;
        case 'default':
            document.documentElement.style.setProperty('--primaryDefaultLight', '#00B0E9')
            localStorage.setItem('primaryDefaultLight', '#00B0E9')

            document.documentElement.style.setProperty('--secondaryDefaultLight', '#007FA7')
            localStorage.setItem('secondaryDefaultLight', '#007FA7')
            break
        case 'blue':
            document.documentElement.style.setProperty('--primaryDefaultLight', '#3b82f6')
            localStorage.setItem('primaryDefaultLight', '#3b82f6')

            document.documentElement.style.setProperty('--secondaryDefaultLight', '#1d4ed8')
            localStorage.setItem('secondaryDefaultLight', '#1d4ed8')
            break
        case 'indigo':
            document.documentElement.style.setProperty('--primaryDefaultLight', '#6366f1')
            localStorage.setItem('primaryDefaultLight', '#6366f1')

            document.documentElement.style.setProperty('--secondaryDefaultLight', '#4338ca')
            localStorage.setItem('secondaryDefaultLight', '#4338ca')
            break
        case 'purple':
            document.documentElement.style.setProperty('--primaryDefaultLight', '#c084fc')
            localStorage.setItem('primaryDefaultLight', '#c084fc')

            document.documentElement.style.setProperty('--secondaryDefaultLight', '#9333ea')
            localStorage.setItem('secondaryDefaultLight', '#9333ea')
            break
        case 'rose':
            document.documentElement.style.setProperty('--primaryDefaultLight', '#f43f5e')
            localStorage.setItem('primaryDefaultLight', '#f43f5e')

            document.documentElement.style.setProperty('--secondaryDefaultLight', '#be123c')
            localStorage.setItem('secondaryDefaultLight', '#be123c')
            break
        default:
            document.documentElement.style.setProperty('--primaryDefaultLight', '#00B0E9')
            localStorage.setItem('primaryDefaultLight', '#00B0E9')

            document.documentElement.style.setProperty('--secondaryDefaultLight', '#007FA7')
            localStorage.setItem('secondaryDefaultLight', '#007FA7')
            break
    }

}