import React, { useState } from 'react'
import styles from './styles/defaultLoader.module.css'

export function DefaultLoader() {
    return (
        <>

            {
                <div className={`${styles.ldsRoller}`}>
                    <div className='after:bg-titleBlackTextLight dark:after:bg-bgPrimaryLight'></div>
                    <div className='after:bg-titleBlackTextLight dark:after:bg-bgPrimaryLight'></div>
                    <div className='after:bg-titleBlackTextLight dark:after:bg-bgPrimaryLight'></div>
                    <div className='after:bg-titleBlackTextLight dark:after:bg-bgPrimaryLight'></div>
                    <div className='after:bg-titleBlackTextLight dark:after:bg-bgPrimaryLight'></div>
                    <div className='after:bg-titleBlackTextLight dark:after:bg-bgPrimaryLight'></div>
                    <div className='after:bg-titleBlackTextLight dark:after:bg-bgPrimaryLight'></div>
                    <div className='after:bg-titleBlackTextLight dark:after:bg-bgPrimaryLight'></div>

                </div>

            }

        </>
    )
}