//hooks
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
//context
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//components
import { Container } from '../../../components/container/container'
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Title } from '../../../components/titlePages/title'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { Footer } from '../../../components/footer/Footer'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { validateFields } from '../../../utils/form.validator'
import { validateEmail } from '../../../utils/validators/email.validator'
import { maskCep } from '../../../utils/validators/cepMask'
import { api } from '../../../services/api/api'
import { oabValidate } from '../../../utils/validators/oab.validator'
import { justNumber } from '../../../utils/validators/justNumber'
import { maskPhone } from '../../../utils/validators/phoneMask'
import { removePontuaction } from '../../../utils/validators/removePontuaction'
import { textMask } from '../../../utils/validators/textMask'

export function UsersPartnersCreate() {

    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [typeAccessList, setTypeAccessList] = useState([])
    //dados matriz
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [oab, setOab] = useState('')
    const [oabValid, setOabValid] = useState(true)
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(true)
    const [dddphone, setDddphone] = useState('')
    const [phone, setPhone] = useState('')
    const [dddmobile, setDddmobile] = useState('')
    const [mobile, setMobile] = useState('')
    const [typeAccess, setTypeAccess] = useState('')
    const [cep, setCep] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressStateList, setAddressStateList] = useState([])
    const [addressCityList, setAddressCityList] = useState([])
    const [addressCity, setAddressCity] = useState('')
    const [addressDistrict, setAddressDistrict] = useState('')
    const [addressRoad, setAddressRoad] = useState('')
    const [addressNumber, setAddressNumber] = useState(0)
    const [addressComplement, setAddressComplement] = useState('')
    const [gender, setGender] = useState('')
    const [birthday, setBirthday] = useState('')
    const [listPartnersMaster, setListPartnersMaster] = useState([])
    const [listPartners, setListPartners] = useState([])
    const [partners_id, setPartners_id] = useState('')
    //dados externos
    const [addressState_externalId, setAddressState_externalId] = useState('')
    const [addressCity_externalId, setAddressCity_externalId] = useState('')
    const [microregion_externalId, setMicroregion_externalId] = useState('')
    const [mesoregion_externalId, setMesoregion_externalId] = useState('')
    //dados filiais
    const [branch, setBranch] = useState({})
    const [branchRows, setBranchRows] = useState(Object.keys(branch))

    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        }
    ]

    useEffect(() => {

        async function getTypeAccess() {

            try {

                const getAccess = await api.get('/api/v1/typeAccess', {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('@auth:token')}`
                    }
                })

                const filterAccess = getAccess.data.data.filter(access => {
                    return access.id > 4
                })

                setTypeAccessList(filterAccess)
                setShowModificationModal(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }

        }

        getTypeAccess()

    }, [])

    useEffect(() => {

        async function getPartners() {


            try {

                const searchPartners = await api.get(`/api/v1/partners`, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('@auth:token')}`
                    }
                })

                const filterPartnersMaster = searchPartners?.data?.data.filter(e => {
                    return e?.isMaster === true
                })

                setListPartnersMaster(filterPartnersMaster)
                setListPartners(searchPartners.data?.data)
                setShowModificationModal(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getPartners()

    }, [])

    useEffect(() => {

        async function getStates() {

            setLoading(true)

            try {

                const getState = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
                const getStateData = await getState.json()

                setAddressStateList(getStateData.sort((a, b) => a.sigla > b.sigla ? 1 : -1))
                setLoading(false)

            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getStates()

    }, [])

    async function handleSubmit(e) {
       
        const requiredFields = [
            { name: 'firstname', value: firstname, required: true, type: 'string' },
            { name: 'lastname', value: lastname, required: true, type: 'string' },
            { name: 'typeAccess', value: typeAccess, required: true, type: 'string' },
            { name: 'email', value: email, required: true, type: 'string' },
            { name: 'addressState', value: addressState, required: true, type: 'string' },
            { name: 'addressCity', value: addressCity, required: true, type: 'string' }
        ]

        if (![7].includes(typeAccess?.id)){
            requiredFields.push({ name: 'partners_id', value: partners_id, required: true, type: 'string' })
        }

        if (validateFields(requiredFields)?.length > 0) {
            // setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (!emailValid) {
            // setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('E-mail inválido')
        }
        if (!oabValid) {
            // setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('OAB inválida')
        }

        if (typeAccess === '') {
            // setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Selecione um tipo de acesso')
        }

        try {

            await api.post('api/v1/users', {
                firstname: firstname ? firstname : undefined,
                lastname: lastname ? lastname : undefined,
                email: email ? email : undefined,
                birthday: birthday ? birthday : undefined,
                gender: gender ? gender?.id : undefined,
                oab: oab ? oab : undefined,
                typeAccess_id: parseInt(typeAccess.id),
                partners_id: partners_id ? partners_id : undefined,
                cep: cep ? parseInt(cep.replace('-', '')) : undefined,
                addressRoad: addressRoad ? addressRoad : undefined,
                addressNumber: addressNumber ? addressNumber : undefined,
                addressDistrict: addressDistrict ? addressDistrict : undefined,
                addressCity: addressCity?.nome ? addressCity?.nome : undefined,
                addressState: addressState?.sigla ? addressState?.sigla : undefined,
                addressComplement: addressComplement ? addressComplement : undefined,
                dddmobile: dddmobile ? dddmobile : undefined,
                mobile: mobile ? parseInt(removePontuaction(mobile)) : undefined,
                dddphone: dddphone ? dddphone : undefined,
                phone: phone ? parseInt(removePontuaction(phone)) : undefined,
                addressState_externalId: addressState?.id ? addressState?.id.toString() : undefined,
                addressCity_externalId: addressCity?.id ? addressCity?.id.toString() : undefined,
                microregion_externalId: addressCity?.microrregiao?.id ? addressCity?.microrregiao?.id.toString() : undefined,
                mesoregion_externalId: addressCity?.microrregiao?.mesorregiao?.id ? addressCity?.microrregiao?.mesorregiao?.id.toString() : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })

            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            if (typeAccess?.id == 7){
                navigate('/linkConsultant')
            } else {
                navigate('/usersPartners')
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        setLoading(false)

    }

    async function getCityApi(data) {

        setLoading(true)

        try {

            const getCity = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${data?.id}/municipios`)
            const getCityData = await getCity.json()

            setAddressCityList(getCityData)
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={'Cadastro de Usuários - Parceiros'} />
                </div>
            </TitlePage>
            <Body hasFooter={true}>
                <div className='flex flex-col items-start justify-start w-full'>
                    {
                        loading ?
                            <div className='flex flex-col w-full items-center justify-center'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                                    <form onSubmit={(e) => e.preventDefault()} className='flex flex-col items-center  lg:grid lg:grid-cols-3 2xl:grid-cols-3 w-full lg:w-auto lg:items-start justify-center gap-2'>

                                        <LabelInput text={'Nome *'}>
                                            <Input type={'text'} value={firstname} id='firstname' onChange={(e) => setFirstname(textMask(e.target.value))} charLimit={100} />
                                        </LabelInput>
                                        <LabelInput text={'Sobrenome *'}>
                                            <Input type={'text'} value={lastname} id='lastname' onChange={(e) => setLastname(textMask(e.target.value))} charLimit={100} />
                                        </LabelInput>
                                        <LabelInput text={'E-mail *'} valid={!emailValid ? false : true}>
                                            <Input type={'text'} value={email} charLimit={255} id='email' onChange={(e) => {
                                                const isValid = validateEmail(e.target.value)
                                                if (isValid === true) {
                                                    setEmailValid(true)
                                                } else {
                                                    setEmailValid(false)
                                                }
                                                setEmail((e.target.value))

                                            }}

                                            />
                                        </LabelInput>
                                        <LabelInput text={'Data de nascimento '}>
                                            <Input id='birthday' value={birthday} type={'date'} onChange={(e) => setBirthday(e.target.value)}></Input>
                                        </LabelInput>
                                        <LabelInput text={'Gênero'}>
                                            <InputAutoComplete
                                                data={sexTypes}
                                                selectedLabel={'name'}
                                                optionList={['id', 'name']}
                                                placeHolder=' - '
                                                onChange={e => setGender(e)}
                                                preSelectedValue={gender ? sexTypes.filter(sex => sex.id == gender.id)[0]?.name : ''}
                                                id='user_gender_select'
                                            />
                                        </LabelInput>
                                        <LabelInput text={'OAB'} valid={!oabValid ? false : true}>
                                            <Input type={'text'} id='oab' charLimit={8} value={oab} onChange={(e) => {
                                                const isValidOab = oabValidate(e.target.value)
                                                if (isValidOab === true) {
                                                    setOabValid(true)
                                                } else {
                                                    setOabValid(false)
                                                }
                                                setOab(e.target.value)
                                            }} />
                                        </LabelInput>
                                        <LabelInput text={'Tipo de acesso *'}>
                                            <InputAutoComplete
                                                data={typeAccessList}
                                                selectedLabel={'description'}
                                                optionList={['description']}
                                                onChange={typeAccess => setTypeAccess(typeAccess)}
                                                preSelectedValue={typeAccess?.description}
                                                id='typeAccess'
                                            />
                                        </LabelInput>
                                        {
                                            typeAccess.description === 'Matriz' ?
                                                <LabelInput text={'Selecione o parceiro *'}>
                                                    <InputAutoComplete
                                                        preSelectedValue={partners_id ? listPartnersMaster.filter(item => item?.id == partners_id)[0].name : ''}
                                                        selectedLabel={'name'} id='partners_id'
                                                        data={listPartnersMaster} optionList={['name']}
                                                        onChange={e => {
                                                            setPartners_id(e?.id)
                                                        }}>
                                                    </InputAutoComplete>
                                                </LabelInput>
                                                : typeAccess.description === 'Filial' ?
                                                    <LabelInput text={'Selecione o parceiro *'}>
                                                        <InputAutoComplete
                                                            preSelectedValue={partners_id ? listPartners.filter(item => item?.id == partners_id)[0].name : ''}
                                                            selectedLabel={'name'} id='partners_id'
                                                            data={listPartners} optionList={['name']}
                                                            onChange={e => {
                                                                setPartners_id(e?.id)
                                                            }}>
                                                        </InputAutoComplete>
                                                    </LabelInput>
                                                    :
                                                    <></>
                                        }
                                        <LabelInput text={'CEP'}>
                                            <Input type={'text'} value={maskCep(cep)} id='cep' onChange={(e) => setCep(e.target.value)} charLimit={9} />
                                        </LabelInput>
                                        <LabelInput text={'Estado *'}>
                                            <InputAutoComplete
                                                preSelectedValue={addressState?.nome?.length > 0 ? addressState?.nome : ''}
                                                selectedLabel={'nome'} id='addressState'
                                                autoComplete="off"

                                                data={addressStateList} optionList={['nome']}
                                                onChange={e => {
                                                    {
                                                        setAddressState(e)
                                                        e?.nome !== undefined && getCityApi(e)
                                                    }
                                                }}>
                                            </InputAutoComplete>
                                        </LabelInput>
                                        {
                                            !addressState?.nome ?
                                                <LabelInput text={'Cidade *'}>

                                                    <Input type={'text'} value={addressCity} autoComplete="off" id='addressCity' disabled={true} />
                                                </LabelInput>
                                                :
                                                <LabelInput text={'Cidade *'}>
                                                    <InputAutoComplete
                                                        preSelectedValue={addressCity ? addressCity?.nome : addressCityList?.nome}
                                                        selectedLabel={'nome'} id='addressCity'
                                                        data={addressCityList} optionList={['nome']}
                                                        onChange={e => {
                                                            setAddressCity(e)
                                                        }}>
                                                    </InputAutoComplete>
                                                </LabelInput>
                                        }
                                        <LabelInput text={'Bairro'}>
                                            <Input type={'text'} value={addressDistrict} onChange={e => setAddressDistrict(e.target.value)} />
                                        </LabelInput>
                                        <LabelInput text={'Logradouro'}>
                                            <Input type={'text'} value={addressRoad} onChange={e => setAddressRoad(e.target.value)} />
                                        </LabelInput>
                                        <LabelInput text={'Número'}>
                                            <Input type={'text'} id='addressNumber' value={addressNumber} onChange={(e) => setAddressNumber(justNumber(e.target.value))} charLimit={8} />
                                        </LabelInput>
                                        <LabelInput text={'Complemento'}>
                                            <Input type={'text'} value={addressComplement} onChange={(e) => setAddressComplement(e.target.value)} charLimit={100} />
                                        </LabelInput>
                                        <div className='flex flex-row gap-3'>
                                            <LabelInput text={'DDD'}>
                                                <Input charLimit={2} width={'50px'} type={'text'} value={dddmobile} onChange={(e) => setDddmobile(justNumber(e.target.value))}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Celular'}>
                                                <Input charLimit={9} width={'255px'} type={'text'} value={mobile} onChange={(e) => setMobile(maskPhone(e.target.value))}></Input>
                                            </LabelInput>
                                        </div>
                                        <div className='flex flex-row gap-3'>
                                            <LabelInput text={'DDD'}>
                                                <Input charLimit={2} width={'50px'} type={'text'} onChange={(e) => setDddphone(justNumber(e.target.value))} value={dddphone}></Input>
                                            </LabelInput>
                                            <LabelInput text={'Telefone'}>
                                                <Input charLimit={9} width={'255px'} type={'text'} onChange={(e) => setPhone(maskPhone(e.target.value))} value={phone}></Input>
                                            </LabelInput>
                                        </div>
                                    </form>
                                </div>
                            </>
                    }
                </div>
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button
                        id='cancelUserEdit'
                        approval={false}
                        shadow={true}
                        onClick={() => { navigate('/usersPartners'); setShowModificationModal(false) }}
                    >Voltar
                    </Button>
                    <Button
                        id='approvalUserEdit'
                        approval={true}
                        shadow={true}
                        onClick={() => handleSubmit()}
                    >Salvar
                    </Button>
                </div>
            </Footer>
        </Container>
    )
}