const listHours = [
    {id: 1, name: '01:00'}, 
    {id: 1.5, name: '01:30'}, 
    {id: 2, name: '02:00' }, 
    {id: 2.5, name: '02:30'}, 
    {id: 3, name: '03:00'}, 
    {id: 3.5, name: '03:30'}, 
    {id: 4, name: '04:00'}, 
    {id: 4.5, name: '04:30'}, 
    {id: 5, name: '05:00'}, 
    {id: 5.5, name: '05:30'}, 
    {id: 6, name: '06:00'}, 
    {id: 6.5, name: '06:30'}, 
    {id: 7, name: '07:00'}, 
    {id: 7.5, name: '07:30'}, 
    {id: 8, name: '08:00'}, 
    {id: 8.5, name: '08:30'}, 
    {id: 9, name: '09:00'}, 
    {id: 9.5, name: '09:30'}, 
    {id: 10, name: '10:00'}, 
    {id: 10.5, name: '10:30'}, 
    {id: 11, name: '11:00'}, 
    {id: 11.5, name: '11:30'}, 
    {id: 12, name: '12:00'}, 
    {id: 12.5, name: '12:30'}, 
    {id: 13, name: '13:00'}, 
    {id: 13.5, name: '13:30'}, 
    {id: 14, name: '14:00'}, 
    {id: 14.5, name: '14:30'}, 
    {id: 15, name: '15:00'}, 
    {id: 15.5, name: '15:30'}, 
    {id: 16, name: '16:00'}, 
    {id: 16.5, name: '16:30'}, 
    {id: 17, name: '17:00'}, 
    {id: 17.5, name: '17:30'}, 
    {id: 18, name: '18:00'}, 
    {id: 18.5, name: '18:30'}, 
    {id: 19, name: '19:00'}, 
    {id: 19.5, name: '19:30'}, 
    {id: 20, name: '20:00'}, 
    {id: 20.5, name: '20:30'}, 
    {id: 21, name: '21:00'}, 
    {id: 21.5, name: '21:30'}, 
    {id: 22, name: '22:00'}, 
    {id: 22.5, name: '22:30'}, 
    {id: 23, name: '23:00'}, 
    {id: 23.5, name: '23:30'}
]


export {
    listHours    
}