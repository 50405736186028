import React, { useEffect, useState } from 'react'
import { TooltipBars } from '../../components/toolTip/toolTipBars'
import ApexCharts from 'apexcharts';
import randomColor from 'randomcolor';
//contexts
import { useThemeContext } from '../../contexts/themeContext';

export function BarsGraph({ data, title, bgGradient, bgGradientFirstColor, bgGradientSecondColor, bgColor, }) {

    const { primaryDefaultLight, secondaryDefaultLight, primaryDefaultDarkColor, darkMode } = useThemeContext()

    useEffect(() => {

        const dataValue = data.map(apiData => apiData.value)
        const namesValue = data.map(apiData => apiData.name)

        var options = {
            series: [{
                name: 'Valor',
                data: dataValue
            }],
            chart: {
                height: 350,
                type: 'bar',
            },
            plotOptions: {
                bar: {

                    backgroundBarColors: ['#fb00b9'],
                    // color: primaryDefaultLight,
                    borderRadius: 5,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return  val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: darkMode ? ['#d1d5db'] : [primaryDefaultLight]
                }
            },

            xaxis: {
                categories: namesValue, 
                labels: {
                    style: {
                        colors: darkMode ? '#d1d5db' : primaryDefaultLight
                    }
                },
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: primaryDefaultLight,
                            colorTo: secondaryDefaultLight,
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }                        
                    }
                },
                tooltip: {
                    enabled: false,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                    formatter: function (val) {
                        return  val;
                    }
                }

            },
            colors: [primaryDefaultLight],
            title: {
                text: title,
                floating: true,
                offsetY: 330,
                align: 'center',
                style: {
                    color: darkMode ? '#4C75F5' : primaryDefaultLight
                }
            }
        };

        var chart = new ApexCharts(document.querySelector("#chart"), options);
        chart.render();

    }, [])

    return (
        <div id='chart'>

        </div>
    )

}
