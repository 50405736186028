import React from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { Login } from '../pages/security/login'
import { SignUp } from '../pages/signUp/signUp'

export function LoginRoutes() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/*' element={<Login />}/>
                <Route path='/' element={<Login />} />
            </Routes>
        </BrowserRouter>
    )
}