import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container } from "../../../components/container/container";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { Button } from "../../../components/buttons/button.default";
import { api } from "../../../services/api/api";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
import { Body } from "../../../components/container/Body";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { listHours } from "../../../utils/listHours";
import { LabelInput } from "../../../components/label/label.input"; 
import { Input } from "../../../components/input/input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { useScreenSizeContext } from "../../../contexts/screenSizeContext";
import { TablePaginationDefault } from "../../../components/table/tablePagination.default";
import { FaEdit } from "react-icons/fa";
import moment from "moment"
import { validateFields } from "../../../utils/form.validator";
import { ScreenModal } from "../../../components/modals/notification/screenModal";
import MultiselectEvents from '../../../components/selects/mult.select'

export function BlockScheduleManyPartners(){

    const { id } = useParams()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { screenX, screenY } = useScreenSizeContext()
    const [loading, setLoading] = useState(true)
    const [loadingMulti, setLoadingMulti] = useState(true)
    const [partnersList, setPartnersList] = useState([])
    const [selectedPartners, setSelectedPartners] = useState([])
    const [selectedDayOff, setSelectedDayOff] = useState('')
    const [selectedStartHour, setSelectedStartHour] = useState('')
    const [selectedFinishHour, setSelectedFinishHour] = useState('')
    const [selectedOptionPartners, setSelectedOptionPartners] = useState({ id: 1, description: 'Todas' })
    const [showModalSubmit, setShowModalSubmit] = useState(false)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        async function getPartners(){
            try {
                const getPartnersApi = await api.get(`/api/v1/partners`, {
                    headers: {
                        authorization: `Bearer ${userData.token}`
                    }
                })
                const partnersFilter = getPartnersApi?.data?.data?.map(filter => {
                    return {
                        ...filter,
                        inputLabel: `${filter?.id} - ${filter?.name}`
                    }
                })

                setPartnersList(partnersFilter)
                setSelectedPartners(partnersFilter)

                setLoading(false)
                setLoadingMulti(false)
            } catch (error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getPartners()
    }, [])

    useEffect(() => {
        if (!showUniversalModal && showModalSubmit){
            setShowModalSubmit(false)
        }
    }, [showUniversalModal])

    async function handleSubmit(){
        setLoading(true)
        
        const requiredFields = [
            { name: 'dateOff', value: selectedDayOff, required: true, type: 'string' },
            { name: 'startHour', value: selectedStartHour, required: true, type: 'object' },
            { name: 'finishHour', value: selectedFinishHour, required: true, type: 'object' },
        ]
        
        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (!selectedPartners || selectedPartners?.length == 0){
            setLoading(false)
            const partnersElement = document.getElementById('partnersLabel')
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            partnersElement.style.color = 'red'
            return setShowNotificationModalText('Campos obrigatórios não preenchidos')
        }

        setLoading(true)
        setShowUniversalModal(false)
    
        try {
            const createAPI = await api.post(`/api/v1/blockSchedule/createMany`, {
                dateOff: selectedDayOff,
                startHour: selectedStartHour?.id,
                finishHour: selectedFinishHour?.id,
                partners: selectedPartners?.map(item => { return item?.id })
            }, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })
            setErrors({
                quantityItems: createAPI?.data?.quantityItems,
                quantityItemsError: createAPI?.data?.quantityitemsError,
                errors: createAPI?.data?.errors
            })
            setSelectedDayOff('')
            setSelectedStartHour('')
            setSelectedFinishHour('')
            setSelectedPartners(partnersList)
            setSelectedOptionPartners(optionsPartners[0])
            setShowUniversalModal(true)
            setShowModalSubmit(true)
            setLoading(false)

        } catch (error){
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    const optionsPartners = [
        {
            id: 1,
            description: 'Todas'
        },
        {
            id: 2,
            description: 'Personalizado'
        }
    ]

    return (
        <>
            {
                showUniversalModal && showModalSubmit ?
                <ScreenModal title={'Bloqueios'}>
                    <div className="w-full flex flex-col">
                        <p className='flex flex-row justify-start items-start w-full p-2 text-sm dark:text-titleGrayTextDark font-semibold'>Total de envios: {errors?.quantityItems}</p>
                        <p className='flex flex-row justify-start items-start w-full p-2 text-sm text-red-600 dark:text-titleGrayTextDark font-semibold'>Total de erros: {errors?.quantityItemsError}</p>
                        <div className='w-96 p-2 h-80 max-h-96 gap-3 flex flex-col items-start justify-start overflow-auto'>
                            {
                                errors?.errors?.map(item => {
                                    return (
                                        <div className="flex flex-col text-sm">
                                            <p className="w-full border"></p>
                                            <p className="mt-1"><a className="text-base text-primaryDefaultLight">Parceiro:</a> {item?.partners_id}</p> 
                                            <p><a className="text-base text-primaryDefaultLight">Erro:</a> {item?.message}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='flex flex-row pb-3 mt-8 items-center justify-center'>
                            <Button shadow={true} onClick={() => { setShowUniversalModal(false); setShowModalSubmit(false)}}>Voltar</Button>
                        </div>
                    </div>
                </ScreenModal>
                :
                <ScreenModal title={'Adicionar Bloqueios'}>
                    <div className='w-full p-2 h-32 flex flex-col items-center justify-center'>
                        <p className='text-base text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente bloquear as agendas?</p>
                        <div className='flex flex-row gap-3 mt-10'>
                            <Button shadow={true} onClick={() => { handleSubmit() }}>Sim</Button>
                            <Button shadow={true} onClick={() => { setShowUniversalModal(false); setShowModalSubmit(false)}} approval={false}>Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            <Container>
                <TitlePage>
                    <div className='flex flex-row w-full justify-center sm:justify-start'>
                        <Title text={`Bloqueio de Agenda para Parceiros`}/>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <div className='flex flex-col w-full items-center justify-center'>
                            <DefaultLoader />
                        </div>
                        :
                        <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                            {
                                loadingMulti ?
                                <></>
                                :
                                <>
                                    <form className='grid grid-cols-1 md:grid-cols-2 gap-2 mb-4'>
                                        <LabelInput text={'Data de bloqueio *'}>
                                            <Input
                                                type={'date'}
                                                id={'dateOff'}
                                                value={selectedDayOff ? selectedDayOff : ''}
                                                onChange={(e) => {
                                                    setSelectedDayOff(e.target.value)
                                                }}
                                                width={'240px'}
                                            />
                                        </LabelInput>
                                        <div className="flex flex-col md:flex-row items-end ml-5 gap-3">
                                            <LabelInput>
                                                <InputAutoComplete width={screenX < 400 ? 64 : 24}
                                                    value={selectedStartHour ? selectedStartHour?.name : ''}
                                                    onChange={(e) => {setSelectedStartHour(e)}}
                                                    data={listHours} 
                                                    selectedLabel={'name'} 
                                                    optionList={['name']}
                                                    id={'startHour'}
                                                />
                                            </LabelInput>
                                            <p className="w-full items-center justify-center flex">às</p>
                                            <LabelInput>
                                                <InputAutoComplete 
                                                    width={screenX < 400 ? 64 : 24}
                                                    value={selectedFinishHour ? selectedFinishHour?.name : ''}
                                                    onChange={(e) => setSelectedFinishHour(e)}
                                                    data={listHours}
                                                    selectedLabel={'name'} 
                                                    optionList={['name']}
                                                    id={'finishHour'}
                                                />
                                            </LabelInput>
                                        </div>
                                    </form>
                                    <div className="mt-2 flex flex-col justify-center items-center sm:justify-start sm:items-start">
                                        <LabelInput text={'Selecionar'}>
                                            <InputAutoComplete
                                                width={60}                                      
                                                value={selectedOptionPartners?.description}
                                                onChange={(e) => {
                                                    if (e?.id == 1){
                                                        setSelectedPartners(partnersList)
                                                    }
                                                    setSelectedOptionPartners(e)
                                                }}
                                                data={optionsPartners}
                                                selectedLabel={'description'} 
                                                optionList={['description']}
                                                id={'selectedOptionPartners'}
                                            />
                                        </LabelInput>                                    
                                        <div className="mt-3">
                                            <LabelInput text={'Parceiros *'} id={'partnersLabel'}>
                                                <MultiselectEvents
                                                    key={'selectedPartners'}
                                                    items={partnersList}
                                                    mult 
                                                    disabled={selectedOptionPartners?.id == 1 ? true : false}
                                                    onChange={e => setSelectedPartners(e)}
                                                    selectedLabel={'inputLabel'}
                                                    value={selectedPartners ? selectedPartners : []}
                                                    id={`selectedPartners`}                                                    
                                                />
                                            </LabelInput>
                                        </div>
                                    </div>
                                    <div className="mt-5 h-full flex flex-col md:flex-row items-end justify-start gap-4">
                                        <Button onClick={() => setShowUniversalModal(true)}>Adicionar</Button>
                                    </div>
                                </>
                            }
                        </div>
                    }
                </Body>
            </Container>
        </>
    )
}