export const hourTime =
    [
        { 'hour': 0 }, { 'hour': 0.25 }, { 'hour': 0.5 }, { 'hour': 0.75 },
        { 'hour': 1 }, { 'hour': 1.25 }, { 'hour': 1.5 }, { 'hour': 1.75 },
        { 'hour': 2 }, { 'hour': 2.25 }, { 'hour': 2.5 }, { 'hour': 2.75 },
        { 'hour': 3 }, { 'hour': 3.25 }, { 'hour': 3.5 }, { 'hour': 3.75 },
        { 'hour': 4 }, { 'hour': 4.25 }, { 'hour': 4.5 }, { 'hour': 4.75 },
        { 'hour': 5 }, { 'hour': 5.25 }, { 'hour': 5.5 }, { 'hour': 5.75 },
        { 'hour': 6 }, { 'hour': 6.25 }, { 'hour': 6.5 }, { 'hour': 6.75 },
        { 'hour': 7 }, { 'hour': 7.25 }, { 'hour': 7.5 }, { 'hour': 7.75 },
        { 'hour': 8 }, { 'hour': 8.25 }, { 'hour': 8.5 }, { 'hour': 8.75 },
        { 'hour': 9 }, { 'hour': 9.25 }, { 'hour': 9.5 }, { 'hour': 9.75 },
        { 'hour': 10 }, { 'hour': 10.25 }, { 'hour': 10.5 }, { 'hour': 10.75 },
        { 'hour': 11 }, { 'hour': 11.25 }, { 'hour': 11.5 }, { 'hour': 11.75 },
        { 'hour': 12 }, { 'hour': 12.25 }, { 'hour': 12.5 }, { 'hour': 12.75 },
        { 'hour': 13 }, { 'hour': 13.25 }, { 'hour': 13.5 }, { 'hour': 13.75 },
        { 'hour': 14 }, { 'hour': 14.25 }, { 'hour': 14.5 }, { 'hour': 14.75 },
        { 'hour': 15 }, { 'hour': 15.25 }, { 'hour': 15.5 }, { 'hour': 15.75 },
        { 'hour': 16 }, { 'hour': 16.25 }, { 'hour': 16.5 }, { 'hour': 16.75 },
        { 'hour': 17 }, { 'hour': 17.25 }, { 'hour': 17.5 }, { 'hour': 17.75 },
        { 'hour': 18 }, { 'hour': 18.25 }, { 'hour': 18.5 }, { 'hour': 18.75 },
        { 'hour': 19 }, { 'hour': 19.25 }, { 'hour': 19.5 }, { 'hour': 19.75 },
        { 'hour': 20 }, { 'hour': 20.25 }, { 'hour': 20.5 }, { 'hour': 20.75 },
        { 'hour': 21 }, { 'hour': 21.25 }, { 'hour': 21.5 }, { 'hour': 21.75 },
        { 'hour': 22 }, { 'hour': 22.25 }, { 'hour': 22.5 }, { 'hour': 22.75 },
        { 'hour': 23 }, { 'hour': 23.25 }, { 'hour': 23.5 }, { 'hour': 23.75 }
    ]