import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container } from "../../../components/container/container";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Title } from "../../../components/titlePages/title";
import { Button } from "../../../components/buttons/button.default";
import { api } from "../../../services/api/api";
import { responseError } from "../../../utils/responsesFunctions/error.response";
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
import { Body } from "../../../components/container/Body";
import { DefaultLoader } from "../../../components/loaders/defaultLoader";
import { listHours } from "../../../utils/listHours";
import { LabelInput } from "../../../components/label/label.input"; 
import { Input } from "../../../components/input/input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import { useScreenSizeContext } from "../../../contexts/screenSizeContext";
import { TablePaginationDefault } from "../../../components/table/tablePagination.default";
import { FaEdit } from "react-icons/fa";
import moment from "moment"
import { validateFields } from "../../../utils/form.validator";
import { ScreenModal } from "../../../components/modals/notification/screenModal";

export function BlockSchedule(){

    const { id } = useParams()
    const navigate = useNavigate()
    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { screenX, screenY } = useScreenSizeContext()
    const [loading, setLoading] = useState(true)
    const [partnerData, setPartnerData] = useState('')
    const [selectedDayOff, setSelectedDayOff] = useState('')
    const [selectedStartHour, setSelectedStartHour] = useState('')
    const [selectedFinishHour, setSelectedFinishHour] = useState('')
    const [filter, setFilter] = useState({ partners_id: id })
    const [quantity, setQuantity] = useState(0)
    const [blockTemp, setBlockTemp] = useState('')
    const [deleteEdit, setDeleteEdit] = useState(false)

    useEffect(() => {
        setLoading(true)

        async function getPartner(){
            try {
                const getPartnersData = await api.get(`/api/v1/partners/${id}`, {
                    headers: {
                        authorization: `Bearer ${userData.token}`
                    }
                })
                setPartnerData(getPartnersData?.data?.data[0])
                setLoading(false)
            } catch (error){
                if (responseError(error).length > 0) {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    setShowNotificationModalSuccess(false)
                    setShowModificationModal(true)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        getPartner()
    }, [])

    useEffect(() => {
        if (!showUniversalModal){
            setBlockTemp('')
            setSelectedDayOff('')
            setSelectedStartHour('')
            setSelectedFinishHour('')
            setDeleteEdit(false)
            setLoading(false)
        }
    }, [showUniversalModal])

    async function handleEdit(){
        const requiredFields = [
            { name: 'dateOffEdit', value: blockTemp?.dateOff, required: true, type: 'string' },
            { name: 'startHourEdit', value: blockTemp?.startHour, required: true, type: 'number' },
            { name: 'finishHourEdit', value: blockTemp?.finishHour, required: true, type: 'number' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        setLoading(true)

        try {
            await api.put(`/api/v1/blockSchedule/${blockTemp?.id}`, {
                dateOff: moment(blockTemp?.dateOff).utc(false).format('YYYY-MM-DD'),
                startHour: blockTemp?.startHour,
                finishHour: blockTemp?.finishHour,
                partners_id: Number(id)
            }, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Bloqueio atualizado com sucesso')
            setShowNotificationModalSuccess(true)
            setShowUniversalModal(false)
        } catch (error){
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }                
    }

    async function handleSubmit(){
        setLoading(true)

        const requiredFields = [
            { name: 'dateOff', value: selectedDayOff, required: true, type: 'string' },
            { name: 'startHour', value: selectedStartHour, required: true, type: 'object' },
            { name: 'finishHour', value: selectedFinishHour, required: true, type: 'object' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.post(`/api/v1/blockSchedule`, {
                dateOff: selectedDayOff,
                startHour: selectedStartHour?.id,
                finishHour: selectedFinishHour?.id,
                partners_id: Number(id)
            }, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })

            setSelectedDayOff('')
            setSelectedStartHour('')
            setSelectedFinishHour('')
            setShowModificationModal(true)
            setShowNotificationModalText('Bloqueio criado com sucesso')
            setShowNotificationModalSuccess(true)
            setLoading(false)
        } catch (error){
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleDelete(){
        setLoading(true)

        try {
            await api.delete(`/api/v1/blockSchedule/${blockTemp?.id}`, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Bloqueio removido com sucesso')
            setShowNotificationModalSuccess(true)
            setShowUniversalModal(false)
        } catch (error){
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }                
    }

    function handleEditMobile(data){
        setBlockTemp(data)
        setShowUniversalModal(true)
    }

    return (
        <>
            <ScreenModal title={'Editar Bloqueio'}>
                <form className='flex flex-col items-center w-80 md:w-96 h-72 justify-center gap-1 p-8'>
                    <LabelInput text={'Data de bloqueio *'}>
                        <Input
                            type={'date'}
                            id={'dateOffEdit'}
                            value={blockTemp?.dateOff ? moment(blockTemp?.dateOff).utc(false).format('YYYY-MM-DD') : ''}                            
                            onChange={(e) => {
                                setBlockTemp(prev => ({...prev, dateOff: e.target.value}))
                            }}
                            width={'240px'}
                        />
                    </LabelInput>
                    <div className="h-full flex flex-col md:flex-row items-center justify-center gap-2">
                        <LabelInput>
                            <InputAutoComplete width={screenX < 400 ? 64 : 24}
                                value={blockTemp?.startHour ? listHours?.filter(hour => hour?.id == blockTemp?.startHour)[0]?.name : ''}
                                onChange={(e) => setBlockTemp(prev => ({...prev, startHour: e?.id}))}
                                data={listHours} 
                                selectedLabel={'name'} 
                                optionList={['name']}
                                id={'startHourEdit'}
                                height={96}
                            />
                        </LabelInput>
                        <p className="w-full items-center justify-center flex flex-col h-full">às</p>
                        <LabelInput>
                            <InputAutoComplete 
                                width={screenX < 400 ? 64 : 24}
                                value={blockTemp?.finishHour ? listHours?.filter(hour => hour?.id == blockTemp?.finishHour)[0]?.name : ''}
                                onChange={(e) => setBlockTemp(prev => ({...prev, finishHour: e?.id}))}
                                data={listHours}
                                selectedLabel={'name'} 
                                optionList={['name']}
                                id={'finishHourEdit'}
                                height={96}
                            />
                        </LabelInput>
                    </div>
                    {
                        deleteEdit ?
                        <div className="mt-0 w-full flex flex-col gap-2">
                            <p className="text-center text-sm">Deseja realmente excluir o bloqueio?</p>
                            <div className="w-full flex flex-row justify-center gap-4">
                                <Button approval={false} onClick={() => handleDelete()}>Sim</Button>
                                <Button onClick={() => setDeleteEdit(false)}>Não</Button>
                            </div>
                        </div>
                        :
                        <div className="mt-5 w-full flex flex-row justify-center gap-4">
                            <Button approval={false} onClick={() => {setDeleteEdit(true)}}>Excluir</Button>
                            <Button onClick={() => handleEdit()}>Editar</Button>
                        </div>
                    }
                </form>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className='flex flex-row w-full justify-between'>
                        <div className="sm:hidden">
                            <Title text={`Bloqueio de Agenda - ${partnerData?.name?.slice(0, 15)}...`}></Title>
                        </div>
                        <div className="hidden sm:flex">
                            <Title text={`Bloqueio de Agenda - ${partnerData?.name}`}></Title>
                        </div>
                        <Button approval={false} onClick={() => navigate(`/partnersEdit/${id}`)}>Voltar</Button>
                    </div>
                </TitlePage>
                <Body>
                    {
                        loading ?
                        <div className='flex flex-col w-full items-center justify-center'>
                            <DefaultLoader />
                        </div>
                        :
                        <>
                            <div className='flex flex-col lg:items-start items-center justify-start w-full mb-5'>
                                <form className='flex flex-col md:flex-row items-center w-full md:items-start justify-center md:justify-start gap-2'>
                                    <LabelInput text={'Data de bloqueio *'}>
                                        <Input
                                            type={'date'}
                                            id={'dateOff'}
                                            value={selectedDayOff ? selectedDayOff : ''}
                                            onChange={(e) => {
                                                setSelectedDayOff(e.target.value)
                                            }}
                                            width={'240px'}
                                        />
                                    </LabelInput>
                                    <div className="h-full flex flex-col md:flex-row items-end justify-end gap-4 md:ml-3">
                                        <LabelInput>
                                            <InputAutoComplete width={screenX < 400 ? 64 : 24}
                                                value={selectedStartHour ? selectedStartHour?.name : ''}
                                                onChange={(e) => {setSelectedStartHour(e)}}
                                                data={listHours} 
                                                selectedLabel={'name'} 
                                                optionList={['name']}
                                                id={'startHour'}
                                            />
                                        </LabelInput>
                                        <p className="w-full items-center justify-center flex">às</p>
                                        <LabelInput>
                                            <InputAutoComplete 
                                                width={screenX < 400 ? 64 : 24}
                                                value={selectedFinishHour ? selectedFinishHour?.name : ''}
                                                onChange={(e) => setSelectedFinishHour(e)}
                                                data={listHours}
                                                selectedLabel={'name'} 
                                                optionList={['name']}
                                                id={'finishHour'}
                                            />
                                        </LabelInput>
                                    </div>
                                    <div className="mt-5 md:ml-4 md:mt-0 h-full flex flex-col md:flex-row items-end justify-center gap-4 md:pb-[0.2rem]">
                                        <Button onClick={() => handleSubmit()}>Adicionar</Button>
                                    </div>
                                </form>
                            </div>
                            <TablePaginationDefault
                                onClick={(e) => {e.func ? e.func.execute(e.data) : setBlockTemp(e); setShowUniversalModal(true)}}
                                quantity={(e) => setQuantity(e)}
                                filters={filter}
                                apiUrl={`/api/v1/blockSchedule`}
                                mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEditMobile }]}
                                collumns={[
                                    {
                                        name: 'dateOff',
                                        type: 'string',
                                        description: 'Data Bloqueio',
                                        func: (item) => {
                                            return moment(item?.dateOff).utc(false).format('DD/MM/YYYY')
                                        }
                                    },
                                    {
                                        name: 'startHour',
                                        type: 'number',
                                        func: (item) => {
                                            const formatStart = listHours?.filter(hour => hour?.id == item?.startHour)[0]?.name
                                            const formatFinish = listHours?.filter(hour => hour?.id == item?.finishHour)[0]?.name
                                            return `${formatStart} às ${formatFinish}`
                                        },
                                        description: 'Horário'
                                    },
                                    {
                                        name: 'created_at',
                                        type: 'date',
                                        description: 'Criado em'
                                    }
                                ]}
                            />                                
                        </>
                    }
                </Body>
            </Container>
        </>
    )
}