import React, { useContext, createContext, useState, useEffect } from 'react'
import { api } from '../services/api/api'

//services
import { getPermissionGroupEspecific, isAuth } from '../services/api/callAPIsFunctions/defaultCalls.api'

const AuthContext = createContext()

export function AuthContextProvider({ children }) {


    const [signed, setSigned] = useState(false)
    const [userData, setUserData] = useState('')
    const [reloadData, setReloadData] = useState(false)
    const [loadingUniversal, setLoadingUniversal] = useState(false)
    const userToken = localStorage.getItem('@auth:token')

    async function getPic(user) {

        try {
            const response = await api.get(`/api/v1/users/photo/filename/${user}`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    Authorization: 'Bearer ' + userToken
                }
            })
            return URL.createObjectURL(response.data)

        } catch (error) {
            console.log(error)
        }
    }

    //funcao para verificar se esta logado ajustar para o sistema corrente
    async function isValidToken() {
        setLoadingUniversal(true)
        try {

            const verifyToken = await api.get('/auth/verify', {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            if (verifyToken.data.userData.photo) {
                verifyToken.data.userData.photoFile = await getPic(verifyToken.data.userData.photo)
            }
            if (verifyToken.data.status === 200) {
                setSigned(true)
                let newUserData = verifyToken.data.userData
                newUserData.token = verifyToken.data.token
                setUserData(newUserData)
                setLoadingUniversal(false)

            }

        } catch (error) {
            setLoadingUniversal(false)
        }
    }

    useEffect(() => {
        isValidToken()
    }, [])

    return (
        <AuthContext.Provider value={{
            signed,
            setSigned,
            userData,
            setUserData,
            isValidToken,
            reloadData,
            setReloadData,
            loadingUniversal
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}