import React, { useEffect, useState } from "react";
//components
import { Container } from "../../components/container/container";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { Input } from "../../components/input/input";
import { DefaultLoader } from "../../components/loaders/defaultLoader";
import { Button } from "../../components/buttons/button.default";
import { CalendarMain } from "../../components/calendar/calendar.main";
import { NotificationModal } from "../../components/modals/notification/notification.modal";
//utils
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import { LabelInput } from "../../components/label/label.input";
import { responseError } from "../../utils/responsesFunctions/error.response";
import { getLinkedOperatorToSupervisor, getPartnersHours, getPartnersLinkedForUser, getPartnersWithLinkedFromSupervisor } from "../../services/api/callAPIsFunctions/defaultCalls.api";
//contexts
import { useAuth } from "../../contexts/useAuth";
import { useScreenSizeContext } from "../../contexts/screenSizeContext";
import { hourTime } from "../../components/calendar/hourTime";
import { useThemeContext } from "../../contexts/themeContext";
//serivces
import { api } from "../../services/api/api";
import { maskHours } from "../../utils/mask/maskHours";
import { permittedFiles } from "../../utils/filesValidadtor/permittedFiles";
import { FaEdit, FaFilter, FaSave } from "react-icons/fa";
import { ClientsCreateComponent } from "../configuration/clients/clients.create_component";
import { listHours } from "../../utils/listHours";
import { TablePaginationDefault } from "../../components/table/tablePagination.default";
import { mCPF } from "../../utils/validators/cpfMask";
import { justNumber } from "../../utils/validators/justNumber";
import { FiSlash } from "react-icons/fi";
import { validateFields } from "../../utils/form.validator";

export function OperatorScheduleCreate({ selectedDate }) {

    const { userData, setReloadData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal, showUniversalModal } = useThemeContext()
    const { screenX, screenY } = useScreenSizeContext()

    const [loading, setLoading] = useState(true)
    const [showAnimate, setShowAnimate] = useState(false)
    const [createNewClient, setCreateNewClient] = useState(false)

    const [value, setValue] = useState(moment().locale('pt-br'))
    const startDay = value.clone().startOf('month').startOf('week')
    const endDay = value.clone().endOf('month').endOf('week')

    const [searchPartners, setSearchPartners] = useState(false)
    const [clientList, setClientList] = useState([])
    const [partnerList, setPartnerList] = useState([])
    const [filteredPartners, setFilteredPartners] = useState([])
    const [partnersHours, setPartnersHours] = useState([])
    const [partnersFinishHours, setPartnersFinishHours] = useState([])
    const [addressStatePartnersList, setAddressStatePartnersList] = useState([])
    const [filteredAddressStatePartnersList, setFilteredAddressStatePartnersList] = useState([])
    const [addressCity, setAddressCities] = useState([])
    const [filteredAddressCity, setFilteredAddressCities] = useState([])
    const [completeHours, setCompleteHours] = useState([])
    const [formattedHours, setFormattedHours] = useState([])
    const [hoursEnd, setHoursEnd] = useState([])
    const [scheduleHoursFilter, setScheduleHoursFilter] = useState([])
    const [tagsList, setTagsList] = useState([])
    const [confirmationList, setConfirmationList] = useState([])

    const [selectedClient, setSelectedClient] = useState([])
    const [selectedPartner, setSelectedPartner] = useState([])
    const [selectedAddressState, setSelectedAddressState] = useState([])
    const [selectedAddressCity, setSelectedAddressCity] = useState([])
    const [selectedDay, setSelectedDay] = useState('')
    const [selectedStartHour, setSelectedStartHour] = useState(0)
    const [selectedFinishHour, setSelectedFinishHour] = useState(0)
    const [selectedObservation, setSelectedObservation] = useState(``)
    const [partnerPreSelected, setPartnerPreSelected] = useState()
    const [hoursCompleteList, setHoursCompleteList] = useState([])
    const [isAutoFinishHour, setIsAutoFinishHour] = useState(false)
    const [loadingHoursAndCalendar, setLoadingHoursAndCalendar] = useState(false)
    const [selectedTag, setSelectedTag] = useState('')
    const [selectedConfirmation, setSelectedConfirmation] = useState('')

    const [scheduleAttacments, setScheduleAttachments] = useState([])
    const [blockData, setBlockData] = useState('')
    const [existsBlock, setExistsBlock] = useState(false)

    const [showModalClientsList, setShowModalClientsList] = useState(false)
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState({})
    const [quantity, setQuantity] = useState(0)
    const [filterClients, setFilterClients] = useState(false)

    const [labels, setLabels] = useState([])
    const [selectedLabels, setSelectedLabels] = useState([])
    const [selectedLabelDescription, setSelectedLabelDescription] = useState('')

    async function getData() {
        try {

            setLoading(true)

            let partnerListData = await getPartnersHours(userData.token, true)

            const getTagsAPI = await api.get('/api/v1/tags?active=true', {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setTagsList(getTagsAPI?.data?.data?.map(item => {
                return {
                    ...item,
                    inputLabel: `${item?.id} - ${item?.description}`
                }
            }))

            const getConfirmationAPI = await api.get('/api/v1/confirmation?active=true', {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setConfirmationList(getConfirmationAPI?.data?.data.map(item => {
                return {
                    ...item,
                    inputLabel: `${item?.id} - ${item?.description}`
                }
            }))

            const getLabelsAPI = await api.get('/api/v1/labels?active=true', {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            const formatLabels = getLabelsAPI?.data?.data.map(item => {
                return {
                    ...item,
                    inputLabel: `#${item?.description}`
                }
            })
            setLabels(formatLabels)

            // REGRA PARA SUPERVISORES VINCULADOS COM PARCEIROS
            if ([2].includes(userData?.typeAccess_id)) {

                let idsPartnersLink = []

                const partnersLinked = await getPartnersLinkedForUser(userData.token, userData.id)
                partnersLinked.data.filter(partner => { return idsPartnersLink.push(partner?.id) })

                if (partnersLinked.data.length > 0) {

                    let filteredPartnersList = []

                    partnerListData.data.map(partner => {
                        if ((idsPartnersLink.includes(partner?.id)) || (idsPartnersLink.includes(partner?.master_id))) {
                            filteredPartnersList.push(partner?.id)
                        }
                    })

                    partnerListData.data = partnerListData.data.filter(partner => {
                        if (filteredPartnersList.includes(partner?.id)) {
                            return partner
                        }
                    })

                }
            }

            // REGRA PARA OPERADOR COM VINCULO NO SUPERVISOR, PARA PEGAR EMPRESA COM BASE NO SUPERVISOR
            if ([3].includes(userData.typeAccess_id)) {

                const linkedSupervisorAPI = await getLinkedOperatorToSupervisor(userData.token, userData.id)

                if (linkedSupervisorAPI.data.length > 0) {
                    let idsPartnersLink = []

                    const partnersToSupervisor = await getPartnersWithLinkedFromSupervisor(userData.token)
                    partnersToSupervisor.data.map(partner => {
                        idsPartnersLink.push(partner?.id)
                    })

                    partnerListData.data = partnerListData.data.filter(partner => {
                        if (idsPartnersLink.includes(partner?.id)) {
                            return partner
                        }
                    })
                }
            }

            setPartnerList(partnerListData.data)

            let stateArr = []
            let statesIncluded = []
            let citiesArr = []
            let citiesIncluded = []
            partnerListData.data.map(partner => {

                if (!statesIncluded.includes(partner.addressState)) {
                    stateArr.push({ addressState_externalId: partner.addressState_externalId, addressState: partner.addressState })
                    statesIncluded.push(partner.addressState)
                }
                if (!citiesIncluded.includes(partner.addressCity_externalId) && partner.addressState_externalId.slice(0, 2) === partner.addressState_externalId) {
                    citiesArr.push({ addressCity_externalId: partner.addressCity_externalId, addressCity: partner.addressCity })
                    citiesIncluded.push(partner.addressCity_externalId)
                }
            })
            setAddressStatePartnersList(stateArr.sort((a, b) => a.addressState_externalId - b.addressState_externalId))
            setFilteredAddressStatePartnersList(stateArr.sort((a, b) => a.addressState_externalId - b.addressState_externalId))
            setAddressCities(citiesArr.sort((a, b) => a.addressCity_externalId - b.addressCity_externalId))
            setFilteredAddressCities(citiesArr.sort((a, b) => a.addressCity_externalId - b.addressCity_externalId))

            const day = startDay.clone().subtract(1, 'day')
            const a = []
            while (day.isBefore(endDay, "day")) {
                a.push(
                    Array(7).fill(0).map(() => day.add(1, "day").clone())
                )
            }
            let thisDay = 0
            a.map((week) => {
                week.map((day) => {
                    if (day.date() === moment().date() && day.month() === moment().month() && day.year() === moment().year()) {
                        setSelectedDay(day)
                        thisDay = day
                    }
                })
            })
            if (selectedDate) {
                setSelectedDay(selectedDate)
            } else {
                setSelectedDay(thisDay)
            }
            setLoading(false)

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    useEffect(() => {
        function cleanState() {
            setSelectedAddressCity('')
            setSelectedAddressState('')
            setSelectedClient('')
            setSelectedObservation('')
            setSelectedStartHour('')
            setSelectedFinishHour('')
            setSelectedPartner('')
            setShowModalClientsList(false)
            setFilterClients(false)
            setSelectedTag('')
            setSelectedConfirmation('')
            setExistsBlock(false)
            setBlockData('')
        }

        cleanState()
    }, [showUniversalModal])

    async function getHoursForThisPartner(partner, blockData) {

        setHoursCompleteList(partner?.hours)
        let hoursToFilter = []
        partner?.hours?.filter((hoursOfPartner) => {
            if (parseInt(selectedDay.clone().format('d')) === hoursOfPartner?.weekday) {
                hoursToFilter.push({
                    partner_id: partner?.id,
                    startHour: hoursOfPartner?.startHour,
                    finishHour: hoursOfPartner?.finishHour
                })
            }
        })
        let hoursFinishPermitted = []
        let hoursStartPermitted = []

        hoursToFilter?.filter((hour, index) => {
            completeHours.filter((hourToValid) => {
                if (hourToValid?.hour < hour?.finishHour && hourToValid?.hour >= hour?.startHour) {
                    hoursStartPermitted.push({ ...hourToValid, id: index })
                }

                if (hourToValid?.hour <= hour?.finishHour && hourToValid?.hour >= hour?.startHour) {
                    hoursFinishPermitted.push({ ...hourToValid, maxFinishHour: hour?.finishHour, id: index })
                }
            })
        })

        const getSchedulesOfPartner = await api.get(`/api/v1/schedule/partner/${partner?.id}/${moment(selectedDay).utc(false).format('YYYY-MM-DD')}`, {
            headers: {
                Authorization: `Bearer ${userData.token}`
            }
        })

        if (getSchedulesOfPartner.data.data?.length > 0){
            const getHoursOfPartner = getSchedulesOfPartner.data.data?.map(hours => {
                return {
                    startHour: hours?.startHour,
                    finishHour: hours?.finishHour
                }
            })
    
            let filterFreeHours = structuredClone(hoursStartPermitted)
            for (const schedule of getHoursOfPartner) {
                const { startHour, finishHour } = schedule
                filterFreeHours = filterFreeHours?.filter(hour => hour?.hour < startHour || hour?.hour >= finishHour)
            }

            hoursStartPermitted = hoursStartPermitted.map(hours => {
                const data = filterFreeHours.filter(hours2 => hours2?.hour == hours?.hour)[0]
                if (data) {
                    return { 
                        ...hours,
                        disabled: false
                    }
                }
                return {
                   ...hours,
                   disabled: true
                }
            })
            setScheduleHoursFilter(getHoursOfPartner)
        }

        setPartnersHours(hoursToFilter)
        if (hoursToFilter?.length > 0) {
            setPartnersFinishHours(hoursFinishPermitted)
            if (blockData){
                const hoursUnavailable = []
                hourTime?.map(hour => {
                    if (hour.hour >= blockData?.startHour && hour.hour < blockData?.finishHour){
                        hoursUnavailable.push(hour.hour)
                    }
                })
                hoursStartPermitted = hoursStartPermitted?.filter(hour =>  !hoursUnavailable.includes(hour?.hour))
            }
            setFormattedHours(hoursStartPermitted)
        } else {
            setSelectedStartHour(``)
            setPartnersFinishHours([])
            setFormattedHours([])
        }
    }

    function handleFinishHour() {

        let filterHoursForFinish = partnersFinishHours?.filter((hour) => {
            if ((hour?.hour > selectedStartHour?.hour && hour?.hour <= hour?.maxFinishHour && selectedStartHour?.id === hour?.id)) {
                return hour
            }
        })

        if (scheduleHoursFilter.length > 0){
            let filterFreeHours = structuredClone(filterHoursForFinish)
            for (const schedule of scheduleHoursFilter) {
                const { startHour, finishHour } = schedule
                filterFreeHours = filterFreeHours?.filter(hour => hour?.hour <= startHour || hour?.hour > finishHour)
            }
    
            filterHoursForFinish = filterHoursForFinish.map(hours => {
                const data = filterFreeHours?.filter(hours2 => hours2?.hour == hours?.hour)[0]
                if (data) {
                    return { 
                        ...hours,
                        disabled: false
                    }
                }
                return {
                   ...hours,
                   disabled: true
                }
            })
        }
        if (blockData){
            const hoursUnavailable = []
            hourTime?.map(hour => {
                if (hour.hour >= blockData?.startHour && hour.hour <= blockData?.finishHour){
                    hoursUnavailable.push(hour.hour)
                }
            })
            filterHoursForFinish = filterHoursForFinish?.filter(hour =>  !hoursUnavailable.includes(hour?.hour))
        }
        setHoursEnd(filterHoursForFinish)
    }

    async function handleSubmit() {
        setLoading(true)
        animateLoading(true)

        const requiredFields = [
            { name: 'selectedTag', value: selectedTag, required: true, type: 'object' },
            { name: 'selectedConfirmation', value: selectedConfirmation, required: true, type: 'object' },
        ]

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            animateLoading(true)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        let newEvent = {
            status: 0,
            weekday: selectedDay.clone().format('d'),
            scheduleDate: selectedDay.clone().format("YYYY-MM-DD"),
            startHour: selectedStartHour?.hour,
            finishHour: selectedFinishHour.hour,
            partners_id: partnerPreSelected ? partnerPreSelected?.id : selectedPartner.id,
            clients_id: selectedClient.id,
            observation: selectedObservation ? selectedObservation : undefined,
            tags_id: selectedTag ? selectedTag?.id : undefined,
            confirmation_id: selectedConfirmation ? selectedConfirmation?.id : undefined
        }
        try {
            const schedule = await api.post(`/api/v1/schedule`,
                newEvent, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            const scheduleId = schedule.data.data[0].id
            if (scheduleAttacments?.length > 0) {
                await handleAttchamentFile(scheduleId)
            }

            try {
                if (selectedLabels?.length > 0){
                    await api.post('/api/v1/scheduleLabels', {
                        schedule_id: scheduleId,
                        labels: selectedLabels?.map(item => item?.id)
                    }, {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    })
                }
            } catch(error){
                console.log('Erro ao vincular etiquetas', error)
            }

            setShowUniversalModal(false)
            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            setReloadData(true)
            setLoading(false)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                animateLoading(true)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                let message = responseError(error)
                const indexStart = responseError(error).indexOf("startHour")
                const indexFinish = responseError(error).indexOf("finishHour")
                if (indexStart > -1) {
                    message = message.replace('startHour', 'Hora Inicial')
                } 
                if (indexFinish > -1){
                    message = message.replace('finishHour', 'Hora Fim')
                }
                return setShowNotificationModalText(message)
            } else {
                setLoading(false)
                animateLoading(true)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    //funcao para verificar os parceiros que atendem na mesoregiao do cliente selecionado
    function filterMesoregion(client) {
        
        const filtered = partnerList?.filter(partner => {
            if (partner?.addressCity_externalId === client?.addressCity_externalId) {
                setPartnerPreSelected(partner)
            }
            if (partner?.mesoregion_externalId === client?.mesoregion_externalId) {
                return partner
            }
        })
        if (filtered?.length === 0) {
            setSelectedAddressCity('')
            setSelectedAddressState('')
            setSelectedPartner('')
            setSearchPartners(true)
        }
        
        setFilteredPartners(filtered)
    }

    //funcao que filtra os parceiros do estado selecionado pelo usuario
    async function filterPartnerByState(state) {
        
        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 30))

        setSelectedAddressState(state)
        let filteredPartnersState = []
        let filteredPartnersCities = []
        let citiesIncluded = []

        partnerList.filter(partner => {
            if (partner.addressState_externalId === state.addressState_externalId) {
                filteredPartnersState.push(partner)
            }
            addressCity.map(city => {
                if (!citiesIncluded.includes(city.addressCity_externalId) && (city.addressCity_externalId.slice(0, 2) === state.addressState_externalId)) {
                    filteredPartnersCities.push(city)
                    citiesIncluded.push(city.addressCity_externalId)
                }
            })
        })
        const city = filteredPartnersCities.filter(city => {
            if (filteredPartnersState[0].addressCity_externalId === city.addressCity_externalId) {
                return city
            }
        })
        city.push({ addressCity: `Todos`, addressCity_externalId: 0 })
        setFilteredPartners(filteredPartnersState)
        setSelectedPartner('')
        setPartnerPreSelected('')
        setSelectedAddressCity('')
        setFilteredAddressCities(filteredPartnersCities)
        setLoading(false)

    }

    //funcao que filtra os parceiros da cidade selecionada pelo usuario
    async function filterPartnerByCity(e) {

        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 30))
        if (e.addressCity == 'Todos' && e.addressCity_externalId == 0) {

            setSelectedAddressCity(e)
            let filteredPartnersState = []
            let filteredPartnersCities = []
            let citiesIncluded = []

            partnerList.filter(partner => {
                if (partner.addressState_externalId === selectedAddressState.addressState_externalId) {
                    filteredPartnersState.push(partner)
                }
                addressCity.map(city => {
                    if (!citiesIncluded.includes(city.addressCity_externalId) && (city.addressCity_externalId.slice(0, 2) === selectedAddressState.addressState_externalId)) {
                        filteredPartnersCities.push(city)
                        citiesIncluded.push(city.addressCity_externalId)
                    }
                })
            })
            const city = filteredPartnersCities.filter(city => {
                if (filteredPartnersState[0].addressCity_externalId === city.addressCity_externalId) {
                    return city
                }
            })

            setFilteredPartners(filteredPartnersState)
            setSelectedPartner('')
            setPartnerPreSelected('')
            setFilteredAddressCities(filteredPartnersCities)
            setLoading(false)
        } else {
            const filteredPartners = partnerList.filter(partner => {
                if (partner.addressCity_externalId === e.addressCity_externalId) {
                    return partner
                }
            })
            setFilteredPartners(filteredPartners)
            setSelectedPartner('')
            setPartnerPreSelected('')
            setSelectedAddressCity(e)
            setLoading(false)

        }

    }

    //funcao que filtra a cidade com base no paraceiro selecionado pelo usuario
    function filterCityByPartner(partner) {
        setSelectedPartner(partner)
        setSelectedStartHour('')
        setSelectedFinishHour('')

        const city = addressCity.filter(city => city.addressCity_externalId === partner.addressCity_externalId)
        setSelectedAddressCity(city[0])
    }

    //funcao que filtra as cidades e estados com os parceiros na regiao do cliente
    function selectCityAndStateByClient(client) {
        let partnerFinded = false
        let partnerWithoutCity = false

        partnerList?.map(partner => {
            if (!partnerFinded && partner?.addressState_externalId === client?.addressState_externalId && partner?.addressCity_externalId === client?.addressCity_externalId) {
                const state = addressStatePartnersList?.filter(state => state?.addressState_externalId === partner?.addressState_externalId)[0]
                const city = addressCity?.filter(city => city?.addressCity_externalId === partner?.addressCity_externalId)
                const cities = addressCity?.filter(city => city?.addressCity_externalId.slice(0, 2) === client?.addressState_externalId)

                setSelectedAddressState(state)
                setSelectedAddressCity(city[0])
                setFilteredAddressCities(cities)
                setSelectedPartner(partner)
                setPartnerPreSelected('')
                partnerFinded = true

            } else if (!partnerFinded && !partnerWithoutCity && partner?.addressState_externalId === client?.addressState_externalId) {
                const state = addressStatePartnersList?.filter(state => state?.addressState_externalId === partner?.addressState_externalId)[0]
                const city = addressCity?.filter((city) => {
                    if (city?.addressCity_externalId === partner?.addressCity_externalId) {
                        return city
                    }
                })
                const cities = addressCity?.filter(city => city?.addressCity_externalId.slice(0, 2) === client?.addressState_externalId)
                setSelectedAddressCity(city[0])
                setSelectedAddressState(state)
                setFilteredAddressCities(cities)
                partnerWithoutCity = true
                setPartnerPreSelected('')
                return
            }
        })
    }

    async function animateLoading(status) {

        if (status) {
            setShowAnimate(true)
            await new Promise(resolve => setTimeout(resolve, 30))
        } else {
            setShowAnimate(false)
        }
    }

    async function filterClientWithRemoteSchedule(){
        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 100))
        setSelectedPartner('')
        setPartnerPreSelected('')
        setSelectedAddressState('')
        setSelectedAddressCity('')
        setSelectedStartHour('')
        setSelectedFinishHour('')
        setExistsBlock(false)
        setBlockData('')
        setFilteredPartners(partnerList?.filter(partner => partner?.isRemote))
        setLoading(false)
    }

    useEffect(() => {
        if (selectedClient?.isRemote){
            filterClientWithRemoteSchedule()
        } else {
            filterMesoregion(selectedClient)
            selectCityAndStateByClient(selectedClient)
            if (selectedPartner?.id > 0) {
                getHoursForThisPartner(selectedPartner)
            }
        }
    }, [selectedClient])

    useEffect(() => {
        getData()
        let formattedHours = []
        hourTime.map(hour => {
            formattedHours.push({ hour: hour?.hour, description: maskHours(hour) })
        })

        setFormattedHours(formattedHours)
        setCompleteHours(formattedHours)
        setHoursEnd(formattedHours)
    }, [])

    useEffect(() => {
        async function getDataAPI(){
            setLoadingHoursAndCalendar(true)
            setExistsBlock(false)
            setBlockData('')
            if (partnerPreSelected) {
                const blockDataFunction = await getBlockOnDateAndPartner(moment(selectedDay)?.utc(false).format('YYYY-MM-DD'), partnerPreSelected?.id)                
                getHoursForThisPartner(partnerPreSelected, blockDataFunction)
            } else if (selectedPartner?.id) {
                const blockDataFunction = await getBlockOnDateAndPartner(moment(selectedDay)?.utc(false).format('YYYY-MM-DD'), selectedPartner?.id)                
                getHoursForThisPartner(selectedPartner, blockDataFunction)
            }
            setLoadingHoursAndCalendar(false)
        }

        getDataAPI()
    }, [selectedPartner, partnerPreSelected, selectedDay])

    useEffect(() => {
        handleFinishHour()
    }, [selectedStartHour])

    async function getBlockOnDateAndPartner(date, partner){
        try {
            const getPartnersData = await api.get(`/api/v1/blockSchedule?dateOff=${date}&partners_id=${partner}`, {
                headers: {
                    authorization: `Bearer ${userData.token}`
                }
            })
            const blockHour = getPartnersData?.data?.data[0] ? getPartnersData?.data?.data[0] : ''
            setBlockData(blockHour)
            const hoursUnavailable = []
            if (blockHour){
                hourTime?.map(hour => {
                    if (hour.hour >= blockHour?.startHour && hour.hour <= blockHour?.finishHour){
                        hoursUnavailable.push(hour.hour)
                    }
                })            
                setExistsBlock(true)
            }
            return blockHour   
        } catch (error){
            if (responseError(error).length > 0) {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    function updateListOfClients() {
        getData()
        setCreateNewClient(false)
    }

    function addNewAttachment() {
        const inputFile = document.getElementById('fileInput')
        inputFile.click()
    }

    const handleNewPhoto = (photoFile) => {

        const inputFile = document.getElementById('fileInput')


        const checkFile = permittedFiles(photoFile.target.files[0])
        if (checkFile.error) {
            setShowModificationModal(true)
            setShowNotificationModalSuccess(false)
            setShowNotificationModalText(checkFile.response)
            return removeThisAttachment(photoFile)
        }

        if (scheduleAttacments.length < 5) {
            setScheduleAttachments([photoFile.target.files[0], ...scheduleAttacments])
        }
    }

    function handleSelectedClient(e) {
        setPartnerPreSelected();
        setSelectedPartner()
        setSelectedStartHour({ description: '', hour: '' })
        setSelectedFinishHour({ description: '', hour: '' })
        setFilterConstructor({})
        setFilter({})
        setSelectedClient(e)
        setShowModalClientsList(false)
    }

    async function removeThisAttachment(attachment, indexAttchament) {
        setScheduleAttachments(scheduleAttacments.filter(attchToRemove => attchToRemove !== attachment))
    }

    async function handleAttchamentFile(scheduleId) {

        try {

            const formData = new FormData();

            scheduleAttacments.map((attachment) => {
                formData.append('files', attachment);
            })

            const config = {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                    'content-type': 'multipart/form-data',
                },
            };

            await api.post(`/api/v1/scheduleAttachments/schedule_id/${scheduleId}`, formData, config)
            return
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowModificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    //funcao para tratar os intervalos de agendamentos definidos no cadastro do parceiro
    async function verifyTimeInterval(startHour) {
        if (selectedPartner?.time_interval && !partnerPreSelected) {
            let diff = startHour?.hour + (selectedPartner?.time_interval / 60)
            let autoFinishHour = partnersFinishHours.filter(hour => { return hour?.hour == diff })[0]
            setSelectedFinishHour(autoFinishHour)
            setIsAutoFinishHour(true)

        } else if (partnerPreSelected?.time_interval) {
            let diff = startHour?.hour + (partnerPreSelected?.time_interval / 60)
            let autoFinishHour = partnersFinishHours.filter(hour => { return hour?.hour == diff })[0]
            setSelectedFinishHour(autoFinishHour)
            setIsAutoFinishHour(true)
        } else {
            setIsAutoFinishHour(false)
        }
    }

    return (
        <Container>
            <NotificationModal isAuth={true} onlyErrors={true} />
            <div className="flex flex-col bg-bgPrimaryLight dark:bg-primaryDefaultDark shadow-lg overflow-auto py-6 dark:scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-zinc-700 scrollbar-thumb-zinc-600">
                {
                    createNewClient ?
                    <div style={{ width: screenX - 80 + 'px', maxWidth: '1150px', maxHeight: screenX - 200 + 'px' }} className="flex flex-col lg:w-full mx-[1.4rem] justify-start items-center flex-wrap gap-6">

                        <ClientsCreateComponent dontShowFooter={false} defaultRoute={false} execFuncionRoute={() => updateListOfClients()} />
                        {/* <ClientsCreate dontShowFooter={true} defaultRoute={false} execFuncionRoute={() => updateListOfClients()} /> */}
                    </div>
                    : showModalClientsList ?
                    <div className="flex flex-col justify-start w-full h-[35rem] p-6">
                        {
                            filterClients ?
                            <div className='flex flex-col pt-2 pb-10 px-12 gap-4 items-center'>
                                <p className="text-lg text-primaryDefaultLight">Filtro Cliente</p>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                    <Input
                                        charLimit={255} id='id' type='string'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                        value={filterConstructor?.id}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                    <Input
                                        charLimit={255} id='firstname' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, firstname: e.target.value })}
                                        value={filterConstructor?.firstname}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Sobrenome *</a>
                                    <Input
                                        charLimit={255} id='lastname' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, lastname: e.target.value })}
                                        value={filterConstructor?.lastname}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>CPF *</a>
                                    <Input
                                        charLimit={11} id='cpf' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, cpf: e.target.value })}
                                        value={filterConstructor?.cpf}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                    <Input
                                        charLimit={255} id='email' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, email: e.target.value })}
                                        value={filterConstructor?.email}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Cidade *</a>
                                    <Input
                                        charLimit={255} id='addressCity' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, addressCity: e.target.value })}
                                        value={filterConstructor?.addressCity}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>UF *</a>
                                    <Input
                                        charLimit={255} id='addressState' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, addressState: e.target.value })}
                                        value={filterConstructor?.addressState}
                                    ></Input>
                                </label>
                                <div className='mt-5 flex flex-row gap-3 items-center justify-center'>
                                    <Button approval={false} onClick={() => setFilterClients(false)}>Voltar</Button>
                                    <Button onClick={() => {
                                        setFilter(filterConstructor)
                                        setFilterClients(false)
                                    }}>Filtrar</Button>
                                </div>
                            </div>
                            : 
                            <>
                                <div className="flex flex-row gap-2 fixed items-start justify-start top-5 left-3">
                                    <Button onClick={() => {setShowModalClientsList(false)}} approval={false}>Voltar</Button>
                                    <Button onClick={() => {setShowUniversalModal(true); setFilterClients(true)}}>
                                        <FaFilter className='text-white' /> <p className='text-white pl-2'> Filtrar </p>
                                    </Button>
                                    {
                                        filter && JSON.stringify(filter) != JSON.stringify({}) ? 
                                        <Tippy
                                            key={`clearFilter`}
                                            content={<span>Limpar filtro</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='right'
                                            delay={100}>
                                            <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center mt-2 gap-2 cursor-pointer'>
                                                <FiSlash className='cursor-pointer' onClick={async () => {
                                                    setLoading(true)
                                                    setFilter()
                                                    setFilterConstructor()
                                                    await new Promise(resolve => setTimeout(resolve, 500))
                                                    setLoading(false)
                                                }} />
                                            </div>
                                        </Tippy>
                                        : <></>
                                    }
                                </div>
                                <div style={{ width: screenX - 80 + 'px', maxWidth: '1150px', maxHeight: screenY - 200 + 'px' }} className="h-full text-center">
                                    <TablePaginationDefault
                                        onClick={(e) => e.func ? e.func.execute(e.data) : handleSelectedClient(e)}
                                        apiUrl={'/api/v1/clients/filter'}
                                        quantity={(e) => setQuantity(e)}
                                        filters={filter}
                                        mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleSelectedClient }]}
                                        collumns={[
                                            {
                                                name: 'id',
                                                type: 'number',
                                                description: 'Código'
                                            },
                                            {
                                                name: 'firstname',
                                                type: 'string',
                                                description: 'Nome'
                                            },
                                            {
                                                name: 'cpf',
                                                type: 'string',
                                                func: (item) => {
                                                    if (item?.cpf) {
                                                        return mCPF(item?.cpf?.toString())
                                                    }
                                                },
                                                description: 'CPF'
                                            },
                                            {
                                                name: 'created_at',
                                                type: 'date',
                                                description: 'Criado em'
                                            }
                                        ]}
                                    />
                                </div>
                            </>
                        }
                    </div>
                    :
                    <div style={{ width: screenX - 80 + 'px', maxWidth: '1150px', maxHeight: screenY - 200 + 'px' }} className="flex flex-col lg:w-full mx-[1.4rem] justify-start items-center flex-wrap gap-6">
                        {
                            loading ?
                            <div style={{ width: screenX - 140 + 'px', maxWidth: '1150px' }} className="flex flex-col items-center justify-center h-[30rem]">
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <div className="flex flex-col fixed items-start justify-start top-2 left-16 ">
                                    <Button onClick={() => handleSubmit()}><FaSave /> Agendar</Button>
                                </div>
                                <div className="flex flex-row items-start justify-center gap-6">
                                    <div className="flex flex-col sm:w-auto gap-4 items-center sm:items-start sm:justify-center shadow-lg border border-gray-200 dark:border-secondaryBorderDark">
                                        <div className=" flex flex-col p-2 gap-4 rounded-lg items-start justify-start py-4 h-72">
                                            <LabelInput text={'Cliente'}>
                                                {
                                                    selectedClient ?
                                                    <p className="flex flex-row justify-start items-center text-sm">
                                                        {selectedClient?.firstname?.length > 38 ? `${selectedClient?.firstname?.slice(0,35)}...` : selectedClient?.firstname}
                                                    </p>
                                                    :
                                                    <></>
                                                }
                                                <p 
                                                    className="flex flex-row justify-center items-center text-sm underline cursor-pointer text-primaryDefaultLight"
                                                    onClick={() => setShowModalClientsList(true)}
                                                >
                                                    Selecione um cliente
                                                </p>
                                            </LabelInput>
                                            {
                                                filteredPartners?.length > 0 && !loading ?
                                                    <>
                                                        <LabelInput text={'Estado'}>
                                                            <InputAutoComplete 
                                                                width={64} 
                                                                disabled={!selectedClient?.id ? true : selectedClient?.isRemote ? true : false}
                                                                preSelectedValue={partnerPreSelected ? partnerPreSelected?.addressState : selectedAddressState?.addressState}
                                                                value={selectedPartner ? selectedPartner?.addressState : partnerPreSelected ? partnerPreSelected?.addressState : selectedAddressState?.addressState}
                                                                data={addressStatePartnersList} 
                                                                onChange={(e) => { 
                                                                    if (typeof(e) == 'object'){
                                                                        filterPartnerByState(e); 
                                                                        setPartnerPreSelected() 
                                                                    }
                                                                }}
                                                                selectedLabel={`addressState`} optionList={[`addressState_externalId`, `addressState`]} />
                                                        </LabelInput>
                                                        <LabelInput text={'Cidade'}>
                                                            <InputAutoComplete 
                                                                width={64} 
                                                                disabled={!selectedClient?.id ? true : selectedClient?.isRemote ? true : false}
                                                                preSelectedValue={selectedAddressCity?.addressCity_externalId !== '' ? selectedAddressCity?.addressCity : ''}
                                                                data={[{ addressCity: `Todos`, addressCity_externalId: 0 }, ...filteredAddressCity]}
                                                                onChange={(e) => { 
                                                                    if (typeof(e) == 'object'){
                                                                        filterPartnerByCity(e)
                                                                        setPartnerPreSelected()
                                                                        setSelectedStartHour(0) 
                                                                    }
                                                                }} 
                                                                selectedLabel={`addressCity`} 
                                                                optionList={[`addressCity_externalId`, `addressCity`]}
                                                            />
                                                        </LabelInput>
                                                        <LabelInput text={'Parceiro'}>
                                                            <InputAutoComplete 
                                                                id={'selectedPartner'}
                                                                width={64}
                                                                disabled={!selectedClient?.id ? true : false}
                                                                value={selectedPartner ? selectedPartner?.name : ''}
                                                                preSelectedValue={partnerPreSelected ? partnerPreSelected?.name : ''}
                                                                data={partnerPreSelected ? filteredPartners.filter(partner => { return partner?.addressCity_externalId == partnerPreSelected?.addressCity_externalId }) : filteredPartners}
                                                                onChange={(e) => {
                                                                    setSelectedPartner(e)
                                                                    if (typeof (e) == 'object') {
                                                                        filterCityByPartner(e)
                                                                    }
                                                                    setPartnerPreSelected();
                                                                }}
                                                                selectedLabel={`name`} optionList={[`id`, `name`]}></InputAutoComplete>
                                                        </LabelInput>
                                                    </>
                                                    :
                                                    searchPartners && selectedClient?.id > 0 && !selectedClient?.isRemote ?
                                                    <div className="w-64">
                                                        <p>Não existem parceiros para esta mesoregião do estado de {selectedClient.addressState}</p>
                                                        <p>Clique abaixo para selecionar outro parceiro</p>
                                                        <div className="flex flex-row gap-2 items-start justify-start">
                                                            <Button onClick={() => { setSearchPartners(false) }}>Continuar</Button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        <div className="flex flex-col gap-4 dark:bg-secondaryDefaultDarkrounded-lg items-start justify-start  lg:w-64">
                                                            <LabelInput text={`Estado`}>
                                                                <InputAutoComplete 
                                                                    id={'selectedAddressState'}
                                                                    width={64} 
                                                                    disabled={!selectedClient?.id ? true : selectedClient?.isRemote ? true : false} 
                                                                    onChange={(e) => {
                                                                        if (typeof(e) == 'object'){
                                                                            filterPartnerByState(e)
                                                                        }
                                                                    }} 
                                                                    data={filteredAddressStatePartnersList} 
                                                                    selectedLabel={`addressState`} 
                                                                    optionList={[`addressState_externalId`, `addressState`]}
                                                                />
                                                            </LabelInput>
                                                            <LabelInput text={'Cidade'}>
                                                                <InputAutoComplete
                                                                    id={'selectedAddressCity'}
                                                                    width={64} 
                                                                    disabled={filteredPartners?.length == 0 ? true : false} 
                                                                    preSelectedValue={addressCity.addressCity} 
                                                                    data={filteredAddressCity} 
                                                                    onChange={(e) => { 
                                                                        if (typeof(e) == 'object'){
                                                                            setSelectedAddressCity(e)
                                                                        }
                                                                    }} 
                                                                    selectedLabel={`name`} 
                                                                    optionList={[`id`, `addressCity`]}
                                                                />
                                                            </LabelInput>
                                                            <LabelInput text={'Parceiro'}>
                                                                <InputAutoComplete 
                                                                    id={'selectedPartner'}
                                                                    width={64} 
                                                                    disabled={filteredPartners?.length == 0 ? true : false} 
                                                                    data={filteredPartners} 
                                                                    onChange={(e) => {
                                                                        if (typeof(e) == 'object'){
                                                                            setSelectedPartner(e) 
                                                                        } 
                                                                    }} 
                                                                    selectedLabel={`addressState`} 
                                                                    optionList={[`addressState_externalId`, `addressState`]}
                                                                />
                                                            </LabelInput>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                        <div className="flex flex-col items-start justify-start lg:p-1 pt-8 ">
                                            <div id='scheduleCreateCalendar' className={`flex flex-col ${existsBlock ? 'gap-2' : 'gap-4'} rounded-lg items-start justify-start lg:w-64`}>
                                                <div className={`px-2 flex flex-col items-start justify-start ${existsBlock ? 'mb-4' : 'mb-6'}`}>
                                                    <p className={`text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor ${existsBlock ? 'mb-0' : 'mb-3'}`}>
                                                        Horário 
                                                    </p>
                                                    {
                                                        loadingHoursAndCalendar ?
                                                        <p className="text-xs flex flex-row animate-pulse justify-center items-center mb-3 w-full">Carregando...</p>
                                                        :
                                                        <div className="flex flex-col gap-2 items-start justify-start">
                                                            {
                                                                formattedHours?.length == 0 ?
                                                                <LabelInput>
                                                                    <a className="text-red-700 text-center">Parceiro não atende nesse dia da semana</a>
                                                                </LabelInput>
                                                                :
                                                                <>
                                                                    {
                                                                        existsBlock && blockData ?                                          
                                                                        <span className="text-sm text-red-800 mb-2">
                                                                            Bloqueio: {`${listHours?.filter(hour => hour?.id == blockData?.startHour)[0]?.name} às ${listHours?.filter(hour => hour?.id == blockData?.finishHour)[0]?.name}`}
                                                                        </span>
                                                                        : <></>
                                                                    }
                                                                    <div className="flex lg:flex-row flex-col gap-2 items-start justify-start">
                                                                        <LabelInput text={'Inicio'}>
                                                                            <InputAutoComplete width={screenX < 400 ? 64 : 24}
                                                                                disabled={(selectedPartner?.id > 0 || partnerPreSelected?.id > 0) && selectedClient?.id > 0 ? false : true}
                                                                                preSelectedValue={selectedStartHour?.description ? selectedStartHour?.description : ''}
                                                                                onChange={(e) => {
                                                                                    setSelectedStartHour(e);
                                                                                    setSelectedFinishHour({ hour: '' });
                                                                                    verifyTimeInterval(e)
                                                                                }}
                                                                                data={formattedHours} selectedLabel={formattedHours?.description} optionList={['description']}/>
                                                                        </LabelInput>
                                                                        <LabelInput text={'Fim'}>
                                                                            <InputAutoComplete width={screenX < 400 ? 64 : 24} disabled={selectedStartHour && !isAutoFinishHour && hoursEnd?.length > 0 ? false : isAutoFinishHour ? true : hoursEnd?.length == 0 && true}
                                                                                preSelectedValue={selectedFinishHour ? selectedFinishHour?.description : ''} value={selectedFinishHour?.description}
                                                                                onChange={(e) => setSelectedFinishHour(e)}
                                                                                data={hoursEnd} selectedLabel={formattedHours?.description} optionList={['description']}></InputAutoComplete>
                                                                        </LabelInput>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div className=' dark:bg-secondaryDefaultDark bg-bgPrimaryLight lg:w-64 w-64 p-2 flex flex-col gap-4'>
                                                    <CalendarMain onClick={(e) => {setSelectedDay(e); setSelectedStartHour(0); setSelectedFinishHour(0)}} hours={selectedPartner || partnerPreSelected ? hoursCompleteList : ''}/>                                                       
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" p-2 flex flex-col items-center justify-center shadow-inner">
                                        <div id="scheduleCreateData" className="flex lg:flex-row flex-col gap-4 mt-9 lg:w-full lg:items-start lg:justify-between lg:h-72" >
                                            <div className="flex flex-col items-center lg:items-start justify-center lg:w-96 rounded-lg gap-4">
                                                <div className=" border-b border-gray-200 dark:border-secondaryBorderDark w-full">
                                                    <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Dados do cliente</p>
                                                </div>
                                                <div className=" flex flex-col items-center justify-center mb-4">
                                                    {
                                                        selectedClient?.id > 0 &&
                                                        <div className="mt-1 flex flex-col rounded-lg gap-4 lg:w-full" >
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Cliente: ${selectedClient.firstname}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Cidade: ${selectedClient.addressCity}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Estado: ${selectedClient.addressState}`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`Mesoregião: ${selectedClient.mesoregion_externalId}`}</a>
                                                            <div className="flex flex-col gap-1">
                                                                <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Observação do cliente:</a>
                                                                <textarea className="text-sm dark:text-titleGrayTextDark p-2 shadow-inner h-20 max-h-20 max-w-24 lg:w-64 dark:bg-secondaryDefaultDark" disabled value={selectedClient.observation} wrap='hard'></textarea>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                            <div className="flex flex-col lg:items-center items-start lg:justify-start lg:w-96 rounded-lg gap-4 pb-11">
                                                <div className="lg:w-full border-b bordar-gray-200 dark:border-secondaryBorderDark lg:h-[1.57rem]  h-10  ">
                                                    <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Dados do parceiro</p>
                                                </div>
                                                <div className="mt-1 lg:w-full flex flex-col items-start justify-start">
                                                    {
                                                        (selectedPartner?.id > 0 || partnerPreSelected?.id > 0) && filteredPartners?.length > 0 &&
                                                        <div className=" w-full flex flex-col rounded-lg gap-3" >
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Parceiro:</strong>{partnerPreSelected?.name ? partnerPreSelected?.name : selectedPartner?.name ? ` ${selectedPartner.name}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Rua:</strong>{partnerPreSelected?.addressRoad ? partnerPreSelected?.addressRoad : selectedPartner?.addressRoad ? ` ${selectedPartner.addressRoad}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>N:</strong>{partnerPreSelected?.addressNumber ? partnerPreSelected?.addressNumber : selectedPartner?.addressNumber ? ` ${selectedPartner?.addressNumber}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Complemento:</strong>{partnerPreSelected?.addressComplement ? partnerPreSelected?.addressComplement : selectedPartner?.addressComplement ? ` ${selectedPartner?.addressComplement}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Bairro:</strong>{partnerPreSelected?.addressDistrict ? partnerPreSelected?.addressDistrict : selectedPartner?.addressDistrict ? ` ${selectedPartner.addressDistrict}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Cidade:</strong>{partnerPreSelected?.addressCity ? partnerPreSelected?.addressCity : selectedPartner?.addressCity ? ` ${selectedPartner.addressCity}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Estado:</strong>{partnerPreSelected?.addressState ? partnerPreSelected?.addressState : selectedPartner?.addressState ? ` ${selectedPartner.addressState}` : ` -`}</a>
                                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark"><strong>Mesoregião:</strong>{partnerPreSelected?.mesoregion_externalId ? partnerPreSelected?.mesoregion_externalId : selectedPartner.mesoregion_externalId ? ` ${selectedPartner.mesoregion_externalId}` : ` -`}</a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div id='scheduleCreateObservation' className="flex flex-col gap-4 rounded-lg items-center justify-center lg:w-full">
                                            <div className="flex flex-col gap-2 items-start justify-start lg:w-full">
                                                <div className="border-b border-gray-200 dark:border-secondaryBorderDark lg:w-full pt-10 mb-4">
                                                    <p className="text-base text-primaryDefaultLight dark:text-primaryDefaultDarkColor">Detalhes</p>
                                                </div>
                                                <div className="flex flex-row w-full">
                                                    <div className="flex flex-col w-[50%] gap-2 items-start">
                                                        <input
                                                            className="
                                                                h-8 pl-2 border border-gray-400 border-opacity-75 rounded-sm outline-none
                                                                text-sm sm:text-sm transition-all duration-200 focus:shadow-borderShadow
                                                                dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark
                                                                w-96                                                             
                                                            "
                                                            placeholder="Pesquisar etiqueta"
                                                            onChange={(e) => setSelectedLabelDescription(e?.target?.value)}
                                                            value={selectedLabelDescription || ''}
                                                        />
                                                        <div className="h-20 overflow-auto w-96 flex flex-wrap border border-gray-200 shadow-inner gap-2 p-2">
                                                            {
                                                                labels?.map(label => {
                                                                    const find = selectedLabels?.find(item => item?.id == label?.id)
                                                                    if (find) { return null }
                                                                    return label
                                                                })
                                                                ?.filter(item => item != null)
                                                                ?.filter(item => !!selectedLabelDescription ? item?.description?.toLowerCase()?.includes(selectedLabelDescription?.toLowerCase()) : item)
                                                                ?.map(label => (
                                                                    <p onClick={() => setSelectedLabels(prev => ([...prev, label]))} className="h-fit py-1 px-2 text-sm border border-gray-400 cursor-pointer hover:opacity-50 duration-100 transition-all">
                                                                        #{String(label?.description)?.substring(0,12)}
                                                                    </p>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className="flex flex-wrap border border-gray-100 w-[24.6rem] h-full max-h-32 overflow-auto rounded-sm gap-2 p-2">
                                                            {
                                                                selectedLabels?.length > 0 ?
                                                                    selectedLabels?.map(label => (
                                                                        <p 
                                                                            style={{ backgroundColor: label?.color ?? '#0B2E58' }} 
                                                                            className={`text-sm w-fit h-fit py-1 px-2 rounded-md text-white cursor-pointer hover:opacity-75 duration-100 transition-all`}
                                                                            onClick={() => setSelectedLabels([...selectedLabels?.filter(item => item?.id != label?.id)])}
                                                                        >
                                                                            #{String(label?.description).substring(0,13)}
                                                                        </p>
                                                                    ))
                                                                :
                                                                <p className="text-gray-400 p-2">Etiquetas selecionadas</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col gap-1 ml-5">
                                                        <LabelInput text={'Tese *'}>
                                                            <InputAutoComplete
                                                                id={'selectedTag'}
                                                                value={selectedTag ? selectedTag?.description : ''}
                                                                preSelectedValue={selectedTag ? selectedTag?.description : ''}
                                                                width={80}                                                             
                                                                data={tagsList} 
                                                                disabled={!selectedClient?.id ? true : false}
                                                                selectedLabel={`description`} 
                                                                optionList={['inputLabel']} 
                                                                onChange={(e) => {setSelectedTag(e)}}
                                                            />
                                                        </LabelInput>
                                                        <LabelInput text={'Confirmação *'}>
                                                            <InputAutoComplete 
                                                                id={'selectedConfirmation'}
                                                                value={selectedConfirmation ? selectedConfirmation?.description : ''}
                                                                preSelectedValue={selectedConfirmation ? selectedConfirmation?.description : ''}
                                                                width={80} 
                                                                disabled={!selectedClient?.id ? true : false}
                                                                data={confirmationList} 
                                                                selectedLabel={`description`} 
                                                                optionList={['inputLabel']} 
                                                                onChange={(e) => {setSelectedConfirmation(e)}}
                                                            />
                                                        </LabelInput>
                                                        <textarea 
                                                            value={selectedObservation} 
                                                            placeholder={'Observação do usuário nobre'} 
                                                            onChange={(e) => setSelectedObservation(e.target.value)} 
                                                            wrap="hard" 
                                                            className="w-full outline-none focus:shadow-borderShadow transition-all duration-300 lg:h-32
                                                                bg-white dark:bg-secondaryDefaultDark border border-gray-100
                                                                dark:border-secondaryBorderDark shadow-inner p-2 mt-3"
                                                        />                                             
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="border-b border-gray-200 dark:border-secondaryBorderDark w-full"></div>
                                        </div>
                                        <div className="lg:w-full flex lg:flex-row flex-col-reverse gap-2 items-center justify-center lg:h-24 rounded-sm p-2 w-96 mt-2">
                                            <div onClick={() => addNewAttachment()} className="p-2 hover:brightness-125 dark:text-white bg-primaryDefaultLight dark:bg-primaryDefaultDarkColor rounded-lg cursor-pointer h-full flex flex-col items-center justify-center w-40">
                                                <p className=" text-titleGrayTextDark text-sm">Anexar arquivos</p>
                                            </div>
                                            <input className="hidden" id='fileInput' type={'file'} onChange={(e) => handleNewPhoto(e)}></input>
                                            <div className="bg-white dark:bg-secondaryDefaultDark shadow-sm border border-gray-100 dark:border-secondaryBorderDark p-2 lg:w-full lg:h-full w-60  h-32 rounded-lg overflow-auto flex flex-row gap-2 flex-wrap">
                                                {
                                                    scheduleAttacments?.length > 0 &&
                                                    scheduleAttacments.map(attachment => {
                                                        return <div className="cursor-pointer p-2 h-12 flex w-36 border border-zinc-50 dark:border-secondaryBorderDark rounded bg-white dark:bg-secondaryDefaultDark shadow-lg flex-col items-center justify-center relative">

                                                            <span onClick={() => removeThisAttachment(attachment)} className="w-6 h-6 rounded-full hover:brightness-75 bg-zinc-700 absolute left-0 top-0 -translate-x-2 -translate-y-2 text-titleGrayTextDark text-xs flex flex-col items-center justify-center cursor-pointer">x</span>
                                                            <a href={URL.createObjectURL(attachment)} download>{attachment?.name?.slice(0, 8).concat('...').concat(attachment.name.slice(attachment.name.length - 3, attachment.name.length))}</a>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }
            </div>
        </Container>

    )

}