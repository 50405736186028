import axios from "axios";

// export const api = axios.create({
//     baseURL: 'http://localhost:3333',
//     withCredentials: true
// })

// AWS API
export const api = axios.create({
    baseURL: 'https://portal.nobredigital.com.br',
    withCredentials: true
})

// AWS API Homolog
// export const api = axios.create({
//     baseURL: 'http://ec2-54-204-121-135.compute-1.amazonaws.com',
//     withCredentials: true
// })