//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contexts/useAuth'
import { FiSlash } from 'react-icons/fi'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { TableDefault } from '../../../components/table/table.default'
import { TableMobile } from '../../../components/table/table.mobile'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { FaDownload, FaEdit, FaFilter } from 'react-icons/fa'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { TablePaginationDefault } from '../../../components/table/tablePagination.default'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { Input } from '../../../components/input/input'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
//services
import { getPartners } from '../../../services/api/callAPIsFunctions/defaultCalls.api'
import { api } from '../../../services/api/api'
//regex
import { mCPF } from '../../../utils/validators/cpfMask'
import { cnpjMask } from '../../../utils/validators/cnpjMask'
import { formatSizeCpf } from '../../../utils/validators/formatSizeCpf'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { justNumber } from '../../../utils/validators/justNumber'


export function Partners() {

    const navigate = useNavigate()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(false)
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [showNotification, setShowNotication] = useState('')
    const [successData, setSuccessData] = useState(false)
    const [partnersList, setPartnersList] = useState([])
    const [filteredPartners, setFilteredPartners] = useState([])
    const [partnersActiveButton, setPartnersActiveButton] = useState()
    const [partnersMaster, setPartnersMaster] = useState([])
    const [filterConstructor, setFilterConstructor] = useState({ active: 1 })
    const [filter, setFilter] = useState({ active: 1 })
    const [quantity, setQuantity] = useState(0)
    const { setShowUniversalModal } = useThemeContext()

    function handleEdit(data) {
        navigate(`/partnersEdit/${data.id}`)
    }

    async function getExport() {

        const blob = await api.get('/api/v1/partners/export', {
            headers: {
                authorization: `Bearer ${userData.token}`,
            },
            responseType: 'blob',
        })

        const url = URL.createObjectURL(blob.data)
        const linkDownload = document.createElement('a')
        linkDownload.setAttribute('href', url)
        linkDownload.setAttribute('download', `parceiros_${new Date().toISOString().split('T')[0]}`)
        linkDownload.click()
    }

    return (
        <>
            <ScreenModal title={'Filtros'}>
                {
                    loading
                        ? <DefaultLoader />
                        : (
                            <div className='flex flex-col pt-2 pb-10 px-12 gap-4 items-center'>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Código *</a>
                                    <Input
                                        charLimit={255} id='id' type='string'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, id: justNumber(e.target.value) })}
                                        value={filterConstructor?.id} autoFocus={true}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                    <Input
                                        charLimit={255} id='name' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, name: e.target.value })}
                                        value={filterConstructor?.name} autoFocus={true}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Cidade *</a>
                                    <Input
                                        charLimit={255} id='addressCity' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, addressCity: e.target.value })}
                                        value={filterConstructor?.addressCity} autoFocus={true}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>UF *</a>
                                    <Input
                                        charLimit={255} id='addressState' type='text'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, addressState: e.target.value })}
                                        value={filterConstructor?.addressState} autoFocus={true}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>CEP *</a>
                                    <Input
                                        charLimit={255} id='cep' type='string'
                                        onChange={(e) => setFilterConstructor({ ...filterConstructor, cep: e.target.value })}
                                        value={filterConstructor?.cep} autoFocus={true}
                                    ></Input>
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Tipo *</a>
                                    <InputAutoComplete
                                        data={[{ id: false, description: 'Filial' }, { id: true, description: 'Matriz' }]}
                                        selectedLabel={'description'}
                                        optionList={['description']}
                                        onChange={e => setFilterConstructor({ ...filterConstructor, isMaster: e.id })}
                                        preSelectedValue={[{ id: false, description: 'Filial' }, { id: true, description: 'Matriz' }]?.filter(filter => filter == filterConstructor?.isMaster)[0]?.description}
                                        id='isMaster'
                                        width={'318px'}
                                    />
                                </label>
                                <label className='flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Status *</a>
                                    <InputAutoComplete
                                        data={[{ id: 0, description: 'Inativo' }, { id: 1, description: 'Ativo' }]}
                                        selectedLabel={'description'}
                                        optionList={['description']}
                                        onChange={e => setFilterConstructor({ ...filterConstructor, active: e.id })}
                                        preSelectedValue={[{ id: 0, description: 'Inativo' }, { id: 1, description: 'Ativo' }]?.filter(filter => filter == filterConstructor?.active)[0]?.description}
                                        id='active'
                                        width={'318px'}
                                    />
                                </label>
                                <div className='mt-5'>
                                    <Button onClick={() => {
                                        setFilter(filterConstructor)
                                        setShowUniversalModal(false)
                                    }}>Filtrar</Button>
                                </div>
                            </div>
                        )
                }
            </ScreenModal>
            <Container>
                <TitlePage displayNotification={showNotification} success={successData} setDisplayNotification={e => setShowNotication(e)}>
                    <div className='flex flex-row gap-2 items-center justify-between pl-2 pr-2 md:pl-0 w-full'>
                        <div className='flex flex-col items-start justify-center w-[50%] md:w-auto'>
                            <Title text={'Parceiros'}></Title>
                            <div className='flex flex-row gap-2 md:w-48 justify-start items-center'>
                                <h3 className='text-base font-light dark:text-titleGrayTextDark'>{quantity} Parceiros</h3>
                                {
                                    [1,2].includes(userData?.typeAccess_id) ?
                                        <Tippy key={`tippy-export`} content={<span>Exportar lista em excel</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='right'
                                            delay={100}>
                                            <div onClick={() => getExport()} id='generateDownloads' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                                <FaDownload className='dark:text-titleGrayTextDark text-titleBlackTextLight text-lg' />
                                            </div>
                                        </Tippy>
                                    : <></>
                                }
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center w-[50%] md:w-auto' onClick={() => navigate('/partnersCreate')}>
                            <Button shadow={true} onClick={() => navigate('/partnersCreate')}>+ Novo Parceiro</Button>
                        </div>
                    </div>
                </TitlePage>
                <Body>
                    <div className='w-full sm:mt-0 mt-2 flex items-center gap-4'>
                        <Button onClick={() => setShowUniversalModal(true)}>
                            <FaFilter className='text-white' /> <p className='text-white pl-2'> Filtrar </p>
                        </Button>
                        {filter && JSON.stringify(filter) != JSON.stringify({ active: 1 })
                            ? <Tippy
                                key={`clearFilter`}
                                content={<span>Limpar filtro</span>}
                                arrow={true}
                                animation='perspective'
                                placement='right'
                                delay={100}>
                                <div id='clearFilter' className='hidden md:flex flex-row justify-start items-center gap-2 cursor-pointer'>
                                    <FiSlash className='cursor-pointer' onClick={async () => {
                                        setLoading(true)
                                        setFilter({ active: 1 })
                                        setFilterConstructor()
                                        await new Promise(resolve => setTimeout(resolve, 500))
                                        setLoading(false)
                                    }} />
                                </div>
                            </Tippy>
                            : <></>
                        }
                    </div>
                    <TablePaginationDefault
                        onClick={(e) => e.func ? e.func.execute(e.data) : handleEdit(e)}
                        quantity={(e) => setQuantity(e)}
                        apiUrl={'/api/v1/partners/filter'}
                        filters={filter}
                        mobileFunctions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                        collumns={[
                            {
                                name: 'id',
                                type: 'number',
                                description: 'Código'
                            },
                            {
                                name: 'name',
                                type: 'string',
                                description: 'Nome'
                            },
                            {
                                name: 'cnpjcpf',
                                type: 'string',
                                func: (item) => {
                                    if (item?.cnpjcpf){
                                        if (item?.partnersType == 'PJ'){
                                            return cnpjMask(item?.cnpjcpf?.toString())
                                        } else {                                        
                                            return mCPF(formatSizeCpf(item?.cnpjcpf?.toString()))
                                        }
                                    }                        
                                },
                                description: 'CPF/CNPJ'
                            },
                            {
                                name: 'isMaster',
                                type: 'string',
                                func: (item) => {
                                    if (item?.isMaster){
                                        return 'Matriz'
                                    } else {
                                        return `Filial`
                                    }
                                },
                                description: 'Tipo'
                            },
                            {
                                name: 'created_at',
                                type: 'date',
                                description: 'Criado em'
                            }
                        ]}
                    />
                </Body>
            </Container>
        </>
    )
}
