
// hooks
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// components
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { validateFields } from '../../../utils/form.validator'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Body } from '../../../components/container/Body'
import { LabelInput } from '../../../components/label/label.input'
import { Footer } from '../../../components/footer/Footer'
// utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
//services
import { api } from '../../../services/api/api'

export function TagsCreate() {

    const { userData } = useAuth()
    const { setShowModificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [selectedDescription, setSelectedDescription] = useState('')

    async function handleSubmit() {

        const requiredFields = [
            { name: 'description', value: selectedDescription, required: true, type: 'string' }
        ]

        setLoading(true)

        if (validateFields(requiredFields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowModificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        try {
            await api.post('api/v1/tags', {
                description: selectedDescription
            }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            })
            setShowModificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
            navigate('/tags')

        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowModificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        // cleanState()
        setLoading(false)

    }

    return (
        <Container>
            <TitlePage>
                <div className='flex justify-center lg:justify-start'>
                    <Title text={'Cadastro de Tese'} />
                </div>
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ?
                        <div className='flex flex-col items-center justify-center w-full h-full'>
                            <DefaultLoader />
                        </div>
                        :
                        <div className='flex flex-col lg:items-start items-center justify-start w-full'>
                            <form className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
                                <LabelInput text={'Descrição *'}>
                                    <textarea className='outline-none border border-gray-400 border-opacity-75 rounded-lg w-80 h-36 lg:h-32 resize-none
                                                    dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark
                                                    focus:border focus:border-primaryDefaultLight
                                                    dark:focus:border dark:focus:border-primaryDefaultLight p-2
                                                    dark:scrollbar-thumb-gray-700 dark:scrollbar-track-gray-900 scrollbar-thin scrollbar-track-gray-400 scrollbar-thumb-gray-300' 
                                        maxLength={250} 
                                        id='description'
                                        value={selectedDescription ? selectedDescription : ''}  
                                        onChange={e => setSelectedDescription(e.target.value)} 
                                    />
                                </LabelInput>
                            </form>
                        </div>
                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button
                        id='cancelUserEdit'
                        approval={false}
                        shadow={true}
                        onClick={() => { navigate('/tags'); setShowModificationModal(false) }}
                    >Voltar
                    </Button>
                    <Button
                        id='approvalUserEdit'
                        approval={true}
                        shadow={true}
                        onClick={() => handleSubmit()}
                    >Salvar
                    </Button>
                </div>
            </Footer>
        </Container>
    )
}
