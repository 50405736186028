import React, { useEffect, useState } from 'react'
import { FaEdit, FaEye } from 'react-icons/fa'
import { useAuth } from '../../contexts/useAuth'
import { api } from '../../services/api/api'
import { formattTimeToShow } from '../../utils/formatData/formatTimeToShow'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { DefaultLoader } from '../loaders/defaultLoader'
import { TableMobile } from '../table/table.mobile'
import { useScreenSizeContext } from '../../contexts/screenSizeContext'

export function LogHistory({ apiData, cancelModal, bodyData, cancelTippy }) {

    const [thisLog, setThisLog] = useState([])
    const [pages, setPages] = useState({
        totalPages: 0,
        actualPage: 0
    })
    const { screenY } = useScreenSizeContext()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const [showOriginalContent, setShowOriginalContent] = useState(false)
    const [originalBody, setOriginalBody] = useState('')
    const [animateThisModal, setAnimateThisModal] = useState(true)

    const tableThead = [
        {
            "name": 'Executado por',
            "original_name": 'users_name'
        },
        {
            "name": 'Ação',
            "original_name": "method"
        },
        {
            "name": 'Detalhes',
            "original_name": "description"
        },
        {
            "name": 'Data',
            "original_name": 'created_at'
        }, {
            "name": 'Original',
            "original_name": 'request_body'
        }
    ]

    useEffect(() => {
        getData()
    }, [])

    async function getData(params, page) {
        setLoading(true)
        if (params) {
            let endpoint = `/api/v1/userActionLog` + apiData.params + params
            try {
                const log = await api.get(endpoint, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`
                    }
                })
                setThisLog(log.data.data)
                let arrayPages = Array(log.data.totalPages).fill(0)
                let formattedArray = []
                arrayPages.map((page, index) => {
                    formattedArray.push(index + 1)
                })

                setPages({
                    totalPages: arrayPages?.length,
                    actualPage: page,
                    page: formattedArray
                })
                setLoading(false)
            } catch (error) {
                console.log(error)
                alert('Erro ao buscar historico')
            }
        } else {
            let endpoint = `/api/v1/userActionLog` + apiData.params
            try {
                const log = await api.get(endpoint, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`
                    }
                })
                setThisLog(log.data.data)

                let arrayPages = Array(log.data.totalPages).fill(0)
                let formattedArray = []
                arrayPages.map((page, index) => {
                    formattedArray.push(index + 1)
                })

                setPages({
                    totalPages: arrayPages?.length,
                    actualPage: 1,
                    page: formattedArray
                })
                setLoading(false)
            } catch (error) {
                console.log(error)
                alert('Erro ao buscar historico')
            }
        }

    }
    function formatThisDate(date) {
        try {
            return formattTimeToShow(date)
        } catch (error) {
            return date
        }
    }

    function handleNewPage(page) {
        getData(`&page=${page}`, page)
        // setPages({ ...pages, actualPage: page })
    }

    return (
        <div className='overflow-x-auto overflow-hidden md:w-full w-[20rem]'>
            <div className='flex justify-start items-start flex-col md:w-full w-[50rem] mt-8 boxShadow rounded-lg bg-white dark:bg-secondaryDefaultDark border dark:border-secondaryBorderDark '>
                {
                    showOriginalContent &&
                    <div className='hidden lg:flex flex-col'>
                        <div className={`${showOriginalContent ? 'flex' : 'hidden'} flex-col items-center justify-center left-0 absolute w-full top-0 h-[100vh] sm:h-full z-[999999999]`}>
                            <div style={{ maxHeight: screenY }} className={`${animateThisModal ? 'translate-y-0' : '-translate-y-96'} transition-all duration-300 bg-white  overflow-auto dark:bg-secondaryDefaultDark border dark:border-secondaryBorderDark border-gray-200 rounded-lg boxShadow`} >
                                <div className='flex flex-row justify-between items-center p-2 dark:border-b dark:border-secondaryBorderDark'>
                                    <h1 className='dark:text-titleGrayTextDark'>{'Conteúdo enviado'}</h1>
                                    <button onClick={() => setShowOriginalContent(false)} className='bg-gray-300 dark:bg-gray-600 dark:text-gray-200 w-8 h-8 rounded-full'>x</button>
                                </div>
                                <div className='flex flex-col items-center justify-center overflow-x-hidden p-6 dark:text-white'>
                                    <pre>{JSON.stringify(JSON.parse(originalBody), null, 2)}</pre>
                                </div>
                            </div>
                        </div>
                        <div className={`${showOriginalContent ? 'flex' : 'hidden'} bg-gray-900 opacity-75  left-0 absolute top-0 w-full h-[100vh] sm:h-full z-[99999999]`}></div>
                    </div>
                }
                {
                    loading ?
                        <div className='py-6 flex flex-col items-center justify-center w-full'>
                            <DefaultLoader />
                        </div>
                        :
                        <table className='border-collapse table-auto overflow-y-auto w-full '>
                            <thead>
                                <tr>
                                    <th colSpan={5} className='bg-primaryDefaultLight text-titleGrayTextDark py-1 text-sm'>Histórico</th>

                                </tr>
                                <tr className=''>
                                    <th className='bg-primaryDefaultLight text-titleGrayTextDark py-1 lg:text-sm text-xs'>Executado por</th>
                                    <th className='bg-primaryDefaultLight text-titleGrayTextDark py-1 lg:text-sm text-xs'>Ação</th>
                                    <th className='bg-primaryDefaultLight text-titleGrayTextDark py-1 lg:text-sm text-xs'>Detalhes</th>
                                    <th className='bg-primaryDefaultLight text-titleGrayTextDark py-1 lg:text-sm text-xs'>Data</th>
                                    <th className='bg-primaryDefaultLight text-titleGrayTextDark py-1 lg:text-sm text-xs'>Original</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    thisLog?.length > 0 ?
                                        thisLog?.map((log, index) => {
                                            return <tr className='h-10' key={index}>
                                                <td className='text-center lg:text-sm text-xs dark:text-titleGrayTextDark '>{log.users_name}</td>
                                                <td className='text-center lg:text-sm text-xs dark:text-titleGrayTextDark'>{log?.method === "POST" ? "Inclusão" : log?.method === "PUT" ? "Alteração" : log?.method === "DELETE" ? "Exclusão" : ''}</td>
                                                <td className='text-center lg:text-sm text-xs dark:text-titleGrayTextDark'>{log?.description}</td>
                                                <td className='text-center lg:text-sm text-xs dark:text-titleGrayTextDark'>{formatThisDate(log?.created_at)}</td>
                                                <td className='text-center lg:text-sm text-xs dark:text-titleGrayTextDark'>
                                                    {
                                                        cancelTippy ?
                                                        <div onClick={() => { 
                                                            if (cancelModal){
                                                                bodyData(log.request_body)
                                                            } else {
                                                                setShowOriginalContent(true)
                                                                setOriginalBody(log.request_body) 
                                                            }
                                                        }} className='flex flex-col items-center justify-center cursor-pointer'>
                                                            <FaEye />
                                                        </div>
                                                        :
                                                        <Tippy content={<span>Verificar conteúdo original</span>}
                                                            arrow={true}
                                                            animation='shift-away'
                                                            placement='top'
                                                            delay={100}>
                                                            <div onClick={() => { 
                                                                if (cancelModal){
                                                                    bodyData(log.request_body)
                                                                } else {
                                                                    setShowOriginalContent(true)
                                                                    setOriginalBody(log.request_body) 
                                                                }
                                                            }} className='flex flex-col items-center justify-center cursor-pointer'>
                                                                <FaEye />
                                                            </div>
                                                        </Tippy>
                                                    }
                                                </td>
                                            </tr>
                                        })
                                        :
                                        <tr className='h-10'>
                                            <td colSpan={5}>
                                                <div className='flex flex-col items-center justify-center'>
                                                    <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Sem dados</a>
                                                </div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                            <tfoot>
                                <tr className='bg-bgSecondaryLight dark:bg-thirdDefaultDark py-1'>
                                    <th colSpan={5}>
                                        <div className='w-full flex flex-row items-center justify-end px-4'>
                                            {
                                                pages?.page.map((thisPage, index) => {
                                                    const totalPages = pages?.page.length

                                                    const buttonTrue = <a onClick={() => handleNewPage(thisPage)} className='text-white px-2 bg-primaryDefaultLight hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage}</a>
                                                    const buttonFalse = <a onClick={() => handleNewPage(thisPage)} className='px-2 hover:bg-primaryDefaultLight hover:text-white cursor-pointer border dark:border-secondaryBorderDark dark:text-titleGrayTextDark'>{thisPage}</a>
                                                    let existsPoints = false
                                                    let showPointers = false

                                                    if (totalPages < 6) {
                                                        return pages.actualPage === thisPage ?
                                                            buttonTrue
                                                            :
                                                            buttonFalse
                                                    } else {
                                                        existsPoints = true
                                                        if (index + 1 === 1 && pages.actualPage !== thisPage) {
                                                            return buttonFalse
                                                        } else if (index + 1 == thisPage && pages.actualPage === thisPage) {
                                                            return buttonTrue
                                                        } else if (pages.actualPage + 3 > index + 1 && pages.actualPage - 2 < index + 1) {
                                                            return buttonFalse
                                                        } else if (totalPages === thisPage) {
                                                            return buttonFalse
                                                        } else {
                                                            if (pages.actualPage + 3 < index + 1 || pages.actualPage - 2 > index + 1) {
                                                                showPointers = true
                                                            }
                                                            if (existsPoints && showPointers == false) {
                                                                return <a>...</a>
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                        </div>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                }
            </div>
            <div className=' hidden'>

                <div className='flex flex-row h-60'>

                    <div className='flex flex-col h-60 '>
                        <h1 className='h-60 bg-primaryDefaultLight text-titleGrayTextDark py-1 text-sm'>Executado por</h1>
                        <h1 className='h-60 bg-primaryDefaultLight text-titleGrayTextDark py-1 text-sm'>Ação</h1>
                        <h1 className='h-60 bg-primaryDefaultLight text-titleGrayTextDark py-1 text-sm'>Detalhes</h1>
                        <h1 className='h-60 bg-primaryDefaultLight text-titleGrayTextDark py-1 text-sm'>Data</h1>
                        <h1 className='h-60 bg-primaryDefaultLight text-titleGrayTextDark py-1 text-sm'>Original</h1>
                    </div>
                    <div>
                        {
                            thisLog?.length > 0 ?
                                thisLog?.map(log => {
                                    return <tr className='h-10'>
                                        <h1 className='text-center text-sm dark:text-titleGrayTextDark'>{log.users_name}</h1>
                                        <h1 className='text-center text-sm dark:text-titleGrayTextDark'>{log?.method === "POST" ? "Inclusão" : log?.method === "PUT" ? "Alteração" : log?.method === "DELETE" ? "Exclusão" : ''}</h1>
                                        <h1 className='text-center text-sm dark:text-titleGrayTextDark'>{log?.description}</h1>
                                        <h1 className='text-center text-sm dark:text-titleGrayTextDark'>{formatThisDate(log?.created_at)}</h1>
                                        <h1 className='text-center text-sm dark:text-titleGrayTextDark'>
                                            <Tippy content={<span>Verificar conteúdo original</span>}
                                                arrow={true}
                                                animation='shift-away'
                                                placement='top'
                                                delay={100}>
                                                <div onClick={() => { setShowOriginalContent(true); setOriginalBody(log.request_body) }} className='flex flex-col items-center justify-center cursor-pointer'>
                                                    <FaEye />
                                                </div>
                                            </Tippy>
                                        </h1>
                                    </tr>
                                })
                                :
                                <tr className='h-10'>
                                    <td colSpan={5}>
                                        <div className='flex flex-col items-center justify-center'>
                                            <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Sem dados</a>
                                        </div>
                                    </td>
                                </tr>
                        }
                    </div>
                </div>

            </div>
        </div>
    )

}